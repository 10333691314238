import React, { useState, useEffect } from 'react';
import {
  Badge,
  Box,
  Fab,
  Tab,
  Tabs,
  Slide,
  useTheme,
  styled,
  Stack,
  IconButton,
  Typography,
  Divider
} from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { ArrowForwardIos, ArrowRight, History } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {
  GlobalDeviceAlerts,
  GlobalTickets,
  WS_DEVICE_DOWN_ALERT
} from '../../services/ApiEndpoints';
import ApiService from '../../services/ApiService';
import styles from './Alerts.module.scss';
import GlobalTicketsData from './GlobalTickets';
import GlobalAlertsData from './GlobalAlerts';
import Scrollbar from '../Scrollbar';
import { a11yProps, TabPanel } from '../tabs';
import { ALL_PACKAGES, MAX_GLOBAL_DATA, SUCCESS } from '../../constants';
import useSocket from '../../_hooks/Socket';
import SearchBox from './SearchBox';
import AlertDetails from '../Tickets/AlertDetails';

// ----------------------------------------------------------------------

const RootStyleMain = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    // width: `${toggle ? '296px' : 'maxWidth'}`
    width: 'maxWidth'
  }
}));

const GlobalDeviceAlertListing = ({ slideOpen, setSlideOpen, setAlertsInfo }) => {
  const { apiService } = ApiService();
  const allPackage = JSON.parse(localStorage.getItem(ALL_PACKAGES));
  const navigate = useNavigate();
  const { palette, CommonPalette } = useTheme();
  const [totalAlerts, setTotalAlert] = useState(0);
  const [value, setValue] = useState(0);
  const [alerts, setAlerts] = useState([]);
  const [alertData, setAlertData] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const { lastMessage } = useSocket(WS_DEVICE_DOWN_ALERT);
  const [loading, setLoading] = useState(false);
  const [newAlerts, setNewAlerts] = useState(0);

  useEffect(() => {
    if (lastMessage !== null) {
      const allowedPackage = JSON.parse(localStorage.getItem('allPackages'));
      const { data } = JSON.parse(lastMessage.data);

      if (alerts?.length > MAX_GLOBAL_DATA) {
        alerts.pop();
      }

      if (allowedPackage.includes(data.device_package_id)) {
        setAlerts((prev) => [{ ...data, isNew: true }, ...prev]);
        setTotalAlert((prev) => prev + 1);
        setNewAlerts((prev) => prev + 1);
      }
    }
  }, [lastMessage]);

  const getGlobalAlerts = () => {
    setLoading(true);
    apiService(
      { url: GlobalDeviceAlerts, method: 'post', data: { package_ids: allPackage } },
      { loader: false }
    )
      .then((result) => {
        if (result?.status === SUCCESS) {
          const _data = result.data;
          setTotalAlert(_data.total);
          setAlerts(_data.listing);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setAlertData(alerts);
    setAlertsInfo((prev) => ({
      ...prev,
      totalAlert: totalAlerts,
      onClick: () => setSlideOpen((prev) => !prev)
    }));
  }, [alerts]);

  useEffect(() => {
    setAlertsInfo((prev) => ({
      ...prev,
      newAlerts
    }));
  }, [newAlerts]);

  const handleGlobalAlertFilter = (e) => {
    setGlobalFilterValue(e.target.value);
    if (e.target.value === '' || e.target.value === '#') {
      setAlerts(alertData);
    } else if (e.target.value.startsWith('#')) {
      const filterData = alerts.filter((row) =>
        row.id.toString().toLowerCase().includes(e.target.value.slice(1).toLowerCase())
      );
      setAlerts(filterData);
    }
  };

  const markAlertRead = (alertInfo) => {
    setAlerts(alerts.map((item) => (item.id === alertInfo.id ? { ...item, isNew: false } : item)));
    if (alertInfo?.isNew) setNewAlerts((prev) => prev - 1);
  };

  useEffect(() => {
    getGlobalAlerts();
  }, []);
  const handleCheckChange = () => {
    setSlideOpen((prev) => !prev);
  };

  return (
    <RootStyleMain>
      <Box style={{ position: 'fixed', right: '0px', zIndex: '9' }}>
        <Fab
          sx={{
            left: '-25px',
            background: CommonPalette.lightpink,
            display: slideOpen ? 'show' : 'none',
            borderBottom: '1px solid #dee2e6 !important',
            borderLeft: '1px solid #dee2e6 !important',
            height: '32px',
            zIndex: 22,
            width: '30px',
            borderRadius: '5px 0px 0px 5px',
            position: 'absolute',
            boxShadow: 'none'
          }}
          onClick={handleCheckChange}
          size="small"
        >
          <ArrowRight sx={{ fontSize: '25px !important' }} />
        </Fab>

        <Slide
          direction="left"
          in={slideOpen}
          mountOnEnter
          unmountOnExit
          sx={{
            background: palette.background.main,
            boxShadow: '-10px 0px 20px rgb(0 0 0 / 25%)'
          }}
        >
          <Box className="global-alert-container">
            <Scrollbar
              sx={{
                height: '100%',
                '& .simplebar-content': {
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }
              }}
            >
              <Box sx={{ borderRadius: '10px' }}>
                <Tabs
                  value={value}
                  variant="fullWidth"
                  scrollButtons={false}
                  aria-label="scrollable prevent tabs example"
                >
                  <Tab
                    className={styles.tab_title_button}
                    icon={
                      <Badge
                        className="badge-icon"
                        badgeContent={totalAlerts}
                        color="error"
                        max={99}
                      />
                    }
                    label="Device Down Alerts"
                    {...a11yProps(0)}
                  />
                </Tabs>
              </Box>

              <TabPanel value={value} index={0}>
                <Box sx={{ padding: '12px 6px 6px 6px' }}>
                  <div className={`has-search ${styles.search_box}`}>
                    <SearchBox value={globalFilterValue} changeHandler={handleGlobalAlertFilter} />
                  </div>
                </Box>
                <Box>
                  {alerts?.length
                    ? alerts.map((alertInfo, index) => (
                        <Box
                          key={index}
                          onClick={() => {
                            markAlertRead(alertInfo.id);
                            navigate(
                              `/dashboard/facility-management-system/devices/${alertInfo.device}`
                            );
                          }}
                          sx={{
                            width: '100%',
                            p: 0,
                            position: 'relative',
                            ':hover': {
                              background: palette.blue.backgroundLight
                            },
                            cursor: 'pointer'
                          }}
                        >
                          {/* {isAcknowledged && (
                              <ThumbUpIcon
                                sx={{
                                  position: 'absolute',
                                  right: '4.5%',
                                  top: '12%',
                                  color: theme.palette.blue.light,
                                  fontSize: '18px'
                                }}
                              />
                            )} */}
                          <Box
                            sx={{
                              padding: '10px',
                              borderLeft: alertInfo?.isNew ? '3px solid #FA5E5E' : 'none'
                            }}
                          >
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Box>
                                <Stack direction="row" spacing={1} alignItems="center">
                                  <Typography sx={{ color: palette.grey[800] }} variant="caption">
                                    #{alertInfo?.id}
                                  </Typography>
                                  <Typography
                                    variant="extraSmall"
                                    sx={{
                                      color: alertInfo?.type_badge_text_color,
                                      padding: '0px 4px',
                                      borderRadius: '4px',
                                      textTransform: 'uppercase',
                                      border: `1px solid ${palette.grey[175]}`,
                                      background: palette.error.main
                                    }}
                                    noWrap
                                  >
                                    Device Down
                                  </Typography>
                                </Stack>
                                <Stack marginTop="6px" spacing="2px">
                                  <Typography variant="caption" sx={{ color: palette.grey[800] }}>
                                    {alertInfo?.device_name}
                                  </Typography>
                                  <Stack
                                    direction="row"
                                    spacing="2px"
                                    sx={{
                                      span: {
                                        ':after': { content: '" \u2022 "' },
                                        ':last-child:after': { content: 'none' }
                                      }
                                    }}
                                  >
                                    <Typography
                                      variant="extraSmall"
                                      sx={{ color: palette.grey[275] }}
                                    >
                                      {alertInfo?.package_name && `${alertInfo.package_name}`}
                                    </Typography>
                                    <Typography
                                      variant="extraSmall"
                                      sx={{ color: palette.grey[275] }}
                                    >
                                      {alertInfo?.created_at}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </Box>
                              <Box sx={{ cursor: 'pointer', padding: '2px' }}>
                                <ArrowForwardIos
                                  sx={{ color: palette.primary.main, fontSize: '10px' }}
                                />
                              </Box>
                            </Stack>
                          </Box>
                          <Divider variant="middle" />
                        </Box>
                      ))
                    : !loading && (
                        <Box paddingY={1}>
                          <AlertDetails classname="alert-danger" data="No Alert Found" />
                        </Box>
                      )}
                </Box>
              </TabPanel>
            </Scrollbar>
          </Box>
        </Slide>
      </Box>
    </RootStyleMain>
  );
};

export default GlobalDeviceAlertListing;
