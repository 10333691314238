// ----------------------------------------------------------------------

import { useTheme } from '@mui/material';

export default function Tooltip() {
  const theme = useTheme();
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.grey[800]
        },
        arrow: {
          color: theme.palette.grey[800]
        }
      }
    }
  };
}
