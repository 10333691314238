import { Suspense, useState, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, LinearProgress } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
//
import DashboardSidebar from './DashboardSidebar';
import DashboardNavbar from './DashboardNavbar';
import GloabalAlertListing from '../../components/events/GlobalEventListing';
import ErrorBoundary from '../../utils/ErrorBoundary';
import SideDrawer from '../../_hoc/SideDrawer';
import EventSlider from '../../pages/alert/AlertDetailComponents/EventSlider';
import GlobalAdasRequest from '../../components/events/GlobalAdasRequest';
import GlobalDeviceAlertListing from '../../components/events/GlobalDeviceAlertListing';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 15,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const theme = useTheme();
  const mdDown = theme.breakpoints.down('sm');
  const mobileView = localStorage.getItem('mobileView');
  const drawerRef = useRef();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [slideOpen, setSlideOpen] = useState(false);
  const [slideDeviceOpen, setSlideDeviceOpen] = useState(false);
  const [alertsInfo, setAlertsInfo] = useState({ newAlerts: 0, onClick: null, totalAlert: 0 });
  const [deviceAlerts, setDeviceAlerts] = useState({ newAlerts: 0, onClick: null, totalAlert: 0 });
  const [slideAdasOpen, setSlideAdasOpen] = useState(false);
  const [adasInfo, setAdasInfo] = useState({ newAdas: 0, onClick: null, totalAdas: 0 });
  const [eventType, setEventType] = useState();
  return (
    <RootStyle>
      {!mobileView && (
        <DashboardSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      )}
      <DashboardNavbar
        mobileView={mobileView}
        sidebarOpen={sidebarOpen}
        slideOpen={slideOpen}
        deviceAlerts={deviceAlerts}
        alertsInfo={alertsInfo}
        setSidebarOpen={setSidebarOpen}
        setEventType={setEventType}
        openEventDrawer={drawerRef}
        slideAdasOpen={slideAdasOpen}
        adasInfo={adasInfo}
      />
      {!mobileView && (
        <>
          <SideDrawer
            title={eventType === 'create' ? 'Create Event' : 'Report Accident'}
            ref={drawerRef}
            slideProps={{ width: '700px' }}
            containerProps={{ paddingBottom: 0 }}
          >
            <EventSlider
              openEventDrawer={drawerRef}
              global
              {...(eventType !== 'create' && { markAccident: true })}
            />
          </SideDrawer>
        </>
      )}
      <MainStyle>
        <Suspense
          fallback={
            <Box sx={{ width: '100%' }}>
              <LinearProgress color="secondary" />
            </Box>
          }
        >
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </Suspense>
      </MainStyle>
      {!mobileView && (
        <>
          <GloabalAlertListing
            slideOpen={slideOpen}
            setSlideOpen={setSlideOpen}
            setAlertsInfo={setAlertsInfo}
          />
          <GlobalDeviceAlertListing
            slideOpen={slideDeviceOpen}
            setSlideOpen={setSlideDeviceOpen}
            setAlertsInfo={setDeviceAlerts}
          />
          <GlobalAdasRequest
            slideAdasOpen={slideAdasOpen}
            setSlideAdasOpen={setSlideAdasOpen}
            setAdasInfo={setAdasInfo}
          />
        </>
      )}
    </RootStyle>
  );
}
