import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  1: '#000000',
  2: '#DCE3EB',
  25: '#7B818A',
  50: '#9EA2A9',
  75: '#9AA5B6',
  100: '#F9FAFB',
  125: '#EAEDF2',
  150: '#929599',
  175: '#DAD8D8',
  200: '#F4F6F8',
  225: '#E4E4E4',
  275: '#797D80',
  300: '#DFE3E8',
  325: '#424242',
  375: '#2D2E34',
  400: '#C4CDD5',
  425: '#C6CDD9',
  450: '#EAEDF2',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#343B46',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8)
};

const PrimaryBlue = '#4f46ba';

const BLUE = {
  main: PrimaryBlue,
  dark: '#2b2380',
  ligher: '#E8E7FF',
  skyLight: '#51bbd21f',
  skyDark: '#179dba',
  cardBlue: '#D5F0FF',
  carBlue: '#3AA7BF',
  light: '#5BA6FF',
  backgroundLight: '#EFF4FF',
  boxBlue: PrimaryBlue,
  lightBlue: '#104DAA',
  cyan: '#6AA0F5',
  icon: '#177FFA',
  lightbackground: '#E0E7FF',
  purple: '9E1EDA',
  bluetext: '#3B92E2',
  infoBlue: '#95BEFF'
};

const PRIMARY = {
  lighter: '#EAEDF2',
  light: '#9EA2A9',
  main: '#343B46',
  dark: '#007B55',
  darker: '#005249',
  contrastText: '#fff',
  graphLabel: '#9EA2A9'
};
const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: PrimaryBlue,
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#fff',
  healthy: '#09D9A8'
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: PrimaryBlue,
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
  infoBlue: '#E9F1FF',
  violet: PrimaryBlue,
  lightViolet: '#F2E9FF'
};
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#019F79',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800]
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
  orange: '#F09205',
  lemon: '#FFF4CC',
  yellow: '#C39110'
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FA5E5E',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
  lightest: '#FFB9B9',
  alert: '#FF983A'
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main)
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4']
};

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
  background: {
    paper: '#fff',
    default: GREY[100],
    neutral: GREY[200],
    pink: '#F8F5EC',
    main: GREY[0],
    thirdcolor: '#fff',
    secondary: GREY[1],
    line: '#eaedf2',
    table: '#f6f7f8',
    colors: '#000'
  },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  },
  blue: BLUE,
  link: '#4F46BA',
  other: {
    pink: '#FFCACD',
    card: '#fff',
    disabled: '#fafafa',
    rp_background: '#FFFAEA',
    callCard: '#343B46',
    topWidget: PrimaryBlue
  }
};

export default palette;
