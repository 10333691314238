import { Box, Divider, IconButton, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { History } from '@mui/icons-material';
import AlertDetails from '../Tickets/AlertDetails';
import SearchBox from './SearchBox';
import TicketEvent from './TicketEvent';
import styles from './Alerts.module.scss';

const GlobalTicketsData = ({ tickets }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const TICKET_PRIORITY = 'High';

  return (
    <>
      <Box sx={{ padding: '12px 6px' }}>
        <Stack direction="row" justifyContent="space-around" alignItems="center" spacing={1}>
          <Box className={`has-search ${styles.search_box}`}>
            <SearchBox
            //   value={globalFilterValue}
            //   changeHandler={handleGlobalAlertFilter}
            />
          </Box>
          <IconButton
            onClick={() => navigate('/dashboard/ticket-listing')}
            sx={{ color: theme.palette.blue.main }}
          >
            <History />
          </IconButton>
        </Stack>
      </Box>
      <Typography sx={{ padding: '10px', color: theme.palette.grey[800] }} variant="subtitle5">
        High Priority Tickets
      </Typography>
      <Divider sx={{ marginTop: '8px' }} />
      {tickets?.length ? (
        tickets.map(
          (item, index) =>
            item?.priority_display === TICKET_PRIORITY && <TicketEvent key={index} tickets={item} />
        )
      ) : (
        <Box paddingY={1}>
          <AlertDetails classname="alert-danger" data="No Ticket Found" />
        </Box>
      )}
    </>
  );
};

export default GlobalTicketsData;
