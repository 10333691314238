import { Paper, Modal, Box, useTheme } from '@mui/material';
import React from 'react';
import useActions from './useActions';
import TopBar from './TopBar';
import Videos from './Videos';

const { forwardRef, useImperativeHandle } = React;

const VideoCall = forwardRef(({ callState, incomingCall = false, userId = '' }, ref) => {
  const { endCall, inCall, setInCall, config, setConfig } = useActions();
  const { CommonPalette } = useTheme();

  useImperativeHandle(ref, () => ({
    startCall: (channel) =>
      setConfig((prev) => ({
        ...prev,
        channelName: channel
      })),
    EndCall: () => endCall(),
    config,
    inCall
  }));

  return (
    <Modal
      open={inCall}
      onClose={endCall}
      hideBackdrop
      sx={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
    >
      <Box
        sx={{
          background: CommonPalette.videoCall.background,
          width: '80%',
          maxHeight: '100%',
          borderRadius: 1,
          position: 'absolute',
          margin: '0 auto',
          left: '0',
          right: '0',
          top: '50%',
          transform: 'translateY(-50%)'
        }}
      >
        {/* <TopBar /> */}
        {config.rtcToken && (
          <Videos
            incomingCall={incomingCall}
            callState={callState}
            config={config}
            setInCall={setInCall}
            endCall={endCall}
            userId={userId}
          />
        )}
      </Box>
    </Modal>
  );
});

export default VideoCall;
