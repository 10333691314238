import React, { useState, createContext, useContext, useEffect } from 'react';
import { DEMO_MODE, DEMO_MODE_OFF, DEMO_MODE_ON, DEMO_PACKAGE_DATA } from '../constants';

const DemoMode = createContext();

export const useDemoMode = () => useContext(DemoMode);

const DemoModeProvider = ({ children }) => {
  const [demoMode, setDemoMode] = useState();

  useEffect(() => {
    if (!localStorage.getItem(DEMO_MODE)) {
      localStorage.setItem(DEMO_MODE, DEMO_MODE_OFF);
      setDemoMode(DEMO_MODE_OFF);
    } else {
      const mode = localStorage.getItem(DEMO_MODE);
      setDemoMode(mode);
    }
  }, []);

  useEffect(() => {
    if (demoMode) {
      if (!localStorage.getItem('package')) {
        localStorage.setItem('package', '');
      }
    }
  }, [demoMode]);

  return (
    <>
      <DemoMode.Provider value={{ demoMode, setDemoMode }}>{children}</DemoMode.Provider>
    </>
  );
};

export default DemoModeProvider;
