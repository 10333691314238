import React, { useState, createContext, useContext, useEffect, useRef } from 'react';
import { Box, Stack, useTheme, Typography, styled, IconButton, Snackbar } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';
import { WS_AGORA_CALLING, AgoraCallStatus } from '../services/ApiEndpoints';
import useSocket from '../_hooks/Socket';
import ApiService from '../services/ApiService';
import { SUCCESS, USERID } from '../constants';
import VideoCall from '../utils/Calling';

const AgoraCall = createContext(null);

export const useAgoraCall = () => useContext(AgoraCall);

const AgoraCallProvider = ({ children }) => {
  const userId = localStorage.getItem(USERID);
  const callingRef = useRef();
  const [callData, setCallData] = useState({ channel_id: '' });
  const [open, setOpen] = useState(false);
  const { lastMessage } = useSocket(`${WS_AGORA_CALLING}?user_id=${userId}`);
  const { apiService } = ApiService();

  const getEvents = (callAccepted) => {
    apiService({
      url: AgoraCallStatus,
      method: 'post',
      data: { call_id: userId, is_accepted: callAccepted }
    }).then((result) => {
      if (result?.status === SUCCESS) {
        const _data = result.data;
      }
    });
  };

  const handleCall = (callAccepted) => {
    setOpen(false);
    if (callAccepted) {
      callingRef.current.startCall(callData.channel_id);
    }
    getEvents(callAccepted);
  };

  const action = (
    <Stack direction="row" spacing={1}>
      <ActionButton
        title="Answer"
        icon={<CallIcon fontSize="small" />}
        color="green"
        onClick={() => {
          handleCall(true);
        }}
      />
      <ActionButton
        title="Decline"
        icon={<CallEndIcon fontSize="small" />}
        color="red"
        onClick={() => {
          handleCall(false);
        }}
      />
    </Stack>
  );

  const message = (
    <Stack direction="column" spacing={1}>
      <Typography variant="h5">Incoming Call</Typography>
      <Typography variant="boxTitle2">{callData?.created_by}</Typography>
    </Stack>
  );

  useEffect(() => {
    if (lastMessage) {
      const data = JSON.parse(lastMessage.data);
      if (
        data?.channel_id &&
        data?.user === Number(userId) &&
        !callingRef.current?.inCall &&
        callingRef.current?.config.channelName !== data.channel_id
      ) {
        setCallData(data);
        setOpen(true);
      }
    }
  }, [lastMessage]);

  return (
    <>
      <AgoraCall.Provider value>{children}</AgoraCall.Provider>

      <Snackbar
        sx={{ '& .MuiSnackbarContent-root': { p: 3 } }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        message={message}
        action={action}
      />
      <VideoCall ref={callingRef} incomingCall />
    </>
  );
};

const ActionButton = ({ color = '', title = '', icon, ...props }) => {
  const { CommonPalette, palette } = useTheme();

  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    padding: '10px',
    '&:hover': { backgroundColor: color }
  }));

  return (
    <StyledIconButton {...props} sx={{ backgroundColor: color }}>
      {icon}
    </StyledIconButton>
  );
};

export default AgoraCallProvider;
