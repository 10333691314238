import { styled, AppBar, alpha, Toolbar } from '@mui/material';
import PropTypes from 'prop-types';
import AccountPopover from './AccountPopover';

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme, sidebaropen }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: sidebaropen === 'open' ? `calc(100% - ${DRAWER_WIDTH + 1}px)` : `100%`,
    marginRight: '0px'
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP
  }
}));

// ----------------------------------------------------------------------

export default function DashboardNavbar(props) {
  return (
    <RootStyle sidebaropen={props.sidebarOpen ? 'open' : 'closed'}>
      <ToolbarStyle>
        <AccountPopover {...props} />
      </ToolbarStyle>
    </RootStyle>
  );
}

DashboardNavbar.propTypes = {
  sidebaropen: PropTypes.bool
};
