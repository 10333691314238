import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function handleException() {
  // const notify = () =>  toast.warn('Something Went Wrong!',
  // {
  //   position: "top-right",
  //   autoClose: 5000,
  //   hideProgressBar: false,
  //   closeOnClick: true,
  //   pauseOnHover: true,
  //   draggable: true,
  //   progress: undefined,
  //   });

  return (
    <div>
      <ToastContainer />
    </div>
  );
}
export default handleException;
