import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { ArrowForwardIos } from '@mui/icons-material';
import { Box, Typography, Divider, useTheme, Stack } from '@mui/material';
import { nameToString } from '../../utils/AppFunction';

const AdasBadge = ({ markAdasRead, adasInfo }) => {
  const { palette, CommonPalette } = useTheme();
  const navigate = useNavigate();
  return (
    <>
      <Box
        onClick={() => {
          markAdasRead(adasInfo);
          navigate(`/dashboard/alert-details/${adasInfo?.alert}`);
        }}
        sx={{
          width: '100%',
          p: 0,
          position: 'relative',
          ':hover': {
            background: palette.blue.backgroundLight
          },
          cursor: 'pointer'
        }}
      >
        <Box sx={{ padding: '10px', borderLeft: adasInfo?.isNew ? '3px solid #FA5E5E' : 'none' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography sx={{ color: palette.grey[800] }} variant="caption">
                  #{adasInfo?.alert}
                </Typography>
                <Typography
                  variant="extraSmall"
                  sx={{
                    color: CommonPalette.primary,
                    padding: '0px 4px',
                    borderRadius: '4px',
                    textTransform: 'uppercase',
                    border: `1px solid ${palette.grey[175]}`,
                    background: palette.grey[225]
                  }}
                  noWrap
                >
                  {adasInfo?.type}
                </Typography>
              </Stack>
              <Stack marginTop="6px" spacing="2px">
                <Typography
                  variant="caption"
                  sx={{ color: palette.grey[800], textTransform: 'capitalize' }}
                >
                  {adasInfo?.vehicle} {adasInfo?.vehicle_number && `(${adasInfo.vehicle_number})`}
                </Typography>
                <Stack
                  direction="row"
                  spacing="2px"
                  sx={{
                    span: {
                      ':after': { content: '" \u2022 "' },
                      ':last-child:after': { content: 'none' }
                    }
                  }}
                >
                  <Typography variant="extraSmall" sx={{ color: palette.grey[275] }}>
                    {adasInfo?.requested_by &&
                      `Requested By ${nameToString(adasInfo.requested_by)}`}{' '}
                    {adasInfo?.requested_at && `at ${adasInfo.requested_at}`}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
            <Box sx={{ cursor: 'pointer', padding: '2px' }}>
              <ArrowForwardIos sx={{ color: palette.primary.main, fontSize: '10px' }} />
            </Box>
          </Stack>
        </Box>
        <Divider variant="middle" />
      </Box>
    </>
  );
};

AdasBadge.propTypes = {
  markAdasRead: PropTypes.func,
  allNewAlerts: PropTypes.array,
  adasInfo: PropTypes.object
};

export default AdasBadge;
