import React, { createContext, useRef, useState } from 'react';
import { Link, Box, useMediaQuery } from '@mui/material';
import ForgotPassowrdForm from './ForgotPassowrdForm';
import SideDrawer from '../../../_hoc/SideDrawer';

export const ForgotPassContext = createContext();

const ForgotPassword = () => {
  const drawerRef = useRef();
  const [state, setState] = useState(false);
  const [page, setPage] = useState({});
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const toggleDrawer = () => {
    setState((prevState) => !prevState);
    setPage({ element: ForgotPassowrdForm });
    drawerRef.current.openDrawer();
  };

  return (
    <>
      <Link onClick={toggleDrawer} href="#" variant="subtitle2" sx={{ fontSize: smDown && '12px' }}>
        Forgot Password
      </Link>

      <SideDrawer title="Forgot Password" ref={drawerRef}>
        <Box>
          <ForgotPassContext.Provider value={{ setPage, toggleDrawer }}>
            {page?.element && React.createElement(page.element, { ...page?.props })}
          </ForgotPassContext.Provider>
        </Box>
      </SideDrawer>
    </>
  );
};

export default ForgotPassword;
