import { useEffect } from 'react';
import FormValidation from '../../utils/FormValidation';
import { ChangePasswordForm, ChangePasswordFormValidation } from './FormModel';
import useForm from '../../_hooks/useForm';
import ApiService from '../../services/ApiService';
import { ChangePassword } from '../../services/ApiEndpoints';
import siteMessages, { validationMessages } from '../../alert_messages';
import { SUCCESS } from '../../constants';
import { useAuthContext } from '../../contexts/AuthContext';
import handleException from '../../utils/handleExceptions';

const useChangePassword = (props) => {
  const { apiService } = ApiService();
  const { logoutUser } = useAuthContext();

  const { formInputs, bindInput } = useForm(ChangePasswordForm);
  const { validate, errors, setErrors } = FormValidation({
    validationRules: { ...ChangePasswordFormValidation },
    formInput: formInputs
  });

  const handleSubmit = () => {
    validate()
      .then(() => {
        apiService(
          { url: ChangePassword, method: 'post', data: formInputs },
          { alertMessage: siteMessages.passwordChanged }
        )
          .then((result) => {
            if (result?.status !== SUCCESS) {
              setErrors({ old_password: validationMessages.incorrectOldPassword });
            } else {
              props.popupHandler.current.openPopUp(false);
              logoutUser();
            }
          })
          .catch((error) => handleException(error));
      })
      .catch(() => handleException(siteMessages.validationError));
  };

  useEffect(() => {
    if (errors) setErrors({});
  }, [formInputs]);

  return {
    handleSubmit,
    bindInput,
    errors
  };
};

export default useChangePassword;
