import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Typography
} from '@mui/material';
import { RoleBasedComponent } from '../routing/RoleBasedAccess';
import { useThemeMode } from '../contexts/DarkThemeContext';
import { DARK_THEME } from '../constants';

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    minHeight: 60,
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    '&:before': {
      top: 0,
      right: 0,
      width: 6,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      backgroundColor: theme.palette.blue.main
    }
  })
);

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func
};

function NavItem({ item, active }) {
  const { themeStatus } = useThemeMode();
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children, head } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const normalColor = {
    color: theme.palette.grey[50]
  };

  const activeRootStyle = {
    color: theme.palette.grey[1],
    fontWeight: 'fontWeightMedium',
    bgcolor: theme.palette.blue.ligher,
    '&:before': { display: 'block' },
    '&:hover': { backgroundColor: themeStatus === DARK_THEME && theme.palette.blue.ligher }
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium'
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot ? activeRootStyle : normalColor)
          }}
        >
          <ListItemIconStyle
            sx={{
              ...(!isActiveRoot && normalColor)
            }}
          >
            {icon && icon}
          </ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Box
            component={Icon}
            icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub ? activeRootStyle : normalColor)
                  }}
                >
                  <ListItemIconStyle
                    sx={{
                      ...(!isActiveRoot && normalColor)
                    }}
                  >
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'text.disabled',
                        transition: (theme) => theme.transitions.create('transform'),
                        ...(isActiveSub && {
                          transform: 'scale(2)',
                          bgcolor: 'primary.main'
                        })
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot ? activeRootStyle : normalColor)
      }}
    >
      <ListItemIconStyle
        sx={{
          ...(!isActiveRoot && normalColor)
        }}
      >
        {icon && icon}
      </ListItemIconStyle>
      <ListItemText
        disableTypography
        primary={
          <>
            <Typography
              component="p"
              variant="subtitle3"
              sx={{ fontWeight: 500, color: theme.palette.background.colors }}
            >
              {isActiveRoot && head}
            </Typography>
            <Typography variant="navItem">{title}</Typography>
          </>
        }
      />
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const match = (path) => (path ? !!matchPath({ path }, pathname) : false);

  return (
    <Box {...other} sx={{mb:3}}>
      <List disablePadding>
        {navConfig.map((item) => (
          <RoleBasedComponent moduleName={item.permissionCode} key={item.title}>
            <NavItem item={item} active={match} />
          </RoleBasedComponent>
        ))}
      </List>
    </Box>
  );
}
