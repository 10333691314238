import { Box, Button, Typography, Container, Stack, Grid, useTheme } from '@mui/material';
import { InputText } from '../../form_inputs';
import useForgotPassword from './useForgotPassword';

const ForgotPassowrdForm = () => {
  const { handleSubmit, bindInput, errors } = useForgotPassword();
  const theme = useTheme();

  return (
    <Container maxWidth="sm">
      <Stack sx={{ mb: 5 }}>
        <Typography variant="h4" gutterBottom align="center">
          Forgot Your Password
        </Typography>
        <Typography sx={{ color: 'text.secondary' }} align="center">
          Please enter the email address or username you'd like your password reset information sent
          to.
        </Typography>
      </Stack>

      <Box sx={{ width: '100%' }}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <InputText
              error={errors}
              name="username"
              label="Enter Email/Username"
              bindInput={bindInput}
            />
          </Grid>
          <Grid container justifyContent="center" sx={{ width: '100%' }}>
            <Button
              type="button"
              sx={{ minWidth: '20%', mt: 2, backgroundColor: theme.palette.blue.main }}
              variant="contained"
              onClick={handleSubmit}
            >
              Request OTP
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ForgotPassowrdForm;
