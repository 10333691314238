import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { ArrowForwardIos } from '@mui/icons-material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { Grid, Paper, styled, Box, Typography, Divider, useTheme, Stack } from '@mui/material';
import { getPriorityColor } from '../Priority';
import { CriticalAlerts, badgesClass } from './AlertsType';
import styles from './Alerts.module.scss';
import AlertStatus from './AlertStatus';

const AlertBadge = ({ markAlertRead, allNewAlerts, alertInfo }) => {
  const theme = useTheme();
  const BADGE_PRIORITY = {
    High: theme.palette.error.main,
    Medium: theme.palette.warning.orange,
    Low: theme.palette.success.main
  };
  const {
    is_acknowledged: isAcknowledged,
    is_resolved: isResolved,
    priority,
    ticket_count: ticketCount
  } = alertInfo;
  const navigate = useNavigate();

  return (
    <>
      <Box
        onClick={() => {
          markAlertRead(alertInfo.id);
          navigate(`/dashboard/alert-details/${alertInfo.id}`);
        }}
        sx={{
          width: '100%',
          p: 0,
          position: 'relative',
          ':hover': {
            background: theme.palette.blue.backgroundLight
          },
          cursor: 'pointer'
        }}
      >
        {isAcknowledged && (
          <ThumbUpIcon
            sx={{
              position: 'absolute',
              right: '4.5%',
              top: '12%',
              color: theme.palette.blue.light,
              fontSize: '18px'
            }}
          />
        )}
        <Box sx={{ padding: '10px', borderLeft: alertInfo?.isNew ? '3px solid #FA5E5E' : 'none' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography sx={{ color: theme.palette.grey[800] }} variant="caption">
                  #{alertInfo?.id}
                </Typography>
                <Typography
                  variant="extraSmall"
                  sx={{
                    color: alertInfo?.type_badge_text_color,
                    padding: '0px 4px',
                    borderRadius: '4px',
                    textTransform: 'uppercase',
                    border: `1px solid ${theme.palette.grey[175]}`,
                    background: alertInfo?.type_badge_color
                  }}
                  noWrap
                >
                  {alertInfo?.type}
                </Typography>
                <Typography
                  variant="extraSmall"
                  sx={{
                    color: theme.palette.error.contrastText,
                    padding: '0px 4px',
                    borderRadius: '4px',
                    textTransform: 'uppercase',
                    background: BADGE_PRIORITY[alertInfo.priority_display]
                  }}
                >
                  {alertInfo.priority_display}
                </Typography>
              </Stack>
              <Stack marginTop="6px" spacing="2px">
                <Typography variant="caption" sx={{ color: theme.palette.grey[800] }}>
                  {alertInfo?.device}
                </Typography>
                <Stack
                  direction="row"
                  spacing="2px"
                  sx={{
                    span: {
                      ':after': { content: '" \u2022 "' },
                      ':last-child:after': { content: 'none' }
                    }
                  }}
                >
                  <Typography variant="extraSmall" sx={{ color: theme.palette.grey[275] }}>
                    {alertInfo?.package_name && `${alertInfo.package_name}`}
                  </Typography>
                  <Typography variant="extraSmall" sx={{ color: theme.palette.grey[275] }}>
                    {alertInfo?.created_at}
                  </Typography>
                </Stack>
                {alertInfo?.ticket_id && (
                  <Typography
                    variant="extraSmall"
                    onClick={() =>
                      navigate(`/dashboard/event-management-system/details/${alertInfo.ticket_id}`)
                    }
                    sx={{ color: theme.palette.info.main }}
                  >
                    View Ticket
                  </Typography>
                )}
              </Stack>
            </Box>
            <Box sx={{ cursor: 'pointer', padding: '2px' }}>
              <ArrowForwardIos sx={{ color: theme.palette.primary.main, fontSize: '10px' }} />
            </Box>
          </Stack>
        </Box>
        <Divider variant="middle" />
      </Box>
    </>
  );
};

AlertBadge.propTypes = {
  markAlertRead: PropTypes.func,
  allNewAlerts: PropTypes.array,
  alertInfo: PropTypes.object
};

export default AlertBadge;
