import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Snackbar, Stack, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Call } from '../assets/svg/call.svg';
import { fTimeDiff1 } from '../utils/formatTime';
import Timer from './Timer';

const CustomSnackBar = (props) => {
  const [status, setStatus] = useState();
  const [callStatus, setCallStatus] = useState('');
  const [missed, setMissed] = useState(false);
  const { data, autoHide } = props;
  const navigate = useNavigate();
  const [handleopen, setHandleOpen] = useState(true);
  const theme = useTheme();

  const callFunction = () => {
    if (data?.call_initialized_at && !data?.call_connected_at) {
      setCallStatus('Ringing');
      setStatus(0);
    } else if (
      data?.call_connected_at &&
      !(data?.call_disconnected_at || data?.disconnect_manually_at)
    ) {
      setCallStatus('Call recording ON');
      setStatus(1);
    } else if (data?.call_disconnected_at || data?.disconnect_manually_at) {
      setCallStatus('Disconnected');
      setStatus(2);
    }
    if (data?.call_initialized_at && (data?.call_disconnected_at || data?.disconnect_manually_at)) {
      setMissed(true);
    }
  };

  const message = (
    <Box>
      <OpenInNewIcon
        sx={{ fontSize: '13px', color: '#EAEDF2', position: 'absolute', right: '6px', top: '6px' }}
      />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '100%' }}
      >
        <Box>
          <Typography variant="body1" component="p" sx={{ color: '#FFAC2F' }}>
            {data?.device_id_raw}
          </Typography>
          <Typography variant="extraSmall" component="p" sx={{ color: '#FFD4AD' }}>
            {data?.location_name}
          </Typography>
          <Typography variant="extraSmall" component="p" sx={{ color: '#FFD4AD' }}>
            Call id -{data?.call_id}
          </Typography>
          <Typography variant="extraSmall" component="p" sx={{ color: '#FFD4AD' }}>
            {data?.package_name}
          </Typography>
        </Box>
        <Box sx={{ position: 'relative' }}>
          <Call width="60px" height="60px" />
          {data?.call_connected_at ? (
            <Typography
              variant="subtitle7"
              sx={{ color: '#fff', position: 'absolute', top: '30%', right: '10%' }}
            >
              {status === 1 ? <Timer date={data?.call_connected_at} /> : ''}
              {status === 2 ? (
                <>
                  {fTimeDiff1(
                    data?.call_disconnected_at || data?.disconnect_manually_at,
                    data?.call_connected_at
                  )}
                </>
              ) : (
                ''
              )}
            </Typography>
          ) : (
            <Typography
              variant="extraSmall"
              sx={{
                color: '#DBE2EE',
                position: 'absolute',
                top: '32%',
                left: '50%',
                transform: 'translate(-50%, 0)',
                fontWeight: 600
              }}
            >
              Ringing...
            </Typography>
          )}
        </Box>
      </Stack>
    </Box>
  );

  useEffect(() => {
    setHandleOpen(true);
    callFunction();
  }, [data]);

  const vertical = 'bottom';
  const horizontal = 'right';
  return (
    // <Link
    //   to={`/dashboard/emergency-calling-box/details/${data.id}`}
    //   underline="hover"
    //   component={RouterLink}
    //   sx={{ textDecoration: 'none !important', mb: 2 }}
    // >
    <Snackbar
      key={data.id}
      onClick={() => {
        setHandleOpen(false);
        navigate(`/dashboard/emergency-calling-box/details/${data.id}`, {
          state: { isMissed: missed }
        });
      }}
      autoHideDuration={autoHide && 60000}
      open={handleopen}
      message={message}
      sx={{
        mt: 3,
        position: 'relative',
        '.MuiSnackbarContent-root': {
          backgroundColor: `${theme.palette.other.callCard} !important`
        },
        '& .MuiSnackbarContent-message': {
          width: '100%'
        }
      }}
    />

    // <SnackbarContent
    //   onClick={() => {
    //     setHandleOpen(false);
    //     console.log(handleopen,'ttttt');
    //     // navigate(`/dashboard/emergency-calling-box/details/${data.id}`, {
    //     //   state: { isMissed: missed }
    //     // });
    //   }}
    //   open={handleopen}
    //   key={data.id}
    //   message={message}
    //   sx={{
    //     backgroundColor: theme.palette.other.callCard,
    //     maxWidth: '100%',
    //     width: '100%',
    //     minWidth: '100% !important',
    //     position: 'relative',
    //     mb: 2,
    //     '& .MuiSnackbarContent-message': {
    //       width: '100%'
    //     }
    //   }}
    // />
    // </Link>
  );
};
export default CustomSnackBar;
