import { ArrowForwardIos } from '@mui/icons-material';
import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { fDateTimeformat } from '../../utils/formatTime';

const TicketEvent = ({ tickets }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Box
      onClick={() => {
        navigate(`/dashboard/ticket-details/${tickets.id}`);
      }}
      variant="button"
      sx={{
        width: '100%',
        p: 0,
        ':hover': {
          background: theme.palette.blue.backgroundLight
        },
        cursor: 'pointer'
      }}
    >
      <Box sx={{ padding: '10px' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Box>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography
                sx={{ color: theme.palette.grey[800], lineHeight: '0px' }}
                variant="caption"
              >
                #{tickets?.id}
              </Typography>
              <Typography
                variant="extraSmall"
                sx={{
                  color: theme.palette.grey[25],
                  padding: '0px 4px',
                  borderRadius: '4px',
                  textTransform: 'uppercase',
                  border: `1px solid ${theme.palette.grey[175]}`,
                  background: theme.palette.grey[225]
                }}
                noWrap
              >
                {tickets?.type}
              </Typography>
              <Typography
                variant="extraSmall"
                sx={{
                  padding: '0px 4px',
                  borderRadius: '4px',
                  textTransform: 'uppercase',
                  border:
                    tickets?.generation_mode === 'manual'
                      ? `1px solid ${theme.palette.warning.orange}`
                      : `1px solid ${theme.palette.info.violet}`,
                  color:
                    tickets?.generation_mode === 'manual'
                      ? theme.palette.warning.orange
                      : theme.palette.info.violet
                }}
              >
                {tickets?.generation_mode}
              </Typography>
            </Stack>
            <Stack marginTop="10px" spacing="2px">
              {tickets?.created_at && (
                <Typography variant="extraSmall" sx={{ color: theme.palette.grey[275] }}>
                  Created on : {fDateTimeformat(tickets?.created_at)}
                </Typography>
              )}
              {tickets?.assigned_to && (
                <Typography variant="extraSmall" sx={{ color: theme.palette.grey[275] }}>
                  Assigned to : {tickets.assigned_to}
                </Typography>
              )}
              {/* <Typography variant="extraSmall" sx={{ color: theme.palette.blue.light }}>
                5/10 tasks completed
              </Typography> */}
            </Stack>
          </Box>
          <Box sx={{ cursor: 'pointer', padding: '2px' }}>
            <ArrowForwardIos sx={{ color: theme.palette.primary.main, fontSize: '10px' }} />
          </Box>
        </Stack>
      </Box>
      <Divider variant="middle" />
    </Box>
  );
};

export default TicketEvent;
