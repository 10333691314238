import React, { useState, useEffect } from 'react';
import { DialogContent, Grid, DialogActions } from '@mui/material';
import { AgoraVideoPlayer, createMicrophoneAndCameraTracks, createClient } from 'agora-rtc-react';
import { CALL_TYPE_VIDEO, INITIATED_FROM_DASHBOARD } from '../../constants';
import ActionBar from './ActionBar';
import SquareBox from '../../components/SquareBox';
import Scrollbar from '../../components/Scrollbar';
import ApiService from '../../services/ApiService';
import { CallInitiate } from '../../services/ApiEndpoints';
import { useSystemSettings } from '../../contexts/SystemSettingsProvider';

const rtcConfig = {
  mode: 'rtc',
  codec: 'vp8'
};
const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();
const useClient = createClient(rtcConfig);

const Videos = ({ config, callState = {}, setInCall, incomingCall, userId = '' }) => {
  const { apiService } = ApiService();
  const [users, setUsers] = useState([]);
  const [start, setStart] = useState(false);
  const client = useClient();
  const { ready, tracks } = useMicrophoneAndCameraTracks();
  const systemSettings = useSystemSettings();
  const [agoraId, setAgoraId] = useState();
  useEffect(() => {
    // function to initialise the SDK
    const init = async (name) => {
      client.on('user-published', async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        console.log('subscribe success');
        if (mediaType === 'video') setUsers((prevUsers) => [...prevUsers, user]);
        if (mediaType === 'audio') {
          user.audioTrack?.play();
        }
      });

      client.on('user-unpublished', (user, type) => {
        console.log('unpublished', user, type);
        if (type === 'audio') {
          user.audioTrack?.stop();
        }
        if (type === 'video') {
          setUsers((prevUsers) => prevUsers.filter((User) => User.uid !== user.uid));
        }
      });

      client.on('user-left', (user) => {
        console.log('leaving', user);
        setUsers((prevUsers) => prevUsers.filter((User) => User.uid !== user.uid));
      });

      await client.join(agoraId, config.channelName, config.rtcToken, null);
      if (tracks) await client.publish([tracks[0], tracks[1]]);
      setStart(true);
    };

    if (ready && tracks && config.rtcToken) {
      console.log('iniVideoCallt ready');

      // initiate api
      if (!incomingCall) {
        const formData = {
          channel_id: config.channelName,
          type: CALL_TYPE_VIDEO,
          initiated_from: INITIATED_FROM_DASHBOARD,
          token: config.rtcToken,
          vehicle_id: callState.vehicle_id,
          participant_vehicle: [callState.vehicle_id && callState.vehicle_id],
          participant_user: userId ? [userId] : []
        };
        console.log(formData);
        apiService(
          { url: CallInitiate, method: 'post', data: { ...formData } },
          { alertMessage: true }
        ).then((result) => {});
      }

      init(config.channelName);
    }
  }, [config.rtcToken, client, ready, tracks]);

  useEffect(() => {
    if (systemSettings?.web_agora_app_id) {
      setAgoraId(systemSettings.web_agora_app_id);
    }
  }, [systemSettings]);

  return (
    <>
      <DialogContent sx={{ paddingBottom: 0 }}>
        <Scrollbar
          sx={{
            maxHeight: 800
          }}
        >
          {start && tracks && (
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
              <Grid item xs={4}>
                <Video track={tracks[1]} />
              </Grid>

              {users.length > 0 &&
                users.map(
                  (user, index) =>
                    user.videoTrack && (
                      <Grid item xs={4} key={index}>
                        <Video track={user.videoTrack} />
                      </Grid>
                    )
                )}
            </Grid>
          )}
        </Scrollbar>
      </DialogContent>

      {ready && tracks && (
        <DialogActions sx={{ justifyContent: 'center', m: '1' }}>
          <ActionBar
            useClient={useClient}
            tracks={tracks}
            setStart={setStart}
            setInCall={setInCall}
          />
        </DialogActions>
      )}
    </>
  );
};

const Video = ({ track }) => (
  <SquareBox>
    <AgoraVideoPlayer
      style={{ height: ' 95%', width: '95%', position: 'relative' }}
      videoTrack={track}
    />
  </SquareBox>
);
export default Videos;
