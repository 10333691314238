import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Grid, Stack, TextField, Typography, useTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useForm from '../../../_hooks/useForm';
import GoogleMap from '../../../components/GoogleMap';
import MediaViewer from '../../../components/MediaViewer';
import StyledFormControl from '../../../components/StyledFormControl';
import { InputText, SelectBox } from '../../../components/form_inputs';
import {
  ALLOWED_CHAINAGE,
  ALL_CHAINAGE,
  MESSAGE_IMAGE_SIZE_ALLOWED,
  PACKAGES,
  SUCCESS
} from '../../../constants';
import { useAlert } from '../../../contexts/AlertContext';
import { useSystemSettings } from '../../../contexts/SystemSettingsProvider';
import {
  CreateAlertEvent,
  DevicePackages,
  EventListing,
  EventTypesListing,
  LocationLatLng,
  PackageLocation,
  SeverityListing,
  VsdsDevices
} from '../../../services/ApiEndpoints';
import ApiService from '../../../services/ApiService';
import { validateImageSize } from '../../../utils/AppFunction';
import ConfirmationBox from '../../../utils/ConfirmationBox';
import FormValidation, { Distance, required } from '../../../utils/FormValidation';
import { fDatePayloadFormat } from '../../../utils/formatTime';
import GroupMessagesAvatar from '../../VirtualMessagingSystem/vms_components/GroupMessagesAvatar';

const EventSlider = (props) => {
  const imageViewer = useRef();
  const theme = useTheme();
  const { showAlert } = useAlert();
  const systemSettings = useSystemSettings();
  const navigate = useNavigate();
  const [eventFilters, setEventFilters] = useState({ package_id: '', location_id: '' });
  const {
    alertDetail,
    markAccident,
    global,
    getAlertsDetail,
    vidsData,
    vidsStatus,
    callInfo,
    tmcsData,
    openEventDrawer
  } = props;
  const { ticketData, deviceList } = vidsData || {};
  const [mapLatLng, setMapLatLng] = useState();
  const [dateValue, setDateValue] = useState(
    alertDetail ? new Date(alertDetail.detected_at) : new Date()
  );
  // new Date(alertDetail ? alertDetail.detected_at : ''));
  const { apiService } = ApiService();
  const [packages, setPackages] = useState([]);

  const [labelData, setLabelData] = useState({});
  const [eventListing, setEventListing] = useState();
  const [subEventTypes, setSubEventTypes] = useState();
  const [uploadUrl, setUploadUrl] = useState('');
  const [locationListing, setLocationListing] = useState();

  const [severity, setSeverity] = useState();
  const [defaultLatLng, setDefaultLatLng] = useState({ lat: '', lng: '' });
  // .device?.location_id

  const { formInputs, bindInput, setFormInputs } = useForm({
    device_id: alertDetail?.device?.id || '',
    event_id: '',
    alert_id: alertDetail?.id ? alertDetail?.id : '',
    type_id: '',
    direction: '',
    package_id:
      (alertDetail?.package_id ? alertDetail.package_id : '') ||
      (callInfo?.device_package_id ? callInfo.device_package_id : ''),
    location:
      (alertDetail?.device?.location_id ? alertDetail.device?.location_id : '') ||
      (callInfo?.device_location_id ? callInfo.device_location_id : ''),
    distance_from_detection_point: 0,
    severity: '',
    OD_event_detection_time: fDatePayloadFormat(new Date()),
    lat: alertDetail?.device?.lat
      ? alertDetail.device.lat
      : Number(systemSettings?.map_default_init_coordinate?.split(',')[0].slice(8)),
    lng: alertDetail?.device?.lng
      ? alertDetail.device.lng
      : Number(systemSettings?.map_default_init_coordinate?.split(',')[1].slice(7, -1)),
    notes: '',
    ecb_call_id: callInfo?.id ? callInfo?.id : ''
  });

  const { validate, errors, setErrors } = FormValidation({
    validationRules: {
      event_id: [required],
      type_id: [required],
      direction: [required],
      severity: [required],
      OD_event_detection_time: [required],
      distance_from_detection_point: [Distance]
    },
    formInput: formInputs
  });

  const direction = [
    { id: 'lhs', name: 'LHS' },
    { id: 'rhs', name: 'RHS' }
  ];

  const getIncidentType = () => {
    apiService({
      url: EventTypesListing,
      method: 'post',
      data: { event_type: formInputs.event_id }
    }).then((result) => {
      if (result?.status === SUCCESS) {
        setSubEventTypes(result.data);
        getLabels();
        getSelectedData(result.data, 'accident', 'type_id');
      }
    });
  };

  const getLatLng = () => {
    apiService({
      url: LocationLatLng,
      method: 'post',
      data: { location_id: formInputs.location }
    }).then((result) => {
      if (result?.status === SUCCESS) {
        setFormInputs({ ...formInputs, lat: result.data.lat, lng: result.data.lng });
      }
    });
  };

  const getEventListing = () => {
    apiService({
      url: EventListing
    }).then((result) => {
      if (result?.status === SUCCESS) {
        const { listing } = result.data;
        setEventListing(listing);
        getSelectedData(listing, 'incident', 'event_id');
      }
    });
  };
  const allLocation = JSON.parse(localStorage.getItem(ALL_CHAINAGE));
  const allowedChainage = localStorage.getItem(ALLOWED_CHAINAGE) === 'true';
  const getPackageLocation = () => {
    apiService({
      url: PackageLocation,
      method: 'post',
      data: { package_id: formInputs.package_id, location_ids: !allowedChainage && allLocation }
    }).then((result) => {
      if (result?.status === SUCCESS) {
        setLocationListing(result.data.listing);
      }
    });
  };

  const getMapLatLng = () => {
    apiService({ url: VsdsDevices }).then((result) => {
      if (result?.status === SUCCESS) {
        setMapLatLng(result?.data.end_points);
      }
    });
  };

  const getSeverity = () => {
    apiService({ url: SeverityListing }).then((result) => {
      if (result?.status === SUCCESS) {
        const { severity } = result?.data;
        setSeverity(severity);
      }
    });
  };
  const getPackages = () => {
    apiService({ url: DevicePackages, method: 'post' }).then((result) => {
      if (result?.status === SUCCESS) {
        const { device_packages: packageList } = result.data;
        setPackages(packageList);
      }
    });
  };

  const containerStyle = {
    position: 'relative',
    width: '100%',
    borderRadius: '10px',
    p: '5px',
    height: '300px',
    flex: '1 0 auto',
    '&::before': {
      content: '""',
      float: 'left',
      paddingTop: '100%'
    }
  };

  const followuphandler = (e) => {
    setFormInputs({ ...formInputs, OD_event_detection_time: fDatePayloadFormat(e) });
  };

  const visitEvent = (icon, data, id) => {
    ConfirmationBox({
      _icon: icon,
      _text: data,
      _confirmButton: 'View',
      _cancelButtonText: 'Close'
    }).then((result) => {
      if (result?.isConfirmed) {
        navigate(`/dashboard/event-management-system/details/${id}`);
      } else if (result?.isDismissed) {
        if (alertDetail) {
          getAlertsDetail();
        }
      }
    });
  };

  const getLabels = () => {
    const labels = {};
    eventListing?.forEach((item, key) => {
      labels[item.id] = item.label;
    });
    setLabelData(labels);
  };

  const getSelectedData = (listing, slug, key) => {
    if (markAccident) {
      listing?.forEach((item) => {
        if (item.slug === slug) {
          setFormInputs({ ...formInputs, [key]: item.id });
        }
      });
    }
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('event', JSON.stringify(formInputs));
    formData.append(`files`, formInputs.file);
    validate().then(() => {
      apiService({ url: CreateAlertEvent, method: 'post', data: formData }).then((result) => {
        if (result?.status === SUCCESS) {
          openEventDrawer.current.closeDrawer();
          const { id } = result.data;
          visitEvent('success', 'Event Created Successfully!', id);
        }
      });
    });
  };

  const handleLocation = (lat, lng) => {
    setFormInputs((prev) => ({
      ...prev,
      lat: Number.parseFloat(lat).toFixed(4),
      lng: Number.parseFloat(lng).toFixed(4)
    }));
  };

  const handleLatLng = () => {
    if (systemSettings?.map_default_init_coordinate) {
      setDefaultLatLng({
        lat: Number(systemSettings.map_default_init_coordinate.split(',')[0].slice(8)),
        lng: Number(systemSettings.map_default_init_coordinate.split(',')[1].slice(7, -1))
      });
    }
  };

  const handleMessageData = (e) => {
    const { name, value, files } = e.target;
    if (name === 'file') {
      const isValid = validateImageSize(files[0], MESSAGE_IMAGE_SIZE_ALLOWED);
      if (isValid !== true) {
        showAlert({ type: 'error', content: isValid });
        return false;
      }
      setUploadUrl(URL.createObjectURL(files[0]));
    }
    const val = name === 'file' ? files[0] : value;

    setFormInputs({ ...formInputs, [name]: val });
  };

  // useEffect(() => {
  //   if (alertDetail) {
  //     setFormInputs({
  //       ...formInputs,
  //       package_id: alertDetail.package_id,
  //       location: alertDetail.device?.location_id
  //     });
  //   }
  //   if (callInfo) {
  //     setFormInputs({
  //       ...formInputs,
  //       package_id: callInfo.device_package_id,
  //       location: callInfo.device_location_id
  //     });
  //   }
  // }, [alertDetail, callInfo]);
  // console.log(formInputs);

  useEffect(() => {
    const _packages = JSON.parse(localStorage.getItem(PACKAGES));
    if (_packages.length > 0) setPackages(_packages);
    else getPackages();
    getMapLatLng();
    getSeverity();
    getEventListing();
    handleLatLng();
  }, []);

  useEffect(() => {
    if (formInputs.package_id) {
      getPackageLocation();
    }
  }, [formInputs.package_id]);

  useEffect(() => {
    if (formInputs.location) {
      getLatLng();
    }
  }, [formInputs.location]);

  useEffect(() => {
    if (formInputs.event_id) {
      getIncidentType();
    }
  }, [formInputs.event_id]);

  useEffect(() => {
    if (errors) setErrors({});
  }, [formInputs]);
  useEffect(() => {
    if (ticketData) {
      setFormInputs({
        ...formInputs,
        package_id: ticketData.package_id,
        location: ticketData.location_id,
        direction: ticketData.direction
      });
    }
    if (tmcsData) {
      setFormInputs({
        ...formInputs,
        package_id: tmcsData?.package_id,
        location: tmcsData?.location_id,
        device_id: tmcsData?.id
      });
    }
  }, [ticketData, locationListing, tmcsData]);

  return (
    <Box>
      <Grid container spacing={3} justifyContent="space-between">
        {vidsStatus && deviceList?.length && (
          <Grid item xs={12}>
            <SelectBox
              options={deviceList}
              error={errors}
              name="device_id"
              label="Device Name"
              bindInput={bindInput}
            />
          </Grid>
        )}
        {tmcsData && (
          <Grid item xs={12}>
            <SelectBox
              options={[{ id: tmcsData.id, name: tmcsData.name }]}
              error={errors}
              name="device_id"
              label="Device Name"
              bindInput={bindInput}
            />
          </Grid>
        )}
        {callInfo?.id && (
          <Grid item xs={6}>
            <InputText
              error={errors}
              name="ecb_call_id"
              label="Call ID"
              inputProps={{ readOnly: true }}
              bindInput={bindInput}
              styleprops={{ sx: { width: '100%' } }}
            />
          </Grid>
        )}

        {alertDetail && (
          <Grid item xs={6}>
            <InputText
              error={errors}
              name="alert_id"
              label="Alert ID"
              inputProps={{ readOnly: true }}
              bindInput={bindInput}
              styleprops={{ sx: { width: '100%' } }}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          {markAccident ? (
            <SelectBox
              options={eventListing}
              error={errors}
              inputProps={{ readOnly: true }}
              name="event_id"
              label="Event type"
              bindInput={bindInput}
            />
          ) : (
            <SelectBox
              options={eventListing}
              error={errors}
              name="event_id"
              label="Event type"
              bindInput={bindInput}
            />
          )}
        </Grid>

        <Grid item xs={6}>
          {markAccident ? (
            <SelectBox
              options={subEventTypes}
              error={errors}
              inputProps={{ readOnly: true }}
              name="type_id"
              label={formInputs?.event_id ? `${labelData[formInputs?.event_id]} type` : 'Sub type'}
              bindInput={bindInput}
            />
          ) : (
            <SelectBox
              options={subEventTypes}
              error={errors}
              name="type_id"
              label={formInputs.event_id ? `${labelData[formInputs.event_id]} type` : 'Sub type'}
              bindInput={bindInput}
            />
          )}
        </Grid>

        <Grid item xs={6}>
          <SelectBox
            options={packages}
            inputProps={{
              readOnly: vidsStatus || alertDetail?.package_id || callInfo?.device_package_id
            }}
            error={errors}
            name="package_id"
            label="Package"
            bindInput={bindInput}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectBox
            options={locationListing}
            error={errors}
            inputProps={{
              readOnly:
                vidsStatus || alertDetail?.device?.location_id || callInfo?.device_location_id
            }}
            name="location"
            label="Location"
            bindInput={bindInput}
          />
        </Grid>

        <Grid item xs={6}>
          <SelectBox
            options={severity}
            error={errors}
            name="severity"
            label="Severity"
            bindInput={bindInput}
          />
        </Grid>

        <Grid item xs={6}>
          <StyledFormControl size="small">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                disableFuture
                clearIcon={null}
                onChange={(e) => {
                  setDateValue(e);
                  followuphandler(e);
                }}
                inputFormat="dd/MM/yyyy HH:mm"
                value={dateValue}
                name="OD_event_detection_time"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      '.MuiInputBase-input': { padding: '8.5px 14px !important' },
                      '.MuiPickersDay-dayWithMargin': {
                        color: 'rgb(229,228,226)',
                        backgroundColor: 'rgba(50, 136, 153)'
                      }
                    }}
                  />
                )}
                label="Detection Date & time"
              />
            </LocalizationProvider>
          </StyledFormControl>
        </Grid>

        <Grid item xs={12} sx={{ ...containerStyle }}>
          {defaultLatLng.lat && (
            <GoogleMap
              mapLatLng={mapLatLng}
              picker
              handleLocation={handleLocation}
              // onClick={(e) => handleLocation(e)}
              center={{
                lat: formInputs?.lat,
                lng: formInputs?.lng
              }}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <InputText
            error={errors}
            name="distance_from_detection_point"
            label="Distance To Detection Point(KMs)"
            bindInput={bindInput}
            styleprops={{ sx: { width: '100%' } }}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectBox
            options={direction}
            error={errors}
            name="direction"
            label="Direction"
            bindInput={bindInput}
          />
        </Grid>
        {global && (
          <>
            <Grid item xs={6}>
              <InputText
                error={errors}
                name="lat"
                label="Latitude"
                bindInput={bindInput}
                styleprops={{ sx: { width: '100%' } }}
              />
            </Grid>
            <Grid item xs={6}>
              <InputText
                error={errors}
                name="lng"
                label="Longitude"
                bindInput={bindInput}
                styleprops={{ sx: { width: '100%' } }}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <TextField
            InputLabelProps={{
              shrink: true
            }}
            name="notes"
            label="Notes"
            placeholder="Write here"
            rows={3}
            multiline
            fullWidth
            error={errors !== null && 'notes' in errors}
            {...bindInput('notes')}
          />
        </Grid>

        <Grid item xs={6}>
          <Typography variant="boxTitle" sx={{ color: theme.palette.grey[75] }}>
            Upload Attachment
          </Typography>
          <Stack
            direction="row"
            alignItems="end"
            spacing={1}
            justifyContent="space-between"
            sx={{ mt: 1 }}
          >
            {formInputs?.file ? (
              <Stack direction="row" alignItems="end" spacing={1}>
                <Box sx={{ position: 'relative' }}>
                  <GroupMessagesAvatar
                    clickHandler={() =>
                      imageViewer.current.openImageViewer(
                        uploadUrl,
                        formInputs?.file?.type.slice(0, 5),
                        'square',
                        { width: '100%', height: '80vh' },
                        { sx: { fontSize: '150px' } },
                        true
                      )
                    }
                    type={formInputs?.file?.type.slice(0, 5)}
                    variant="rounded"
                    sx={{ height: '76px', width: '76px', position: 'relative' }}
                    url={uploadUrl}
                    upload="true"
                  />
                  <Button
                    component="label"
                    sx={{
                      position: 'absolute',
                      padding: '0px',
                      minWidth: '0px',
                      top: '-10px',
                      right: '-10px'
                    }}
                  >
                    <EditIcon
                      sx={{
                        color: theme.palette.primary.contrastText,
                        background: theme.palette.error.main,
                        padding: '5px',
                        borderRadius: '50%'
                      }}
                    />
                    <input
                      hidden
                      accept="image/* , video/*"
                      name="file"
                      onChange={(e) => handleMessageData(e)}
                      type="file"
                    />
                  </Button>
                </Box>
                <Button onClick={() => setFormInputs({ ...formInputs, file: '' })}>Remove</Button>
              </Stack>
            ) : (
              <Button
                component="label"
                sx={{ padding: '20px', border: `1px solid ${theme.palette.grey[125]}` }}
              >
                <AddIcon sx={{ color: theme.palette.primary.light, fontSize: '34px' }} />
                <input
                  hidden
                  accept="image/* , video/*"
                  name="file"
                  onChange={(e) => handleMessageData(e)}
                  type="file"
                />
              </Button>
            )}
          </Stack>
        </Grid>

        <MediaViewer ref={imageViewer} />
      </Grid>
      <Box
        sx={{
          position: 'sticky',
          bottom: 0,
          marginTop: '20px',
          textAlign: 'right',
          padding: '15px',
          width: '107%',
          marginLeft: '-24px',
          background: '#00000085',
          zIndex: 99999
        }}
      >
        <Button sx={{ marginRight: '6px' }} variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default EventSlider;
