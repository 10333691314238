import { Alert } from '@mui/material';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

const AlertContext = createContext({});

const AlertProvider = ({ children }) => {
  const initialState = {
    display: false,
    content: '',
    type: 'success'
  };

  const [alert, setAlert] = useState(initialState);

  const hideAlert = () => {
    setAlert({ ...alert, content: '', display: false });
  };

  useEffect(() => {
    if (alert.display) {
      const timeId = setTimeout(() => {
        hideAlert();
      }, 5000);

      return () => {
        clearTimeout(timeId);
      };
    }
  }, [alert.display]);

  const showAlert = (params) =>
    setAlert((prevState) => ({ ...prevState, display: true, ...params }));

  const values = { showAlert, hideAlert };

  const messageType = alert.type;
  const severity = () => {
    let result;
    if (messageType === 'success') {
      result = 'success';
    } else if (messageType === 'error') {
      result = 'error';
    } else {
      result = 'info';
    }
    return result;
  };

  return (
    <AlertContext.Provider value={useMemo(() => values, [values])}>
      {alert.display && (
        <Alert
          severity={severity()}
          onClose={() => hideAlert()}
          sx={{ position: 'fixed', right: '5px', top: '5px' }}
          style={{ zIndex: 9999 }}
        >
          {alert.content}
        </Alert>
      )}

      {children}
    </AlertContext.Provider>
  );
};

export default AlertProvider;

AlertProvider.propTypes = {
  children: PropTypes.node
};
export const useAlert = () => useContext(AlertContext);
