import React, { createContext, useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getLocalStorage } from '../utils/LocalStorage';
import { USER_LEVEL } from '../constants';

const PageHeadingContext = createContext(null);

export const usePageHeading = () => useContext(PageHeadingContext);

const PageHeadingProvider = ({ children }) => {
  const [pageHeading, setPageHeading] = useState('');
  const [level, setLevel] = useState(getLocalStorage(USER_LEVEL));

  const values = {
    pageHeading,
    setPageHeading,
    level,
    setLevel
  };
  return (
    <PageHeadingContext.Provider value={useMemo(() => values, [values])}>
      {children}
    </PageHeadingContext.Provider>
  );
};

PageHeadingProvider.propTypes = {
  children: PropTypes.node
};
export default PageHeadingProvider;
