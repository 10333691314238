export const AddContact = '/crm/contacts/contact/create';
export const AlertFilters = '/alerts/alert/master-listing';
export const DevicePackages = 'device-management/devices/package/listing';
export const DevicesOverPackage = 'device-management/devices/package/device-listing';
export const PackageLocation = 'device-management/devices/device-location/listing-by-package';
export const LocationListing = 'device-management/devices/location/listing';
export const AddLocationComment = 'device-management/devices/location/add-comment';
export const LocationCommentListing = 'device-management/devices/location/comment-listing';
export const LocationLatLng = 'device-management/devices/location/get-lat-lng';
export const ModuleListing = 'device-management/devices/module/listing';
export const CreateAlerts = '/alerts/alert/create';
export const DashboardWidgets = '/fr/menu/homepage-widgets/listing';
export const Devices = 'device-management/devices/device/listing';
export const ServerDevices = 'device-management/devices/server-device/listing';
export const DeviceConfig = 'device-management/devices/device/get-device-config';
export const DeviceConfigDelete = 'device-management/devices/device/delete-device-config';
export const DeviceConfigCreate = 'device-management/devices/device/create-device-config';
export const DeviceConfigUpdate = 'device-management/devices/device/update-device-config';
export const DevicesType = 'device-management/devices/device-type/listing';
export const Tickets = '/crm/tickets/ticket/listing';
export const Alerts = '/alerts/alert/listing';
export const ExportAlert = '/alerts/alert/export-alert-report';
export const GlobalAlerts = '/alerts/alert/global-listing';
export const GlobalDeviceAlerts = '/alerts/device-down-alert/listing';
export const GlobalTickets = 'crm/tickets/ticket/global-listing';
export const TicketFilters = '/crm/tickets/ticket/filters';
export const TicketDetails = '/crm/tickets/ticket/detail';
export const TicketFollowup = '/crm/tickets/ticket/followup-listing';
export const TicketsAnalytics = '/crm/tickets/ticket/analytics';
export const EscalateTicket = '/crm/tickets/ticket/escalate-ticket';
export const AlertsDetail = '/alerts/alert/detail';
export const MarkAlertAcknowledged = 'c/alert/mark-acknowledged';
export const AlertResolvedStatus = '/crm/masters/closing-reason/listing';
export const AlertResolved = '/alerts/alert/mark-resolved';
export const TicketResolved = '/crm/tickets/ticket/mark-closed';
export const TicketAssigned = '/crm/tickets/ticket/assign-to';
export const TicketType = '/crm/masters/ticket-type-master/listing';
export const TicketUpdate = '/crm/tickets/ticket/update';
export const CrmContact = '/crm/contacts/contact/listing';
export const EmergencyContact = '/crm/contacts/contact/emergency-listing';
export const CrmUpdate = '/crm/contacts/contact/update';
export const CreateFollowup = '/crm/tickets/ticket/followup/create';
export const CreateAlertTicket = '/alerts/alert/create-ticket';
export const VMSDevices = 'device-management/devices/device/vms/listing';
export const VMSSendMessage = 'device-management/devices/device/vms/send-message';
export const FontFamilyListing = '/masters/font-family/listing';
export const UpdateDevice = 'device-management/devices/device/update';
export const OandMUsers = '/auth/user/o-and-m-user/listing';
export const CreateTickets = '/crm/tickets/ticket/create';
export const Login = '/auth/user/login';
export const Logout = '/auth/user/logout';
export const UserLoginInfo = '/auth/user/get-profile-over-token';
export const FieldTeamUsersListing = '/auth/user/get-field-team-users';
export const UpdateUserLoginInfo = '/auth/user/update-profile-by-token';
export const ChangePassword = '/auth/user/update-password';
export const ForgotPassword = '/auth/user/generate-otp';
export const ResetPassword = '/auth/user/forgot-password';
export const GetAlertResolvedStatus = '/crm/masters/status/listing';
export const VehicleAnalytics = 'device-management/devices/analytics/vehicle-speed-detection';
export const Videos = 'device-management/devices/device/tmcs/camera-listing';
export const TMCSSystemHealthApi = 'device-management/devices/device/tmcs/get-health-count';
export const Departments = '/crm/contacts/department/listing';
export const SystemSettings = 'configurations/system-setting/get-init-configurations';
export const VerifySystemSettings = 'configurations/system-setting/verify-system-config';
export const VmsMessageGroup = '/device-management/vms/vms-message-group/listing';
export const VmsMessages = '/device-management/vms/vms-messages/listing';
export const VmsDeviceTemplateCreate = '/device-management/vms/vms-device-template/create';
export const VmsGroupMessages = '/device-management/vms/vms-device-message/create';
export const ANPRVehicleDetection =
  '/device-management/detection/vids-detection/passing-vehicle-listing';
export const CreateManualDetection =
  '/device-management/detection/manual-detection/save-manual-detection-data';
export const ManualDetectionListing = '/device-management/detection/manual-detection/listing';
export const ManualDetectionDetail = '/device-management/detection/manual-detection/detail';
export const ANPRVsdsVehicleDetection =
  '/device-management/detection/ANPR-vehicle-detection/listing';
export const HistoicalLisitng =
  '/device-management/detection/ANPR-vehicle-detection/historical-data';
export const DownloadAnprDetection =
  '/device-management/detection/ANPR-vehicle-detection/get-exported-anpr-report';
export const DownloadHistoricalAnpr =
  '/device-management/detection/ANPR-vehicle-detection/download-historical-data';
export const DownloadVidsEventDetection =
  '/device-management/detection/vids-detection/export-event-data';
export const DownloadHistoricalVidsDetection =
  '/device-management/detection/vids-detection/download-historical-event-listing';
export const DownloadVidsPassingVehicleDetection =
  '/device-management/detection/vids-detection/export-passing-vehicle-listing';
export const GetVmsGroupMessages = '/device-management/vms/vms-template/listing-over-device';
export const EventList = '/device-management/detection/vids-detection/event-listing';
export const HistoricalEventList =
  '/device-management/detection/vids-detection/historical-event-listing';
export const DeviceOverModule = '/device-management/devices/device-type/listing-over-module';
export const VsdsDevices = 'device-management/devices/device/vsds/listing';
export const SaveLastSync = 'device-management/devices/device/update-last-sync';
export const SetVmsGroupMessages = '/device-management/vms/vms-messages/create';
export const VmsDeleteGroup = '/device-management/vms/vms-message-group/delete';
export const VehicleIncidentDetections = '/device-management/devices/device/vids/listing';
export const EscalationLevel = '/crm/masters/escalation-level/listing';
export const TicketTimeline = '/crm/tickets/ticket/timeline-listing';
export const UserListing = '/auth/user/listing';
export const incidentRecording = '/device-management/detection/incident-recording/fetch-incidents';
export const userDetail = '/auth/user/details';
export const FSMDashboard = '/device-management/devices/device/fms/dashboard-analytics';
export const FSMDevicesDetails = 'device-management/devices/device/details';
export const loadDefaultConfig = 'device-management/devices/device/load-config-file';
export const alignedDevicesDetails = 'device-management/devices/device/get-aligned-device';
export const VIDSDeviceDetail = '/device-management/devices/device/vids/location-wise-listing';
export const ModuleLocation = '/device-management/devices/device-location/listing';
export const SyncWithMedia = '/device-management/devices/device/media-server/create_broadcast';
export const HealthUpdate = '/device-management/devices/device/update-device-health';
export const PingIpAddress = '/device-management/devices/device/ping-ip-address';
export const CaptureRTSPFrame = '/device-management/devices/device/capture-rtsp-frame';
export const SaveAnnotation = '/device-management/devices/device/save-annotation-data';
export const GenerateConfigFile = '/device-management/devices/device/generate-config-file';
export const GenerateVSDSConfigFile = '/device-management/devices/device/generate-vsds-config-file';
export const SyncConfigFile = '/device-management/devices/device/script/run-on-remote';
export const DetectionByVehicleNumber =
  '/device-management/detection/ANPR-vehicle-detection/get-vehicle-history-by-vehicle-number';
export const GenerateConfigFileOverLocation =
  '/device-management/devices/device/generate-config-file-over-location';
export const FsmDeviceUpdate = '/device-management/devices/device/update';
export const FsmDeviceCreate = '/device-management/devices/device/create';
export const FsmDeviceDelete = '/device-management/devices/device/delete';
export const LocationGroup = '/device-management/devices/device-location-group/listing';
export const UserRoles = '/auth/roles/listing';
export const userUpdate = '/auth/user/update-user';
export const CreateUser = '/auth/user/create';
export const AdasUsersListing = '/auth/user/get-adas-users';
export const VidsAnalytics = '/device-management/devices/device/vids/analytics';
export const DeviceTickets = '/crm/tickets/ticket/device-wise-ticket-listing';
export const DeviceAlerts = '/alerts/alert/device-wise-alert-listing';
export const VsdsAnalytics = '/device-management/devices/device/vsds/analytics';
export const lastDetectionLocationWise =
  '/device-management/devices/device/last-recieved-location-data';
export const HardwareVendor = '/masters/hardware-vendor/listing';
export const CreateTemplate = '/device-management/vms/vms-message-group/save';
export const TemplateList = '/device-management/vms/vms-template-master/listing';
export const ListingOverGroup = '/device-management/vms/vms-template/listing-over-group';
export const DeleteTemplate = '/device-management/vms/vms-template/delete';
export const UpdateVmsGroup = '/device-management/vms/vms-message-group/update';
export const DashboardAnalytics = '/device-management/devices/device/dashboard/analytics';
export const LocationWiseVIDS = '/device-management/devices/device/location-wise-device-listing';
export const ECBListing = '/device-management/ecb/emergency-call/listing';
export const ECBPendingCallsByUser =
  '/device-management/ecb/emergency-call/get-pending-calls-by-user';
export const UserGuideListing = '/user-guide/listing';
export const UserGuideDetails = '/user-guide/details';
export const ECBReassignCall = '/device-management/ecb/emergency-call/reassign-call';
export const ECBDashboard = '/device-management/ecb/emergency-call/dashboard';
export const ECBDashboardDownload = '/device-management/ecb/emergency-call/dashboard-report';
export const ECBDetail = 'device-management/ecb/emergency-call/details';
export const ECBListingReport = 'device-management/ecb/emergency-call/export-listing';
export const ECBHistoryReport = 'device-management/ecb/emergency-call-history/export-listing';
export const ECBHistoryListing = 'device-management/ecb/emergency-call-history/listing';
export const MarkFakeCall = '/device-management/ecb/emergency-call/mark-as-fake-call';
export const ECBDataSave = '/device-management/ecb/emergency-call/update';
export const VehicleList = '/device-management/devices/device/vehicle/listing';
export const ExportVehicleList = '/device-management/devices/device/vehicle/export-listing';
export const VehicleDetails = '/device-management/devices/device/vehicle/details';
export const VehicleUpdate = '/device-management/devices/device/vehicle/update-vehicle';
export const DeactivateVehicle = '/device-management/devices/device/vehicle/deactivate-vehicle';
export const ActivateVehicle = '/device-management/devices/device/vehicle/activate-vehicle';
export const AttendCall = '/device-management/ecb/emergency-call/attend-call';
export const VehicleHistory = '/device-management/devices/device/vehicle/location-history';
export const AddVehicles = '/device-management/devices/device/vehicle/add-vehicle';
export const VehicleDelete = '/device-management/devices/device/vehicle/delete';
export const ECBRecording = '/device-management/ecb/emergency-call/call-recording';
export const DownloadRecording = '/device-management/ecb/emergency-call/recording-downloaded';
export const ECBCallDisconnectManually =
  '/device-management/ecb/emergency-call/call-disconnect-manually';
export const ECBMembers = '/auth/user/get-ecb-handler-users';
export const ChangeUserLevel = '/auth/user/change-user-level';
export const VehicleStatistics = '/device-management/devices/device/get-device-health-analytics';
export const exportDeviceStatistics =
  '/device-management/devices/device/export-device-health-analytics';
export const NearbyDevice = '/device-management/devices/device/get-nearby-devices';
export const NearbyVehicle = '/device-management/devices/device/vehicle/get-near-by-vehicle';
export const EventTypeList = '/crm/masters/detection/event-type/listing';
export const getAttachDevices = '/device-management/devices/device/vehicle/get-attached-devices';
export const AttachDevices = '/device-management/devices/device/vehicle/attach-vehicle';
export const ActivityLogListing = 'device-management/detection/activity-detection/listing';
export const ActivityTypeListing = '/device-management/detection/activity-detection/type-listing';
export const RemoveAttachVehicleDevice =
  '/device-management/devices/device/vehicle/remove-attach-vehicle';
export const AttachDevice = '/device-management/devices/device/attach-device';
export const SendMessage = '/device-management/devices/device/send-message-display-board';
export const TestBlinker = '/device-management/devices/device/test-blinker';
export const RemoveAttachedDevice = '/device-management/devices/device/remove-attach-device';
export const TicketTypeOnEvent = '/crm/masters/ticket-type-master/listing-over-event-type';
export const AlertHistory = '/alerts/alert/status-history/listing';
export const RemarkOnTicket = '/alerts/alert/remark-update';
export const UpdateAlert = '/alerts/alert/update';
export const IncidentRecording = '/alerts/alert/save-incident-video';
export const AlertStatusListing = '/crm/masters/status/listing';
export const GPSVendorListing = '/masters/gps-vendor/listing';
export const UpdateODVehicleNumber = '/alerts/alert/update-vehicle-number';
export const UpdateAlertStatus = '/alerts/alert/status-update';
export const ATCCAnalytics = '/device-management/devices/device/atcc/analytics';
export const ExportATCCAnalytics = '/device-management/devices/device/atcc/export-analytics';
export const ExportTTMSAnalytics = '/device-management/devices/device/ttms/export-analytics';
export const TTMSVehicleAnalytics = '/device-management/devices/device/ttms/location/analytics';
export const TTMSAnalyticsByVehicleNumber =
  '/device-management/devices/device/ttms/analytics-by-vehicle-number';
export const TTMSAnalytics = '/device-management/devices/device/ttms/analytics';
export const EventListing = '/crm/masters/detection/event-type/listing';
export const EventTypesListing = '/crm/masters/ticket-type-master/listing-over-event-type';
export const CreateAlertEvent = '/crm/tickets/ticket/create-event';
export const SeverityListing = 'device-management/devices/device/get-filters';
export const RecommendedMaster = '/crm/masters/pre-define-task/listing';
export const MasterMapping = '/masters/vehicle-class-mapping/get-vehicle-class-mapping';
export const AlertNearbyVehicle = '/alerts/alert/near-by-vehicle';
export const EventAssistance = 'crm/tickets/ticket/get-remarks-over-ticket';
export const EventAddRemark = 'crm/tickets/ticket/add-remark';
export const MarkAccident = 'crm/tickets/ticket/mark-as-accident';
export const EventAssistanceNearby = 'crm/tickets/ticket/near-by-vehicle';
export const EventClosure = 'crm/tickets/ticket/mark-closer';
export const FmsVehicleType = '/device-management/devices/device/vehicle/get-vehicle-type';
export const VehclePredefinedTask =
  '/device-management/devices/device/vehicle/get-predefined-task-over-vehicle-type';
export const VehicleAssignedTask =
  '/device-management/devices/device/vehicle-task/get-vehicle-task-details';
export const VehicleTaskAssign =
  '/device-management/devices/device/vehicle/assign-ticket-to-vehicle';
export const VehicleTaskListing = '/device-management/devices/device/vehicle-task/listing';
export const ReportImageUpload = 'crm/tickets/ticket/incident-report/upload-attachment';
export const ReportImageDelete = 'crm/tickets/ticket/incident-report/delete-attachment';
export const AccidentType = 'crm/tickets/ticket/incident-report/get-accident-types';
export const CreateVehicleReport = 'crm/tickets/ticket/incident-report/create';
export const EventReport = 'crm/tickets/ticket/incident-report/listing';
export const VehicleTypes = 'crm/tickets/ticket/event-vehicle/get-vehicle-type';
export const EventTimeLine = 'crm/tickets/ticket/timeline-listing';
export const CallInitiate = '/crm/adas-calling/adas-call/initiate-adas-call/';
export const CallRejected = '/crm/adas-calling/adas-call/adas-call/mark-as-call-rejected';
export const CallAttended = '/crm/adas-calling/adas-call/adas-call/mark-as-call-attended';
export const LiveVehicleData = '/device-management/devices/device/vehicle/get-assigned-vehicles';
export const ServiceRestart = '/system_loggings/system_logging/service/restart';
export const ServiceStatus = '/system_loggings/system_logging/service/check-status';
export const ServiceList = '/system_loggings/system_logging/service/listing';
export const ServiceStop = '/system_loggings/system_logging/service/stop';
export const ServiceStart = '/system_loggings/system_logging/service/start';
export const AgoraCallStatus = 'crm/adas-calling/adas-call/mark-as-call-attended';
export const AccidentReportListing = '/crm/reporting/reporting/accident-reporting/';
export const EventReportListing = '/crm/reporting/reporting/get-event-report/';
export const AlertReportListing = '/crm/reporting/reporting/get-alert-report/';
export const DeviceReportListing = '/crm/reporting/reporting/get-device-health-report/';
export const IPReportListing = '/crm/reporting/reporting/get-ip-address-master-report/';
export const UserLoginReportListing = '/crm/reporting/reporting/get-user-login-report/';
export const AssignedVehicle = '/crm/tickets/ticket/get-assign-vehicles';
export const AccidentListing = '/crm/tickets/ticket/get-accident-listing';
export const HtmsAlerts = '/alerts/alert/get-alerts-for-htms';
export const AdasImageUpload = '/crm/tickets/ticket/adas-report/upload-attachment';
export const AdasImageDelete = '/crm/tickets/ticket/adas-report/delete-attachment';
export const AdasIncidentListing = '/crm/tickets/ticket/adas-report/listing';
export const AdasReportData = '/crm/tickets/ticket/adas-report/details';
export const AdasReportCreate = '/crm/tickets/ticket/adas-report/create';
export const AdasReportListing = '/crm/tickets/ticket/adas-report/listing';
export const AdasVerifyToken = '/auth/token/verify';
export const AddChallan = '/crm/challan/vehicle-challan/create';
export const PushChallan = '/crm/challan/vehicle-challan/push';
export const VerifyChallan = '/crm/challan/vehicle-challan/verify';
export const RejectChallan = '/crm/challan/vehicle-challan/reject';
export const UpdateChallan = '/crm/challan/vehicle-challan/update';
export const challanDashboard = '/crm/challan/vehicle-challan/dashboard';
export const DashboardVidsAnalytics = '/device-management/devices/device/analytics/vids-analytics';
export const DashboardVsdsAnalytics = '/device-management/devices/device/analytics/vsds-analytics';
export const DashboardAlertsAnalytics =
  '/device-management/devices/device/analytics/alert-analytics';
export const DashboardEventsAnalytics =
  '/device-management/devices/device/analytics/events-analytics';
export const DashboardSystemHealthAnalytics =
  '/device-management/devices/device/analytics/system-health-analytics';
export const DashboardAccidentReportsAnalytics =
  '/device-management/devices/device/analytics/accident-report-analytics';
export const SystemHealth = '/system_loggings/system_logging/get-server-health';
export const InternetSpeed = '/system_loggings/system_logging/get-internet-speed';
export const ChallanListing = '/crm/challan/vehicle-challan/listing';
export const ChallanDetails = '/crm/challan/vehicle-challan/get-challan-details';
export const ChallanExport = '/crm/challan/vehicle-challan/get-exported-challan-report';
export const ChallanVoilation = '/crm/challan/vehicle-challan/get-ticket-type';

export const VehicleRaisedRequests = '/crm/tickets/ticket/near-by-request-vehicle';
export const RejectAdasRequest = '/adas/adas-request/reject';
export const AdasRequest = '/adas/adas-request/listing-over-alert';
export const ShiftMaster = '/masters/shift-master/listing';
export const DeviceLevelLogsListing = '/device-management/devices/device/device-level-log/listing';
export const AnsibleServiceListing = '/device-management/ansible/ansible-service/listing';
export const AnsibleServiceHistoryListing = '/device-management/ansible/ansible-service-history/listing';

// Socket Services Endpoints
export const WS_ALERTS = '/ws/alerts/consumer/alerts/';
export const WS_SPEED_DETECTION = '/ws/detection/consumer/anpr/';
export const WS_VIDS_EVENTS_DETECTION = '/ws/detection/consumer/vidsdetection/';
export const WS_VSDS_EVENTS_DETECTION = '/ws/detection/consumer/vsdsdetection/';
export const WS_AGORA_CALLING = '/ws/adas-calling/consumer/call-initiated/';
export const WS_VEHICLE_TASK = '/ws/devices/consumer/vehicle-task-web/';
export const WS_VEHICLE_UPDATE = '/ws/devices/consumer/vehicle-updates-web/';
export const WS_LIVE_ACCIDENTS = '/ws/crm_base/consumer/accident-tickets/';
export const WS_HTMS_ALERTS = '/ws/alerts/consumer/alerts-htms/';
export const WS_ECB_CALLS = '/ws/ecb/consumer/emergency_call/';
export const WS_ADAS_REQUEST = '/ws/adas/consumer/adas-request/';
export const WS_DEVICE_DOWN_ALERT = '/ws/alerts/consumer/device-down-alerts/';

// ws/devices/consumer/vehicle-updates-web/
