import { FormControl } from '@mui/material';
import React from 'react';

const StyledFormControl = ({ children, size, sx, variant, className, ...props }) => (
  <FormControl size={size} sx={sx} variant={variant} className={className} {...props}>
    {children}
  </FormControl>
);

StyledFormControl.defaultProps = {
  variant: 'outlined',
  sx: {},
  size: 'small',
  className: ''
};

export default StyledFormControl;
