import PropTypes from 'prop-types';
import AlertProvider from './AlertContext';
import LoaderProvider from './LoaderProvider';
import PageHeadingProvider from './PageHeadingProvider';
import SystemSettingsProvider from './SystemSettingsProvider';
import EcbCallContext from './EcbCallContext';
import AgoraCallProvider from './AgoraCallContext';
import DemoModeProvider from './DemoModeProvider';

const ContextsProvider = ({ children }) => (
  <AlertProvider>
    <LoaderProvider>
      <PageHeadingProvider>
        <SystemSettingsProvider>
          <DemoModeProvider>
            <AgoraCallProvider>
              <EcbCallContext>{children}</EcbCallContext>
            </AgoraCallProvider>
          </DemoModeProvider>
        </SystemSettingsProvider>
      </PageHeadingProvider>
    </LoaderProvider>
  </AlertProvider>
);

ContextsProvider.propTypes = {
  children: PropTypes.node
};
export default ContextsProvider;
