import React, { useRef } from 'react';
import { Button, Box, Grid } from '@mui/material';
import { Popup } from '../../components/popup/Popup';
import { InputText } from '../../components/form_inputs';
import useChangePassword from './useChangePassword';

// const Item = styled(Paper)(({ theme }) => ({
//   textAlign: 'center'
// }));

const { forwardRef, useImperativeHandle } = React;

const ChangePassword = forwardRef((props, ref) => {
  const popupRef = useRef();

  const { handleSubmit, bindInput, errors } = useChangePassword({
    popupHandler: popupRef
  });

  function openPopUp(status) {
    popupRef.current.openPopUp(status);
  }

  useImperativeHandle(ref, () => ({
    openPopUp
  }));

  return (
    <>
      <Popup
        title="Change Password"
        submitButton={false}
        className="popup_position"
        ref={popupRef}
        openButton={false}
        width={{ fullWidth: true, maxWidth: 'sm' }}
      >
        <Box sx={{ width: '100%' }}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <InputText
                error={errors}
                name="old_password"
                label="Old Password"
                bindInput={bindInput}
                type="password"
              />
            </Grid>
            <Grid item md={12}>
              <InputText
                error={errors}
                name="new_password"
                label="New Password"
                bindInput={bindInput}
                type="password"
              />
            </Grid>
            <Grid item md={12}>
              <InputText
                error={errors}
                name="confirm_password"
                label="Confirm Password"
                bindInput={bindInput}
                type="password"
              />
            </Grid>
            <Grid container justifyContent="center" sx={{ width: '100%' }}>
              <Button
                type="button"
                sx={{ minWidth: '20%', mt: 2 }}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Change Password
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Popup>
    </>
  );
});

export default ChangePassword;
