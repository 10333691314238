const CommonPalette = {
  primary: '#343B46',
  pink: '#DC1ABD',
  lightpink: '#E8E7FF',
  whiteFade: '#f3f3f3',
  lightGrey: '#f9f9f9',
  white: '#FFF',
  black: '#000',
  darkgrey: '#292929',
  red: '#FF2B22',
  green: '#4CAF50',
  orange: '#FA653C',
  hightlightip: '#d44950',

  videoCall: {
    background: '#434343',
    buttonsColor: '#00000085',
    typography: '#979BB1'
  }
};

export default CommonPalette;
