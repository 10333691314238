import React from 'react';
import ImageAvatar, { VideoAvatar } from '../../../components/ImageAvatar';
import { API_HOST } from '../../../constants';

const GroupMessagesAvatar = ({
  type,
  url,
  clickHandler,
  upload = false,
  variant = 'circle',
  ...props
}) =>
  type === 'image' ? (
    <ImageAvatar
      onClick={clickHandler}
      src={upload ? url : API_HOST + url}
      variant={variant}
      sx={{ cursor: 'pointer' }}
      {...props}
      brokenicon={{}}
    />
  ) : (
    <VideoAvatar onClick={clickHandler} sx={{ cursor: 'pointer' }} {...props} />
  );

export default GroupMessagesAvatar;
