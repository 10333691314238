import { memo, React, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled, useTheme } from '@mui/material/styles';
import { Box, Link, Drawer, Typography, Stack } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import sidebarConfig from './SidebarConfig';
import { useSystemSettings } from '../../contexts/SystemSettingsProvider';
import Aurigalogo from '../../components/Aurigalogo';
import ThemeSvg from '../../theme/ThemeSvg';
import { useThemeMode } from '../../contexts/DarkThemeContext';
import { DARK_THEME } from '../../constants';
// ----------------------------------------------------------------------

// const DRAWER_WIDTH = 280;

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.info.infoBlue
}));

// ----------------------------------------------------------------------

const DashboardSidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const systemSettings = useSystemSettings();
  const theme = useTheme();
  const [commandCenter, setCommandCenter] = useState({ title: '', sub_title: '' });
  const { themeStatus } = useThemeMode();
  const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
      flexShrink: 0,
      width: `${sidebarOpen ? '270px' : '0px'}`,
      maxWidth: '270px'
    },
    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
      width: `${sidebarOpen ? '270px' : '0px'}`,
      marginLeft: '20px',
      maxWidth: '270px'
    }
  }));

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  }));

  const handleDrawerClose = () => {
    setSidebarOpen(false);
  };

  const handleDrawerOpen = () => {
    setSidebarOpen(true);
  };

  useEffect(() => {
    if (systemSettings?.command_center_name)
      setCommandCenter(JSON.parse(systemSettings?.command_center_name));
  }, [systemSettings]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <DrawerHeader>
        <Box sx={{ py: 3 }}>
          <Stack direction="row" spacing="0.5">
            <Box
              component={RouterLink}
              to="/"
              sx={{
                display: 'inline-flex'
              }}
            >
              <Aurigalogo themeStatus = {themeStatus === DARK_THEME}
                  sx={{width:'85%', height:'55px', ml:'25px', display:'block'}}
               />
            </Box>
          </Stack>
        </Box>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>

      <Box sx={{ mb: 5, mx: 2.5}}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle sx={{padding: "10px 10px"}}>
            <Box>
              {commandCenter.title && (
                <Typography variant="subtitle3" sx={{ color: 'text.primary' }}>
                  {commandCenter.title}
                </Typography>
              )}
              {commandCenter.sub_title && (
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {commandCenter.sub_title}
                </Typography>
              )}
            </Box>
          </AccountStyle>
        </Link>
      </Box>
      <Stack
        direction="column"
        sx={{ height: '100%'}}
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={3}
      >
        <NavSection navConfig={sidebarConfig} />
        <Box sx={{ textAlign: 'center', marginBottom: 3, backgroundColor:theme.palette.background.default }} className="footer-branding">
          <Typography variant="caption" fontSize="13px" sx={{ position:'fixed', bottom:0, pl:'10px'}}>
            Powered By: Auriga IT Solutions Pvt. Ltd.
          </Typography>
        </Box>
      </Stack>
    </Scrollbar>
  );

  return (
    <RootStyle sx={{ zIndex: sidebarOpen ? 99 : 0 }}>
      <Drawer
        sx={{
          width: '280px',
          maxWidth: '280px',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '280px',
            maxWidth: '280px',
            boxSizing: 'border-box',
            backgroundColor: theme.palette.background.default
          }
        }}
        variant="persistent"
        anchor="left"
        open={sidebarOpen}
      >
        {renderContent}
      </Drawer>
    </RootStyle>
  );
};

export default memo(DashboardSidebar);
