export default {
  VIDEO_INCIDENT_DETECTION_SYSTEM: ['vids', 'readonly_vids'],
  VEHICLE_SPEED_DETECTION_SYSTEM: ['vsds', 'readonly_vsds'],
  TICKET_MANAGEMENT_SYSTEM: ['tms', 'readonly_tms'],
  GIS: ['gis'],
  USER_GUIDE: ['user_guide'],
  ALERT: ['alert', 'readonly_alert'],
  MANUAL_DETECTION: ['manual_detection', 'readonly_manual_detection'],
  CHALLAN: ['challan', 'readonly_challan'],
  TRAFFIC_MONITORING_CONTROL_SYSTEM: ['tmcs', 'readonly_tmcs'],
  TRAFFIC_MONITORING_CONTROL_SYSTEM_MOBILE: ['tmcs_mobile_view'],
  HIGHWAY_TRAFFICE_MONITORING_MODULE: ['htmm', 'readonly_htmm'],
  VIRTUAL_MESSAGING_SYSTEM: ['vms', 'readonly_vms'],
  EMERGENCY_CONTROLLING_BOX: ['ecb', 'readonly_ecb'],
  ACTIVITY_LOG: ['activity_log'],
  ANNOTATION_TOOL: ['annotation_tool'],
  HISTORICAL_VIDS: ['historical_vids', 'readonly_historical_vids'],
  HISTORICAL_VSDS: ['historical_vsds', 'readonly_historical_vsds'],
  REPORTS: ['reports', 'readonly_reports'],
  FMS_USERS: ['fms_users', 'readonly_fms_users'],
  FMS_DEVICES: ['fms_devices', 'readonly_fms_devices'],
  FMS_CONTACTS: ['fms_contacts', 'readonly_fms_contacts'],
  FMS_VEHICLES: ['fms_vehicles', 'readonly_fms_vehicles'],
  FMS_SERVICES: ['fms_services', 'readonly_fms_services'],
  SYSTEM_SERVICE: ['system_services'],
  get FMS_DASHBOARD() {
    return [
      this.FMS_USERS,
      this.FMS_DEVICES,
      this.FMS_VEHICLES,
      this.FMS_CONTACTS,
      this.FMS_SERVICES
    ];
  },
  ATCC: ['atcc', 'readonly_atcc'],
  TTMS: ['ttms', 'readonly_ttms'],
  ADAS: ['adas', 'readonly_adas']
};
