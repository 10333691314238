import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { fTimeDiff1 } from '../utils/formatTime';

const Timer = ({ date }) => {
  const [newTime, setNewTime] = useState();
  date = date?.split(' ');
  useEffect(() => {
    if (date[2] === 'PM') {
      const t = date[1].split(':');
      setNewTime(`${Number(t[0]) + 12}:${t[1]}`);
    } else {
      setNewTime(date[1]);
    }
  }, []);
  const [time, setTime] = useState('00:00:00');

  const tick = () => {
    const today = new Date();
    const t1 = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
    setTime(fTimeDiff1(t1, newTime));
  };

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return function cleanup() {
      clearInterval(timerID);
    };
  });

  return time;
};

export default Timer;
