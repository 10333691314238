import Swal from "sweetalert2";  

export const MessagePopup = (_type, _text) => 
(
    Swal.fire({
        icon: _type,
        text: _text,
      })
  )


