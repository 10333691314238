import { useState } from 'react';

const useForm = (form) => {
  const [formInputs, setFormInputs] = useState(form);
  const changeInput = (e) => {
    setFormInputs({ ...formInputs, [e.target.name]: e.target.value });
  };

  const bindInput = (name) => ({
    value: formInputs[name],
    onChange: changeInput
  });

  return { formInputs, bindInput, setFormInputs };
};

export default useForm;
