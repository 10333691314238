import { required, passwordPattern, confirmPassword } from '../../../utils/FormValidation';

export const ForgotPasswordForm = {
  username: ''
};

export const ForgotPasswordValidation = {
  username: [required]
};

export const ResetPasswordForm = {
  otp: '',
  new_password: '',
  confirm_password: ''
};

export const ResetPasswordFormValidation = {
  otp: [required],
  username: [required],
  new_password: [required, passwordPattern],
  confirm_password: [required, passwordPattern, confirmPassword]
};
