import { useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { SOCKET_URL } from '../constants';

const useSocket = (url, baseUrl = SOCKET_URL) => {
  const socketUrl = baseUrl + url;

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated'
  }[readyState];

  if (lastMessage !== null) {
    const alertObj = JSON.parse(lastMessage.data);
  }

  return { lastMessage, connectionStatus, sendMessage };
};

export default useSocket;
