// ----------------------------------------------------------------------

export default function Link(theme) {
  return {
    MuiLink: {
      styleOverrides: {
        root: {
          color: theme.palette.blue.main,
          textDecorationColor: theme.palette.blue.main,
          cursor: 'pointer',
          '&.theme-link': {
            color: theme.palette.blue.boxBlue,
            textDecorationColor: theme.palette.blue.boxBlue
          }
        }
      }
    }
  };
}
