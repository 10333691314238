import React from 'react';
import { Navigate, Outlet, useRoutes, useSearchParams } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
//

import AdasReport from '../pages/EventManagementSystem/Report/AdasReport';
import Login from '../pages/Login';
import NotFound from '../pages/Page404';
import checkLogin from '../utils/requireAuth';
import FMSRoleBasedRoute from './FMSRoleBasedAccess';
import RoleBasedRoute from './RoleBasedAccess';
import SiteModules from './SiteModules';

const Incident = React.lazy(() => import('../pages/EventManagementSystem/Dashboard'));
const IncidentAllData = React.lazy(
  () => import('../pages/VehicleIncidentDetectionSystem/EventAllData')
);
const ActivityLog = React.lazy(() => import('../pages/ActivityLog'));
const GIS = React.lazy(() => import('../pages/GIS'));
const TicketsList = React.lazy(() => import('../pages/ticket/List'));
const Dashboard = React.lazy(() => import('../pages/Dashboard'));
const Vms = React.lazy(() => import('../pages/VirtualMessagingSystem'));
const DeviceDetails = React.lazy(
  () => import('../pages/FacilityManagementSystem/device/DeviceDetails')
);
const SpeedDetectionSystem = React.lazy(() => import('../pages/SpeedDetectionSystem'));
const TrafficMonitoring = React.lazy(
  () => import('../pages/HighwayTrafficMonitoring/TrafficMonitoring')
);
const LastLocationListing = React.lazy(
  () => import('../pages/FacilityManagementSystem/LastLocationDetection')
);
const Ticket = React.lazy(() => import('../pages/ticket/Dashboard/index'));
const AlertListing = React.lazy(() => import('../pages/alert/AlertListing'));
const UserManual = React.lazy(() => import('../pages/UserManual'));
const TicketDetailDashboard = React.lazy(() => import('../pages/ticket/TicketDetail/index'));
const VideoMoniteringSystem = React.lazy(() => import('../pages/TrafficMonitoringControl'));
const TMCSMobileView = React.lazy(() => import('../pages/TMCSMobileView'));
const TMCSDeviceDetails = React.lazy(() => import('../pages/TrafficMonitoringControl/TMCSDeviceDetails'));
const ManualDetectionMobileView = React.lazy(() => import('../pages/ManualDetectionMobileView'));
const Profile = React.lazy(() => import('../pages/profile'));
const VehicleIncidentDetection = React.lazy(
  () => import('../pages/VehicleIncidentDetectionSystem/index')
);
const ChallanList = React.lazy(() => import('../pages/EventManagementSystem/Challan/ChallanList'));
const ChallanDashboard = React.lazy(
  () => import('../pages/EventManagementSystem/Challan/ChallanDashboard')
);
const FacilityManagementSystem = React.lazy(() => import('../pages/FacilityManagementSystem'));
const UserTable = React.lazy(() => import('../pages/UserAndRolesAdmin/Users'));
const DeviceList = React.lazy(
  () => import('../pages/FacilityManagementSystem/device/DeviceListing')
);
const VidsDeviceDetail = React.lazy(
  () => import('../pages/VehicleIncidentDetectionSystem/VidsDeviceDetail')
);
const UserDetails = React.lazy(() => import('../pages/UserAndRolesAdmin/Users/UserDetails'));
const VsdsDeviceDetail = React.lazy(() => import('../pages/SpeedDetectionSystem/VsdsDeviceDetail'));
const Atcc = React.lazy(() => import('../pages/Atcc'));
const Ttms = React.lazy(() => import('../pages/Ttms'));
const TravelTimeVehicle = React.lazy(() => import('../pages/Ttms/TravelTimeVehicle'));

const EcbDashboard = React.lazy(() => import('../pages/EmergencyControlBox/ECBDashboard'));
const EcbDeviceDetails = React.lazy(() => import('../pages/EmergencyControlBox/ECBDeviceDetails'));
const EmergencyControlBox = React.lazy(() => import('../pages/EmergencyControlBox'));
const DepartmentListing = React.lazy(
  () => import('../pages/FacilityManagementSystem/contacts/DepartmentListing')
);
const PermissionDenied = React.lazy(() => import('../pages/PermissionDenied/index'));
const Adas = React.lazy(() => import('../pages/Adas'));
const ContactListing = React.lazy(
  () => import('../pages/FacilityManagementSystem/contacts/ContactDetails/ContactListing')
);
const EmergencyDetails = React.lazy(() => import('../pages/EmergencyControlBox/ECBDetails'));
const VehicleListing = React.lazy(() => import('../pages/Adas/vehicle/VehicleListing'));
const VehicleDetail = React.lazy(() => import('../pages/Adas/vehicle/VehicleDetails'));
const AlertDetails = React.lazy(() => import('../pages/alert/AlertDetails'));
const EventList = React.lazy(() => import('../pages/EventManagementSystem/List'));
const ManageEvent = React.lazy(() => import('../pages/EventManagementSystem'));
const UserAndRolesAdmin = React.lazy(() => import('../pages/UserAndRolesAdmin'));
const Services = React.lazy(() => import('../pages/FacilityManagementSystem/services'));
const ReportsListing = React.lazy(() => import('../pages/Reports'));
const AccidentReport = React.lazy(() => import('../pages/Reports/Detail/AccidentReport'));
const EventReport = React.lazy(() => import('../pages/Reports/Detail/EventReport'));
const IPReport = React.lazy(() => import('../pages/Reports/Detail/IPReport'));
const DeviceReport = React.lazy(() => import('../pages/Reports/Detail/DeviceReport'));
const AlertReport = React.lazy(() => import('../pages/Reports/Detail/AlertReport'));
const UserLoginReport = React.lazy(() => import('../pages/Reports/Detail/UserLoginReport'));
const EmergencyContact = React.lazy(() => import('../pages/EmergencyContacts'));
const SequentailView = React.lazy(
  () => import('../pages/SpeedDetectionSystem/vsds_components/SequentailView')
);
const PassingAllData = React.lazy(
  () => import('../pages/SpeedDetectionSystem/vsds_components/EventAllData')
);
const HistoicalView = React.lazy(
  () => import('../pages/SpeedDetectionSystem/vsds_components/Historical')
);
const HistoicalViewVids = React.lazy(
  () => import('../pages/VehicleIncidentDetectionSystem/HistoicalView')
);
const SequentailViewVIDS = React.lazy(
  () => import('../pages/VehicleIncidentDetectionSystem/SequentailView')
);
const DetectionDetails = React.lazy(
  () => import('../pages/ManualDetectionMobileView/DetectionDetails')
);
const UserGuide = React.lazy(() => import('../pages/UserGuide'));
const UserGuideListing = React.lazy(() => import('../pages/UserGuide/Listing'));
const UserGuideDetails = React.lazy(() => import('../pages/UserGuide/Details'));

const Annotation = React.lazy(() => import('../components/Annotation'));

// ----------------------------------------------------------------------
export const LoginRedirection = '/dashboard';
export const TmcsMobileView = '/dashboard/tmcs-mobile-view';

export default function Router() {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');

  return useRoutes([
    {
      path: '/',
      element: (
        <Navigate to={page === 'tmcs-mobile-view' ? '/login?page=tmcs-mobile-view' : '/login'} />
      )
    },
    { path: '/adas-report/:eventId/:vehicleId/:token', element: <AdasReport /> },
    {
      element: <PrivateRoutes />,
      children: [
        {
          path: '/dashboard',
          element: <DashboardLayout />,
          children: [
            {
              path: ':page',
              element: <Dashboard headingText="ATMS" />
            },
            {
              path: '',
              element: <Dashboard headingText="ATMS" />
            },
            {
              path: 'app',
              element: <Dashboard headingText="ATMS" />
            },
            {
              path: 'annotation-tool',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.ANNOTATION_TOOL}
                  element={<Annotation headingText="Annotation Tool" />}
                />
              )
            },
            {
              path: 'user-guide',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.USER_GUIDE}
                  element={<UserGuide headingText="User Guide" />}
                />
              )
            },
            {
              path: 'user-guide/listing',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.USER_GUIDE}
                  element={<UserGuideListing headingText="User Guide Listing" />}
                />
              )
            },
            {
              path: 'user-guide/details/:id',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.USER_GUIDE}
                  element={<UserGuideDetails headingText="User Guide Details" />}
                />
              )
            },
            {
              path: 'activity-log',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.ACTIVITY_LOG}
                  element={<ActivityLog headingText="Activity Log" />}
                />
              )
            },
            {
              path: 'gis',
              element: (
                <RoleBasedRoute moduleName={SiteModules.GIS} element={<GIS headingText="GIS" />} />
              )
            },
            {
              path: 'emergency-contacts',
              element: <EmergencyContact headingText="Emergency Contacts" />
            },
            {
              path: 'traffic-monitoring',
              element: (
                <RoleBasedRoute
                  element={
                    <TrafficMonitoring
                      headingText="Highway Traffic Management System"
                      boxHeight="70vh"
                    />
                  }
                  moduleName={SiteModules.HIGHWAY_TRAFFICE_MONITORING_MODULE}
                />
              )
            },
            {
              path: 'ticket-listing',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.TICKET_MANAGEMENT_SYSTEM}
                  element={<TicketsList headingText="Ticket Management System" />}
                />
              )
            },
            {
              path: 'ticket-dashboard',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.TICKET_MANAGEMENT_SYSTEM}
                  element={<Ticket headingText="Ticket Management System" />}
                />
              )
            },
            {
              path: 'challan-dashboard',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.CHALLAN}
                  element={<ChallanDashboard headingText="Vehicle Challans" />}
                />
              )
            },
            {
              path: 'challan-list',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.CHALLAN}
                  element={<ChallanList headingText="Vehicle Challans" />}
                />
              )
            },
            {
              path: 'ticket-details/:id',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.TICKET_MANAGEMENT_SYSTEM}
                  element={<TicketDetailDashboard headingText="Ticket Details" />}
                />
              )
            },
            {
              path: 'traffic-monitoring-and-control-system',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.TRAFFIC_MONITORING_CONTROL_SYSTEM}
                  element={
                    <VideoMoniteringSystem headingText="Traffic Monitoring and Control System" />
                  }
                />
              )
            },
            {
              path: 'tmcs-mobile-view',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.TRAFFIC_MONITORING_CONTROL_SYSTEM_MOBILE}
                  element={<TMCSMobileView headingText="TMCS" />}
                />
              )
            },
            {
              path: 'tmcs/tmcs-device-details',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.TRAFFIC_MONITORING_CONTROL_SYSTEM}
                  element={<TMCSDeviceDetails headingText="Traffic Monitoring Control System" />}
                />
              )
            },
            {
              path: 'manual-detection-mobile-view',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.MANUAL_DETECTION}
                  element={<ManualDetectionMobileView headingText="Manual Detection" />}
                />
              )
            },
            {
              path: 'manual-detection-mobile-view/detail/:id',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.MANUAL_DETECTION}
                  element={<DetectionDetails headingText="Manual Detection" />}
                />
              )
            },
            {
              path: 'speed-detection-system',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.VEHICLE_SPEED_DETECTION_SYSTEM}
                  element={<SpeedDetectionSystem headingText="Vehicle Speed Detection System" />}
                />
              )
            },
            {
              path: 'speed-detection-system/device-details',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.VEHICLE_SPEED_DETECTION_SYSTEM}
                  element={<VsdsDeviceDetail headingText="Vehicle Speed Detection System" />}
                />
              )
            },
            {
              path: 'speed-detection-system/passing-vehicles',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.VEHICLE_SPEED_DETECTION_SYSTEM}
                  element={<PassingAllData headingText="Vehicle Speed Detections" />}
                />
              )
            },
            {
              path: 'speed-detection-system/historical-view',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.HISTORICAL_VSDS}
                  element={<HistoicalView headingText="Historical Vehicle Speed Detections" />}
                />
              )
            },
            {
              path: 'speed-detection-system/sequential-view',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.VEHICLE_SPEED_DETECTION_SYSTEM}
                  element={<SequentailView headingText="VSDS Sequential View" />}
                />
              )
            },
            {
              path: 'variable-messaging-system',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.VIRTUAL_MESSAGING_SYSTEM}
                  element={<Vms headingText="Variable Messaging System" />}
                />
              )
            },
            {
              path: 'reports',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.REPORTS}
                  element={<ReportsListing headingText="Reports" />}
                />
              )
            },
            {
              path: 'reports/accident-report',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.REPORTS}
                  element={<AccidentReport headingText="Accident Report" />}
                />
              )
            },
            {
              path: 'reports/event-report',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.REPORTS}
                  element={<EventReport headingText="Event Report" />}
                />
              )
            },
            {
              path: 'reports/alert-report',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.REPORTS}
                  element={<AlertReport headingText="Alert Report" />}
                />
              )
            },
            {
              path: 'reports/device-report',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.REPORTS}
                  element={<DeviceReport headingText="Device Report" />}
                />
              )
            },
            {
              path: 'reports/user-login-report',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.REPORTS}
                  element={<UserLoginReport headingText="User Login Report" />}
                />
              )
            },
            {
              path: 'reports/ip-address-report',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.REPORTS}
                  element={<IPReport headingText="IP Address Report" />}
                />
              )
            },

            {
              path: 'vehicle-incident-detection-system',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.VIDEO_INCIDENT_DETECTION_SYSTEM}
                  element={
                    <VehicleIncidentDetection headingText="Video Incident Detection System" />
                  }
                />
              )
            },
            {
              path: 'vehicle-incident-detection-system/sequential-view',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.VIDEO_INCIDENT_DETECTION_SYSTEM}
                  element={<SequentailViewVIDS headingText="VIDS Sequential View" />}
                />
              )
            },
            {
              path: 'vehicle-incident-detection-system/events',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.VIDEO_INCIDENT_DETECTION_SYSTEM}
                  element={<IncidentAllData headingText="Video Incident Detection System" />}
                />
              )
            },
            {
              path: 'vehicle-incident-detection-system/historical-view',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.HISTORICAL_VIDS}
                  element={<HistoicalViewVids headingText="Historical Video Incident Detections" />}
                />
              )
            },
            {
              path: 'vehicle-incident-detection-system/device-details',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.VIDEO_INCIDENT_DETECTION_SYSTEM}
                  element={<VidsDeviceDetail headingText="Video Incident Detection System" />}
                />
              )
            },
            {
              path: 'facility-management-system',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.SYSTEM_SERVICE}
                  element={<FacilityManagementSystem headingText="System Services" />}
                />
              )
            },
            {
              path: 'facility-management-system/location-wise-detection',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.SYSTEM_SERVICE}
                  element={<LastLocationListing headingText="Location Wise Detection Data" />}
                />
              )
            },
            {
              path: 'facility-management-system/devices/:id',
              element: (
                <FMSRoleBasedRoute
                  moduleName={SiteModules.FMS_DEVICES}
                  element={<DeviceDetails headingText="Device Details" />}
                />
              )
            },
            {
              path: 'user-and-roles-admin',
              element: (
                <FMSRoleBasedRoute
                  moduleName={SiteModules.FMS_USERS}
                  element={<UserAndRolesAdmin headingText="Users & Roles Administration " />}
                />
              )
            },
            {
              path: 'user-and-roles-admin/users',
              element: (
                <FMSRoleBasedRoute
                  moduleName={SiteModules.FMS_USERS}
                  element={<UserTable headingText="Users & Roles Administration " />}
                />
              )
            },
            {
              path: 'user-and-roles-admin/users/:id',
              element: (
                <FMSRoleBasedRoute
                  moduleName={SiteModules.FMS_USERS}
                  element={<UserDetails headingText="User Details" />}
                />
              )
            },
            {
              path: 'facility-management-system/devices',
              element: (
                <FMSRoleBasedRoute
                  moduleName={SiteModules.FMS_DEVICES}
                  element={<DeviceList headingText="Devices" />}
                />
              )
            },
            {
              path: 'facility-management-system/department/contacts',
              element: (
                <FMSRoleBasedRoute
                  moduleName={SiteModules.FMS_CONTACTS}
                  element={<DepartmentListing headingText="Contacts" />}
                />
              )
            },

            {
              path: 'facility-management-system/department/contacts/:id',
              element: (
                <FMSRoleBasedRoute
                  moduleName={SiteModules.FMS_CONTACTS}
                  element={<ContactListing headingText="Contacts" />}
                />
              )
            },
            {
              path: 'adas/vehicles',
              element: (
                <FMSRoleBasedRoute
                  moduleName={SiteModules.FMS_VEHICLES}
                  element={<VehicleListing headingText="Vehicles" />}
                />
              )
            },
            {
              path: 'adas/vehicles/:id',
              element: (
                <FMSRoleBasedRoute
                  moduleName={SiteModules.FMS_VEHICLES}
                  element={<VehicleDetail headingText="Vehicles" />}
                />
              )
            },
            {
              path: 'facility-management-system/department/services',
              element: (
                <FMSRoleBasedRoute
                  moduleName={SiteModules.FMS_SERVICES}
                  element={<Services headingText="Services" />}
                />
              )
            },
            {
              path: 'atcc',
              element: (
                <FMSRoleBasedRoute
                  moduleName={SiteModules.ATCC}
                  element={<Atcc headingText="Automatic Traffic Counter Cum Classifier " />}
                />
              )
            },
            {
              path: 'ttms',
              element: (
                <FMSRoleBasedRoute
                  moduleName={SiteModules.TTMS}
                  element={<Ttms headingText="Travel Time Measurement System" />}
                />
              )
            },
            {
              path: 'ttms/details',
              element: (
                <FMSRoleBasedRoute
                  moduleName={SiteModules.TTMS}
                  element={<TravelTimeVehicle headingText="Travel Time Measurement System" />}
                />
              )
            },
            {
              path: 'adas',
              element: (
                <FMSRoleBasedRoute
                  moduleName={SiteModules.ADAS}
                  element={<Adas headingText="Advanced Driver Assistance Systems" />}
                />
              )
            },
            {
              path: 'emergency-calling-box/listing',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.EMERGENCY_CONTROLLING_BOX}
                  element={<EmergencyControlBox headingText="Emergency Calling Box" />}
                />
              )
            },
            {
              path: 'event-management-system',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.TICKET_MANAGEMENT_SYSTEM}
                  element={<Incident headingText="Event Management System" />}
                />
              )
            },
            {
              path: 'event-management-system/listing',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.TICKET_MANAGEMENT_SYSTEM}
                  element={<EventList headingText="Event Management System" />}
                />
              )
            },
            {
              path: 'event-management-system/details/:id',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.TICKET_MANAGEMENT_SYSTEM}
                  element={<ManageEvent headingText="Event Management System" />}
                />
              )
            },
            {
              path: 'emergency-calling-box/dashboard',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.EMERGENCY_CONTROLLING_BOX}
                  element={<EcbDashboard headingText="Emergency Calling Box" />}
                />
              )
            },
            {
              path: 'emergency-calling-box/ecb-device-details',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.EMERGENCY_CONTROLLING_BOX}
                  element={<EcbDeviceDetails headingText="Emergency Calling Box" />}
                />
              )
            },
            {
              path: 'emergency-calling-box/details/:id',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.EMERGENCY_CONTROLLING_BOX}
                  element={<EmergencyDetails headingText="Emergency Calling Box" />}
                />
              )
            },
            {
              path: 'permission-denied',
              element: <PermissionDenied />
            },
            {
              path: 'alert-listing',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.ALERT}
                  element={<AlertListing headingText="Alerts" />}
                />
              )
            },
            {
              path: 'alert-details/:id',
              element: (
                <RoleBasedRoute
                  moduleName={SiteModules.ALERT}
                  element={<AlertDetails headingText="Alerts Details" />}
                />
              )
            },
            {
              path: 'user-manual',
              element: <UserManual headingText="User Manual" />
            },
            {
              path: 'profile',
              element: <Profile headingText="Edit Profile" />
            }
          ]
        }
      ]
    },
    {
      path: '/',
      exact: true,
      children: [
        {
          path: 'login',
          element: <RestrictedRoutes page={page} />,
          children: [{ element: <Login /> }]
        },
        {
          path: 'register',
          element: <RestrictedRoutes page={page} />,
          children: [{ element: <Login /> }]
        },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

export const PrivateRoutes = () => (checkLogin() ? <Outlet /> : <Navigate to="/login" />);

export const RestrictedRoutes = ({ page }) =>
  !checkLogin() ? (
    <Outlet />
  ) : (
    <Navigate to={page === 'tmcs-mobile-view' ? TmcsMobileView : LoginRedirection} />
  );
