import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
// material
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material/styles';
//
import { DARK_THEME } from '../constants';
import { useAuthContext } from '../contexts/AuthContext';
import { useThemeMode } from '../contexts/DarkThemeContext';
import CommonPalette from './CommonPalette';
import darkpalette from './darkpalette';
import componentsOverride from './overrides';
import palette from './palette';
import shadows, { customShadows } from './shadows';
import shape from './shape';
import typography from './typography';

// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node
};

export default function ThemeConfig({ children }) {
  const { themeStatus } = useThemeMode();
  const { isLoggedIn } = useAuthContext();

  const themeOptions = useMemo(
    () => ({
      CommonPalette,
      palette: themeStatus === DARK_THEME ? darkpalette : palette,
      shape,
      typography,
      shadows,
      customShadows,
      mode: themeStatus
    }),
    [themeStatus, isLoggedIn()]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);
  useEffect(() => {
    const body = document.getElementById('body');
    body.style.backgroundColor = theme.palette.background.default;
    if (themeStatus === DARK_THEME && isLoggedIn()) {
      body.classList.add('dark-theme');
      body.classList.remove('light-theme');
    } else {
      body.classList.add('light-theme');
      body.classList.remove('dark-theme');
    }
  }, [themeStatus, isLoggedIn()]);

  useEffect(() => {
    const root = document.getElementById('root');
    root.style.overflowX = 'auto';
    // const size = document.documentElement.clientWidth;
    root.style.minWidth = `1440px`;
    // if (size) {
    // }
    // console.log(document.documentElement.clientWidth);
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
