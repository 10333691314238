import React, { useState, useEffect } from 'react';
import { Badge, Box, Fab, Tab, Tabs, Slide, useTheme, styled } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { ArrowRight } from '@mui/icons-material';
import { AdasRequest, WS_ADAS_REQUEST } from '../../services/ApiEndpoints';
import ApiService from '../../services/ApiService';
import styles from './Alerts.module.scss';
import Scrollbar from '../Scrollbar';
import { a11yProps, TabPanel } from '../tabs';
import { ALL_PACKAGES, MAX_GLOBAL_DATA, SUCCESS } from '../../constants';
import useSocket from '../../_hooks/Socket';
import AdasBadge from './AdasBadge';
import AlertDetails from '../Tickets/AlertDetails';
import siteMessages from '../../alert_messages';

// ----------------------------------------------------------------------

const RootStyleMain = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    // width: `${toggle ? '296px' : 'maxWidth'}`
    width: 'maxWidth'
  }
}));

const GlobalAdasRequest = ({ slideAdasOpen, setSlideAdasOpen, setAdasInfo }) => {
  const { apiService } = ApiService();
  const allPackage = JSON.parse(localStorage.getItem(ALL_PACKAGES));
  const { palette, CommonPalette } = useTheme();
  const [totalAdasRequest, setTotalAdasRequest] = useState(0);
  const [value, setValue] = useState(0);
  const [adasData, setAdasData] = useState([]);
  const { lastMessage } = useSocket(WS_ADAS_REQUEST);
  const [loading, setLoading] = useState(false);
  const [newAdas, setNewAdas] = useState(0);

  useEffect(() => {
    if (lastMessage !== null) {
      const { data } = JSON.parse(lastMessage.data);
      if (adasData?.length > MAX_GLOBAL_DATA) {
        adasData.pop();
      }
      setAdasData((prev) => [{ ...data, isNew: true }, ...prev]);
      setTotalAdasRequest((prev) => prev + 1);
      setNewAdas((prev) => prev + 1);
    }
  }, [lastMessage]);

  const getAdasRequest = () => {
    setLoading(true);
    apiService(
      { url: AdasRequest, method: 'post', data: { package_ids: allPackage } },
      { loader: false }
    )
      .then((result) => {
        if (result?.status === SUCCESS) {
          const _data = result.data;
          setAdasData(_data);
          setTotalAdasRequest(_data.length);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setAdasInfo((prev) => ({
      ...prev,
      totalAdas: totalAdasRequest,
      onClick: () => setSlideAdasOpen((prev) => !prev)
    }));
  }, [adasData, totalAdasRequest]);

  useEffect(() => {
    setAdasInfo((prev) => ({
      ...prev,
      newAdas
    }));
  }, [newAdas]);

  const markAdasRead = (alertInfo) => {
    setAdasData(
      adasData.map((item) => (item.id === alertInfo.id ? { ...item, isNew: false } : item))
    );
    if (alertInfo?.isNew) setNewAdas((prev) => prev - 1);
  };

  useEffect(() => {
    getAdasRequest();
  }, []);
  const handleCheckChange = () => {
    setSlideAdasOpen((prev) => !prev);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <RootStyleMain>
      <Box style={{ position: 'fixed', right: '0px', zIndex: '9' }}>
        <Fab
          sx={{
            left: '-25px',
            background: CommonPalette.lightpink,
            display: slideAdasOpen ? 'show' : 'none',
            borderBottom: '1px solid #dee2e6 !important',
            borderLeft: '1px solid #dee2e6 !important',
            height: '32px',
            zIndex: 22,
            width: '30px',
            borderRadius: '5px 0px 0px 5px',
            position: 'absolute',
            boxShadow: 'none'
          }}
          onClick={handleCheckChange}
          size="small"
        >
          <ArrowRight sx={{ fontSize: '25px !important' }} />
        </Fab>
        <Slide
          direction="left"
          in={slideAdasOpen}
          mountOnEnter
          unmountOnExit
          sx={{
            background: palette.background.main,
            boxShadow: '-10px 0px 20px rgb(0 0 0 / 25%)'
          }}
        >
          <Box className="global-alert-container">
            <Scrollbar
              sx={{
                height: '100%',
                '& .simplebar-content': {
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }
              }}
            >
              <Box sx={{ borderRadius: '10px' }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="fullWidth"
                  scrollButtons={false}
                  aria-label="scrollable prevent tabs example"
                >
                  <Tab
                    className={styles.tab_title_button}
                    icon={
                      <Badge
                        className="badge-icon"
                        badgeContent={totalAdasRequest}
                        color="error"
                        max={totalAdasRequest + 1}
                      />
                    }
                    label="Adas Requests"
                    {...a11yProps(0)}
                  />
                </Tabs>
              </Box>

              <TabPanel value={value} index={0}>
                <Box>
                  {adasData.length
                    ? adasData.map((item, index) => (
                        <AdasBadge markAdasRead={markAdasRead} adasInfo={item} key={index} />
                      ))
                    : !loading && (
                        <Box paddingY={1}>
                          <AlertDetails
                            classname="alert-danger"
                            data={siteMessages.NoAdasRequest}
                          />
                        </Box>
                      )}
                </Box>
              </TabPanel>
            </Scrollbar>
          </Box>
        </Slide>
      </Box>
    </RootStyleMain>
  );
};

export default GlobalAdasRequest;
