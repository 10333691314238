import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { toast } from 'react-toastify';
// material
import {
  Stack,
  Checkbox,
  TextField,
  IconButton,
  useTheme,
  InputAdornment,
  FormControlLabel,
  Typography,
  Button,
  Grid,
  useMediaQuery
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { LoginRedirection } from '../../../routing/routes';
import LSM from '../../../utils/lsm';
import { Login } from '../../../services/ApiEndpoints';
import ApiService from '../../../services/ApiService';
import { SUCCESS } from '../../../constants';
import ForgotPassword from '../forogt_password';
import { InputText } from '../../form_inputs';
import useForm from '../../../_hooks/useForm';
import FormValidation, { required } from '../../../utils/FormValidation';
import { usePageHeading } from '../../../contexts/PageHeadingProvider';
// ----------------------------------------------------------------------

export default function LoginForm({ page }) {
  const { apiService } = ApiService();
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { setLevel } = usePageHeading();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [message, setMessage] = useState();
  const { formInputs, setFormInputs, bindInput } = useForm({
    username: '',
    password: '',
    remember: true
  });

  const { validate, errors, setErrors } = FormValidation({
    validationRules: {
      username: [required],
      password: [required]
    },
    formInput: formInputs
  });

  // const LoginSchema = Yup.object().shape({
  //   email: Yup.string().required('Username is required'),
  //   password: Yup.string().required('Password is required')
  // });

  const loginFunc = async () => {
    validate().then(() => {
      apiService(
        {
          url: Login,
          method: 'post',
          data: formInputs
        },
        { setErrorMessage }
      )
        .then((result) => {
          if (result?.status === SUCCESS) {
            LSM.setSuperUser(result.data.is_superuser);
            LSM.setDevices(result.data.devices);
            LSM.setAccessToken(
              result.data.access,
              result.data.refresh,
              result.data.expired_in_hours
            );
            LSM.setPermissions(result.data.permissions);
            LSM.setLoggedinDisplayName(result.data.username);
            LSM.setUserID(result.data.user_id);
            LSM.setUserLevel(result.data.level);
            LSM.setPackages(result.data.packages);
            LSM.setAllPackages(result.data.packages);
            LSM.setChainage(result.data.chainages);
            LSM.setAllChainage(result.data.chainages);
            LSM.setallowedAllChainage(result.data.allow_all_chainages);
            setLevel(result.data.level);
            if (page) {
              navigate('/dashboard/tmcs-mobile-view', { replace: true });
            } else {
              navigate(LoginRedirection, { replace: true });
            }
          } else {
            // setInvalidCredentials(true);
            toast.error(result.message);
          }
        })
        .catch((result) => {
          // setInvalidCredentials(true);
        });
    });
  };

  // const formik = useFormik({
  //   initialValues: {
  //     email: '',
  //     password: '',
  //     remember: true
  //   },
  //   validationSchema: LoginSchema,
  //   validateOnChange: false,
  //   validateOnBlur: false,
  //   onSubmit: () => {
  //     const _username = formik.values.email;
  //     const _password = formik.values.password;
  //     loginFunc(_username, _password);
  //   }
  // });

  // const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setErrors } = formik;

  const handleChange = (event) => {
    setFormInputs({ ...formInputs, remember: event.target.checked });
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') loginFunc();
  };

  useEffect(() => {
    if (errorMessage?.data?.message) {
      setMessage(errorMessage?.data?.message);
    }
  }, [errorMessage]);

  useEffect(() => {
    setMessage('');
  }, [formInputs]);

  return (
    // <FormikProvider value={formInputs}>
    //   <Form autoComplete="off" onSubmit={loginFunc}>
    <>
      <Stack spacing={3}>
        <InputText
          error={errors}
          name="username"
          label="Username"
          bindInput={bindInput}
          type="email"
          onKeyDown={handleSearchKeyPress}
        />
        <TextField
          error={errors !== null && 'password' in errors}
          sx={{ minWidth: '100%' }}
          size="small"
          helperText={<>{errors !== null && 'password' in errors && errors.password}</>}
          {...bindInput('password')}
          label="Password"
          name="password"
          type={showPassword ? 'text' : 'password'}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                </IconButton>
              </InputAdornment>
            )
          }}
          onKeyDown={handleSearchKeyPress}
        />
        {/* <InputText
          error={errors}
          name="password"
          label="Password"
          bindInput={bindInput}
          type={showPassword ? 'text' : 'password'}
          inputProps={{
            endadornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                </IconButton>
              </InputAdornment>
            )
          }}
        /> */}
        {/* <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Username"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          /> */}
        {message && <Typography sx={{ color: 'red', mt: '8px !important' }}>{message}</Typography>}
      </Stack>

      <Grid container my={2} alignItems="center">
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                sx={{ color: 'blue' }}
                // {...getFieldProps('remember')}
                // checked={values.remember}
                checked={formInputs.remember}
                onChange={handleChange}
              />
            }
            label={<Typography sx={{ fontSize: smDown && '12px' }}>Remember me</Typography>}
          />
        </Grid>
        <Grid item xs={6} textAlign="end">
          <ForgotPassword />
        </Grid>
      </Grid>

      {/* <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      ></Stack> */}

      <Button
        fullWidth
        size="large"
        type="submit"
        onClick={loginFunc}
        sx={{ backgroundColor: theme.palette.blue.main }}
        variant="contained"
      >
        Login
      </Button>
    </>
    //   </Form>
    // </FormikProvider>
  );
}
