import { useEffect, useState } from 'react';
import { RtcTokenBuilder, RtcRole } from 'agora-access-token';
import { useSystemSettings } from '../../contexts/SystemSettingsProvider';

const options = {
  // Pass your temp token here.
  tokenType: 'uid',
  // Set the user ID.
  uid: 0,
  // Set the user role.
  role: RtcRole.PUBLISHER,
  // Set token expire time.
  ExpireTime: 3000
};

const useActions = (props) => {
  const [inCall, setInCall] = useState(false);
  const [config, setConfig] = useState({ channelName: '', rtcToken: '' });
  const systemSettings = useSystemSettings();
  const [agoraId, setAgoraId] = useState();
  const [agoraCertificate, setAgoraCertificate] = useState();

  useEffect(() => {
    if (config.channelName) {
      createChannel();
    }
  }, [config.channelName]);

  useEffect(() => {
    if (!inCall) {
      setConfig({ channelName: '', rtcToken: '' });
    }
  }, [inCall]);

  const endCall = () => {
    setInCall(false);
  };

  const createChannel = () => {
    if (!config.channelName) {
      alert('Channel name is required');
      return false;
    }
    const expirationTimeInSeconds = 3600;
    const currentTimestamp = Math.floor(Date.now() / 1000);
    const privilegeExpiredTs = currentTimestamp + expirationTimeInSeconds;

    const rtcToken = RtcTokenBuilder.buildTokenWithUid(
      agoraId,
      agoraCertificate,
      config.channelName,
      options.uid,
      options.role,
      privilegeExpiredTs
    );

    if (rtcToken) {
      setConfig((prev) => ({
        ...prev,
        rtcToken
      }));

      setInCall(true);
    }
  };
  useEffect(() => {
    if (systemSettings?.web_agora_app_id && systemSettings?.web_agora_app_certificate) {
      setAgoraId(systemSettings.web_agora_app_id);
      setAgoraCertificate(systemSettings.web_agora_app_certificate);
    }
  }, [systemSettings]);

  const funcs = { endCall, inCall, config, setInCall, setConfig };

  return funcs;
};

export default useActions;
