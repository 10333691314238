const { Box } = require('@mui/material');

const SquareBox = ({ children, sx, ...props }) => {
  const containerStyle = {
    position: 'relative',
    width: '100%',
    borderRadius: '10px',
    flex: '1 0 auto',
    '&::before': {
      content: '""',
      float: 'left',
      paddingTop: '100%'
    }
  };
  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around', ...sx }}>
      <Box sx={containerStyle}>{children}</Box>
    </Box>
  );
};

export const RectangleBox = ({ children, sx, ...props }) => {
  const containerStyle = {
    position: 'relative',
    width: '100%',
    borderRadius: '10px',
    flex: '1 0 auto',
    '&::before': {
      content: '""',
      float: 'left',
      paddingTop: '58%'
    }
  };
  return (
    <Box
      sx={{
        width: '100%',
        maxHeight: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        ...sx
      }}
    >
      <Box sx={containerStyle}>{children}</Box>
    </Box>
  );
};

export default SquareBox;
