import { Box } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import { useEffect, useState } from 'react';
import { useSystemSettings } from '../contexts/SystemSettingsProvider';

const GoogleMap = ({
  center,
  mapLatLng,
  borderRadius = '10px',
  children,
  picker,
  handleLocation,
  ...props
}) => {
  const defaultProps = {
    center,
    zoom: 12
  };
  const systemSettings = useSystemSettings();
  const [googleMapKey, setGoogleMapKey] = useState();

  const googleApiIsLoaded = (map, maps) => {
    if (maps) {
      const image = {
        url: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png',
        // This marker is 20 pixels wide by 32 pixels high.
        size: new window.google.maps.Size(20, 32),
        // The origin for this image is (0, 0).
        origin: new window.google.maps.Point(0, 0),
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new window.google.maps.Point(0, 32)
      };
      const directionsService = new window.google.maps.DirectionsService();
      const directionsRenderer = new window.google.maps.DirectionsRenderer({
        polylineOptions: { strokeColor: '#1073cb', strokeWeight: 8, fillColor: '#1073cb' },
        suppressMarkers: true,
        preserveViewport: true
      });
      directionsRenderer.setMap(map);
      // if (mapLatLng) calculateAndDisplayRoute(directionsService, directionsRenderer);

      if (picker) {
        const marker = new window.google.maps.Marker({
          map,
          draggable: true,
          animation: window.google.maps.Animation.DROP,
          position: new window.google.maps.LatLng(center.lat, center.lng),
          icon: image
        });
        window.google.maps.event.addListener(marker, 'dragend', (marker) => {
          const { latLng } = marker;
          const currentLatitude = latLng.lat();
          const currentLongitude = latLng.lng();
          handleLocation(currentLatitude, currentLongitude);
        });
      }
    }
  };
  function calculateAndDisplayRoute(directionsService, directionsRenderer) {
    const start = new window.google.maps.LatLng(mapLatLng.start_lat, mapLatLng.start_lng);
    const end = new window.google.maps.LatLng(mapLatLng.end_lat, mapLatLng.end_lng);

    directionsService
      .route({
        origin: start,
        destination: end,
        travelMode: window.google.maps.TravelMode.DRIVING
      })
      .then((response) => {
        directionsRenderer.setDirections(response);
      })
      .catch(() => window.alert('Directions request failed due to '));
  }

  useEffect(() => {
    if (systemSettings?.web_google_map_api_key)
      setGoogleMapKey(systemSettings?.web_google_map_api_key);
  }, [systemSettings]);

  return (
    <Box sx={{ borderRadius, width: '100%', height: '100%', overflow: 'hidden' }}>
      {googleMapKey && (
        <GoogleMapReact
          sx={{ height: '100%', width: '100%', position: 'relative' }}
          bootstrapURLKeys={{ key: googleMapKey }}
          center={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => googleApiIsLoaded(map, maps)}
          {...props}
        >
          {children}
        </GoogleMapReact>
      )}
    </Box>
  );
};

export default GoogleMap;
