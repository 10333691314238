import axios from 'axios';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  USER,
  LOGGED_IN_USER_DISPLAY,
  ACCESS,
  REFRESH,
  EXPIRED_AT,
  SYSTEM_SETTINGS,
  REFRESH_AT,
  SUCCESS,
  API_HOST
} from '../constants';
import LSM from '../utils/lsm';
import { getLocalStorage, removeFromStorage } from '../utils/LocalStorage';
import ApiService from '../services/ApiService';
import { Logout } from '../services/ApiEndpoints';

export const AuthContext = React.createContext({});

const userInfo = {
  username: '',
  email: '',
  profileImage: '/static/mock-images/avatars/profile_image.png'
};

export const AuthProvider = ({ children }) => {
  const mobileView = localStorage.getItem('mobileView');
  const [user, setUser] = useState(userInfo);
  const navigate = useNavigate();
  const { refreshToken, apiService } = ApiService();
  const API_BASE_URL = `${String(API_HOST)}/api/v1`;
  let headers = {
    'Content-Type': 'application/json'
  };

  const access = localStorage.getItem(ACCESS);
  if (access) {
    headers = { ...headers, Authorization: `Bearer ${access}` };
  }

  const isLoggedIn = () =>
    getLocalStorage(ACCESS) !== null && Object.keys(getLocalStorage(ACCESS)).length > 0;

  useEffect(() => {
    setUser({ ...userInfo, username: getLocalStorage(LOGGED_IN_USER_DISPLAY) });

    if (isLoggedIn()) {
      // Refresh every hour
      const interval = setInterval(() => {
        callRefresh();
      }, 3600000);

      return () => clearInterval(interval);
    }
  }, [isLoggedIn()]);

  const setAuthUser = (userData) => {
    // setLocalStroage(USER, userData);
    setUser({ ...user, ...userData });
  };
  const logoutUser = () => {
    axios({
      url: API_BASE_URL + Logout,
      headers
    }).then((result) => {
      const { data } = result;
      if (data?.status === SUCCESS) {
        removeFromStorage(USER);
        removeFromStorage(ACCESS);
        removeFromStorage(REFRESH);
        removeFromStorage(REFRESH_AT);
        removeFromStorage(EXPIRED_AT);
        removeFromStorage(SYSTEM_SETTINGS);
        // sessionStorage.clear();
        setUser({});
        if (mobileView) {
          navigate('/login?page=tmcs-mobile-view', { replace: true });
        } else {
          navigate('/login', { replace: true });
        }
      }
    });
  };

  const logoutUnauthUser = () => {
    removeFromStorage(USER);
    removeFromStorage(ACCESS);
    removeFromStorage(REFRESH);
    removeFromStorage(REFRESH_AT);
    removeFromStorage(EXPIRED_AT);
    removeFromStorage(SYSTEM_SETTINGS);
    setUser({});
    if (mobileView) {
      navigate('/login?page=tmcs-mobile-view', { replace: true });
    } else {
      navigate('/login', { replace: true });
    }
  };

  const callRefresh = () => {
    const refreshTime = LSM.getTokenRefreshTime();
    if (refreshTime && new Date().getTime() >= refreshTime) {
      if (isLoggedIn()) {
        refreshToken()
          .then(({ data }) => {
            LSM.setAccessToken(data.access, data.refresh, data.expired_in_hours);
          })
          .catch((error) => logoutUser());
      }
    }
  };

  const values = {
    authUser: user,
    isLoggedIn,
    setAuthUser,
    logoutUser,
    logoutUnauthUser
  };

  return (
    <AuthContext.Provider value={useMemo(() => values, [values])}>{children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node
};
export const useAuthContext = () => useContext(AuthContext);
