import { Box, IconButton, Stack, useTheme } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { History } from '@mui/icons-material';
import AlertDetails from '../Tickets/AlertDetails';
import AlertBadge from './AlertBadge';
import SearchBox from './SearchBox';
import styles from './Alerts.module.scss';

const GlobalAlertsData = ({
  markAlertRead,
  alertInfo,
  globalFilterValue,
  handleGlobalAlertFilter,
  loading
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <>
      <Box sx={{ padding: '12px 6px 6px 6px' }}>
        <Stack direction="row" justifyContent="space-around" alignItems="center" spacing={1}>
          <div className={`has-search ${styles.search_box}`}>
            <SearchBox value={globalFilterValue} changeHandler={handleGlobalAlertFilter} />
          </div>
          <IconButton
            onClick={() => navigate('/dashboard/alert-listing')}
            sx={{ color: theme.palette.blue.main }}
          >
            <History />
          </IconButton>
        </Stack>
      </Box>
      <Box>
        {alertInfo.length
          ? alertInfo.map((alert, index) => (
              <AlertBadge markAlertRead={markAlertRead} alertInfo={alert} key={index} />
            ))
          : !loading && (
              <Box paddingY={1}>
                <AlertDetails classname="alert-danger" data="No Alert Found" />
              </Box>
            )}
      </Box>
    </>
  );
};

export default GlobalAlertsData;
