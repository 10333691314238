export const getLocalStorage = (key) => {
  if (localStorage.getItem(key) !== 'undefined') {
    const result = localStorage.getItem(key);
    return isJsonString(result) ? JSON.parse(localStorage.getItem(key)) : result;
  }

  return null;
};

export const getLocalStorageAtob = (key) =>
  localStorage.getItem(key) !== 'undefined'
    ? JSON.parse(atob(localStorage.getItem(key)) || '{}')
    : {};

export const setLocalStroage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeFromStorage = (key) => {
  localStorage.removeItem(key);
};

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
