const siteMessages = {
  hardwareConfig: 'Hardware not configured',
  userUpdate: 'User Updated SuccessFully',
  update: 'Updated SuccessFully',
  commentAdded: 'Comment Added Successfully',
  challanUpdate: 'Challan Updated SuccessFully',
  ManualDetection: 'Event Reported SuccessFully',
  levelChange: 'User Details Updated Successfully',
  callReassign: 'Call Reassign SuccessFully',
  permissionsDenied: "You don't have permission to access this",
  contactCreated: 'A new contact created successfully!',
  challanPushed: 'Challan pushed successfully to NIC Portal',
  challanVerify: 'Challan verifed successfully',
  challanReject: 'Challan Rejected successfully',
  validationError: 'Some validation error',
  uploadData: 'Data uploaded successfully',
  serverError: 'Some Error occurred',
  departmentEmpty: 'Please select a department',
  passwordChanged: 'Password changed successfully!',
  otpSend: 'OTP has been generated successfully!',
  vmsMessageSync: 'VMS Messages Saved Successfully!',
  ticketAssigned: 'Ticket Assigned Successfully!',
  ticketCreated: 'Ticket Created Successfully!',
  ticketClosed: 'Ticket Closed Successfully!',
  followCreated: 'Followup Created Successfully!',
  ticketFailure: 'Ticket creation failure!',
  followFailure: 'Followup creation failure!',
  noData: 'No Data Found!',
  escalateSuccess: 'Escalate Ticket Successfully!',
  escalateFailure: 'Ticket is already on top level!',
  userCreated: 'User Created Successfully!',
  userNotCreated: 'User Not Created!',
  remarkAdded: 'Remark updated successfully!',
  markedAsDuplicate: 'Alert Marked as Duplicated!',
  markedAsFalse: 'Alert Marked as False Alert!',
  templateAdded: 'Template Created Successfully!',
  templateUpdated: 'Template Updated Successfully!',
  groupNameUpdated: 'Group Name Updated Successfully!',
  vehicleAdded: 'Vehicle Added successfully!',
  vehicleNotAdded: 'Vehicle not added successfully!',
  vehicleUpdated: 'Vehicle Details updated successfully!',
  deviceUpdated: 'Device Updated Successfully!',
  vehicleDeleted: 'Vehicle Deleted Successfully!',
  vehicleAssign: 'Vehicle Assigned Successfully!',
  NoReport: 'No Report Found!',
  unknownLocation: 'Unknown Location',
  ChainageFound: '0 Chainage Configured',
  ChallanGenerate: 'Challan Generated Successfully!',
  NoNearbyVehicle: 'No Nearby Vehicle Found!',
  FakeCall: 'Call Marked as Fake Call Successfully!',
  EcbCallSaved: 'Details Saved Successfully!',
  VmsEmptyMessage: 'Group and message template are required field !',
  NoAdasRequest: 'No Request Found!',
  RemarkRequired: 'Remark can not be empty',
  AdasReportSubmit: 'Report Submitted Successfully!',
  NoDataAvailable: 'No Data Available',
  AddDataReport: 'No data found for selected filters',
  NoData: 'No Data Found',
  Loading : 'Loading...'
};

export const validationMessages = {
  incorrectOldPassword: 'Old password is incorrect',
  accountNotFound: 'This email address is not registered',
  passwordChanged: 'Password Changed Successfully!'
};

export default siteMessages;
