// ----------------------------------------------------------------------

import { DARK_THEME } from '../../constants';

export default function Datepicker(theme) {
  return {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          ...(theme.mode === DARK_THEME && {
            backgroundColor: 'transparent',
            '&.Mui-selected': {
              color: theme.CommonPalette.primary
            },
            '&.Mui-selected:hover': {
              color: theme.palette.common.white
            }
          })
        }
      }
    }
  };
}
