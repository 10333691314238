import React from 'react';
import ImageIcon from '@mui/icons-material/Image';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { Avatar, useTheme } from '@mui/material';

const ImageAvatar = ({ brokenicon = { sx: { fontSize: '150px' } }, zoomState, ...props }) => {
  const theme = useTheme();
  return (
    <>
      <Avatar {...props} className={`${!zoomState && 'cusor-pointer'}`} loading="lazy">
        <ImageIcon {...brokenicon} />
      </Avatar>
    </>
  );
};

export const VideoAvatar = (props) => (
  <Avatar {...props}>
    <OndemandVideoIcon color="error" />
  </Avatar>
);

export default ImageAvatar;
