import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ApiService from '../services/ApiService';
import { SystemSettings as ApiEndpoint } from '../services/ApiEndpoints';
import { SUCCESS, SYSTEM_SETTINGS } from '../constants';
import { setLocalStroage } from '../utils/LocalStorage';
import { useAuthContext } from './AuthContext';

const SystemSettingsContext = createContext(null);

export const useSystemSettings = () => useContext(SystemSettingsContext);

const SystemSettingsProvider = ({ children }) => {
  const [systemSettings, setSystemSettings] = useState({});
  const { apiService } = ApiService();
  const { isLoggedIn } = useAuthContext();
  const [requiredKeys, setRequiredKeys] = useState([
    'base_backend_url',
    'command_center_name',
    'map_default_init_coordinate',
    'media_server_base_url',
    'media_server_stream_base_url',
    'speed_display_windows_socket_url',
    'system_user_id',
    'vms_windows_sdk_socket_url',
    'web_agora_app_certificate',
    'web_agora_app_id',
    'web_google_map_api_key',
    'annotation_tool_access_key'
  ]);

  const loadSystemSettings = () => {
    apiService({ url: ApiEndpoint, method: 'post', data: { type: 'web' } }).then((result) => {
      if (result?.status === SUCCESS) {
        const _data = result.data;
        if (!_data.listing.vms_windows_sdk_socket_url) {
          console.log(`Warning: {vms_windows_sdk_socket_url} key is missing`);
        }
        setLocalStroage(SYSTEM_SETTINGS, _data.listing);
        requiredKeys.forEach((item) => {
          if (!Object.keys(_data.listing).includes(item)) {
            console.log(`${item} key is missing`);
          }
        });
        setSystemSettings(_data.listing);
      }
    });
  };

  useEffect(() => {
    if (isLoggedIn()) {
      loadSystemSettings();
    }
  }, [isLoggedIn()]);

  return (
    <SystemSettingsContext.Provider value={systemSettings}>
      {children}
    </SystemSettingsContext.Provider>
  );
};

SystemSettingsProvider.propTypes = {
  children: PropTypes.node
};
export default SystemSettingsProvider;
