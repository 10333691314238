import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Box } from '@mui/material';

const AlertDetails = (props) => {
  const { data } = props;
  return (
    <Box px={1}>
      <Alert severity="error">{data}</Alert>
    </Box>
  );
};

AlertDetails.propTypes = {
  data: PropTypes.string
};

export default AlertDetails;
