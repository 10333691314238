import { ALLOWED_CHAINAGE, ALL_CHAINAGE, ALL_PACKAGES } from '../constants';
import LSM from './lsm';

const checkLogin = () => {
  const access = LSM.getAccessToken();
  if (access === null || access === undefined || !access) {
    return false;
  }
  return true;
};

export const checkPackagePermission = (data, key, valueKey = 'package_ids') => {
  const allPackage = JSON.parse(localStorage.getItem(ALL_PACKAGES));
  let formData = data;
  if (!data[key]) {
    const { key: packageId, ...rest } = data;
    formData = { ...rest, [valueKey]: allPackage };
  }
  return formData;
};

export const checkChainagePermission = (data, key, valuekey = 'location_ids') => {
  const allPackage = JSON.parse(localStorage.getItem(ALL_CHAINAGE));
  const allowedChainage = localStorage.getItem(ALLOWED_CHAINAGE) === 'true';
  let formData = data;
  if (!allowedChainage) {
    if (!data[key]) {
      const { key: packageId, ...rest } = data;
      formData = { ...rest, [valuekey]: allPackage };
    }
  }
  return formData;
};

export default checkLogin;
