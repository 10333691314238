import { useContext, useEffect } from 'react';
import { Link, Box, Button, Typography, Container, Stack, Grid, useTheme } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import PropTypes from 'prop-types';
import { ForgotPassword } from '../../../services/ApiEndpoints';
import ApiService from '../../../services/ApiService';
import siteMessages from '../../../alert_messages';
import { InputText } from '../../form_inputs';
import useResetPassword from './useResetPassword';
import { ForgotPassContext } from '.';
import ForgotPassowrdForm from './ForgotPassowrdForm';
import handleException from '../../../utils/handleExceptions';

const ResetPassword = (props) => {
  const { apiService } = ApiService();
  const theme = useTheme();
  const { handleSubmit, bindInput, errors, setFormInputs, formInputs } = useResetPassword();
  const { setPage } = useContext(ForgotPassContext);

  useEffect(() => {
    setFormInputs({ ...formInputs, username: props?.username });
  }, []);

  const resendOtp = () => {
    apiService(
      { url: ForgotPassword, method: 'post', data: { username: props?.username } },
      { alertMessage: siteMessages.otpSend }
    ).catch((error) => handleException(error));
  };

  return (
    <>
      <Container maxWidth="sm">
        <Stack sx={{ mb: 5 }}>
          <Typography variant="h4" gutterBottom align="center">
            Reset Password
          </Typography>
          <Typography sx={{ color: 'text.secondary' }} align="center">
            Please enter the OTP and New Password.
          </Typography>
        </Stack>

        <Box sx={{ width: '100%' }}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <InputText
                error={errors}
                name="otp"
                label="OTP"
                bindInput={bindInput}
                type="number"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 6 }}
              />
            </Grid>
            <Grid item md={12}>
              <InputText
                error={errors}
                name="new_password"
                label="New Password"
                bindInput={bindInput}
                type="password"
              />
            </Grid>
            <Grid item md={12}>
              <InputText
                error={errors}
                name="confirm_password"
                label="Confirm Password"
                bindInput={bindInput}
                type="password"
              />
            </Grid>
            <Grid item md={12}>
              <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                <Button
                  variant="contained"
                  startIcon={<RefreshIcon />}
                  onClick={resendOtp}
                  sx={{ backgroundColor: theme.palette.blue.main }}
                >
                  Resend OTP
                </Button>
                <Button
                  type="button"
                  sx={{ minWidth: '20%', mt: 2, backgroundColor: theme.palette.blue.main }}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Change Password
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Typography align="center" mt={2}>
            <Link
              onClick={() => setPage({ element: ForgotPassowrdForm })}
              href="#"
              variant="subtitle2"
            >
              Back To Forgot Password
            </Link>
          </Typography>
        </Box>
      </Container>
    </>
  );
};

ResetPassword.propTypes = {
  username: PropTypes.string
};

export default ResetPassword;
