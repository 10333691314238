import * as React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, OutlinedInput } from '@mui/material';

const SearchBox = ({
  value,
  changeHandler,
  properties = {},
  sx = { fontSize: '12px', width: '100%' },
  icon,
  placeholder = 'Search'
}) => (
  <OutlinedInput
    {...properties}
    value={value}
    size="small"
    variant="outlined"
    onChange={(e) => changeHandler(e)}
    placeholder={placeholder}
    sx={sx}
    startAdornment={
      !icon ? (
        <InputAdornment position="start">
          <SearchIcon sx={{ fontSize: '15px' }} />
        </InputAdornment>
      ) : (
        icon
      )
    }
  />
);

SearchBox.propTypes = {
  value: PropTypes.string,
  changeHandler: PropTypes.func
};

export default SearchBox;
