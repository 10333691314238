// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
          '&:hover': {
            boxShadow: 'none'
          },
          '&.legendButton': {
            color: theme.palette.grey[50],
            textTransform: 'capitalize',
            fontWeight: 500,
            border: `1px solid ${theme.palette.grey[125]}`,
            borderRadius: '4px',
            padding: '4px 8px',
            minWidth: 'auto'
          },
          '&.legendButton.disabled': {
            background: theme.palette.other.disabled,
            color: '#9ea2a96b',
            '& .MuiButton-startIcon': {
              opacity: 0.6
            }
          }
        },
        sizeLarge: {
          height: 48
        },
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          backgroundColor: theme.palette.blue.main,
          '&:hover': {
            backgroundColor: theme.palette.blue.dark
          }
        },
        containedPrimary: {
          backgroundColor: theme.palette.blue.main,
          boxShadow: theme.customShadows.primary,
          '&:hover': {
            backgroundColor: theme.palette.blue.dark
          }
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        },
        outlinedPrimary: {
          border: `1px solid ${theme.palette.blue.main}`,
          color: theme.palette.blue.main
        },
        text: {
          color: theme.palette.blue.main
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: theme.palette.blue.main
        }
      }
    }
  };
}
