import Swal from 'sweetalert2';

const ConfirmationBox = (params) => {
  const { _titleText, _text, _icon, _confirmButton, colorCancel, colorConfirm, _cancelButtonText } =
    {
      colorConfirm: '#3085d6',
      colorCancel: '#d33',
      _icon: 'warning',
      _cancelButtonText: 'Cancel',
      ...params
    };
  return Swal.fire({
    title: _titleText,
    text: _text,
    icon: _icon,
    showCancelButton: true,
    cancelButtonText: _cancelButtonText,
    confirmButtonColor: colorConfirm,
    cancelButtonColor: colorCancel,
    confirmButtonText: _confirmButton
  });
};

export const Confirmed = (params) => {
  const { _titleText, _text, _type } = params;
  Swal.fire(_titleText, _text, _type);
};

export default ConfirmationBox;
