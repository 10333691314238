import axios from 'axios';
import { useAlert } from '../contexts/AlertContext';
import { useLoader } from '../contexts/LoaderProvider';
import {
  REFRESH,
  ACCESS,
  API_HOST,
  FAILURE,
  FAILURE_CODE,
  UNAUTHORIZED_STATUS_CODE,
  NOT_FOUND_STATUS_CODE
} from '../constants';
import { useAuthContext } from '../contexts/AuthContext';
import siteMessages from '../alert_messages';

const ApiService = (apiConfig = { contentType: 'application/json' }) => {
  const { showAlert } = useAlert();
  const setLoader = useLoader();
  const API_BASE_URL = `${String(API_HOST)}/api/v1`;
  const { logoutUnauthUser } = useAuthContext();

  let headers = {
    'Content-Type': apiConfig.contentType
  };

  const access = localStorage.getItem(ACCESS);
  if (access) {
    headers = { ...headers, Authorization: `Bearer ${access}` };
  }

  const axiosObj = axios.create({
    baseURL: API_BASE_URL,
    headers
  });

  axiosObj.interceptors.request.use((config) => config);

  const _showAlert = (type, content) => showAlert && showAlert({ type, content });

  const apiService = (config = { url: '', data: '', method: 'get' }, params) => {
    const settings = {
      alertMessage: false,
      errorMessage: true,
      loader: true,
      ...params
    };

    if (settings.loader === true) {
      setLoader(true);
    }

    return new Promise((resolve, reject) => {
      axiosObj(config)
        .then((result) => {
          const { data } = result;

          const responseMessage = data?.message;

          if (data?.status === FAILURE && settings.errorMessage) {
            if (responseMessage) _showAlert('error', responseMessage);
          }
          // Show a custome message or show a message received from server
          else if (settings.alertMessage) {
            _showAlert(
              'success',
              settings.alertMessage === true ? responseMessage : settings.alertMessage
            );
          }

          resolve(data);
        })
        .catch((err) => {
          let responseText = '';
          if (err?.response) {
            if (params?.setErrorMessage) {
              params?.setErrorMessage(err?.response);
            }

            const { data, status } = err.response;
            if (status === UNAUTHORIZED_STATUS_CODE) {
              logoutUnauthUser();
            }
            if ((status === FAILURE_CODE && settings.errorMessage) || params?.setErrors) {
              if (data?.message) {
                responseText = data.message;
                const key = data?.data;
                if (params?.setErrors) {
                  params.setErrors((prev) => ({ ...prev, [key]: responseText }));
                }
              }
            }
            if (status === NOT_FOUND_STATUS_CODE) {
              responseText = siteMessages.serverError;
            }
          } else {
            responseText = siteMessages.serverError;
          }

          if (responseText) _showAlert('error', responseText);
          reject();
        })
        .finally(() => {
          if (settings.loader) setLoader(false);
        });
    });
  };

  const refreshToken = () =>
    new Promise((resolve, reject) => {
      axiosObj({
        url: '/auth/token/refresh',
        data: { refresh: localStorage.getItem(REFRESH) },
        method: 'post'
      })
        .then((result) => {
          const { data } = result;
          if (data?.status === FAILURE) {
            reject(data);
          } else {
            resolve(data);
          }
        })
        .catch((err) => reject(siteMessages.serverError));
    });

  return { apiService, refreshToken };
};

export default ApiService;
