import { Icon } from '@iconify/react';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import TaxiAlertIcon from '@mui/icons-material/TaxiAlert';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import LogoutIcon from '@mui/icons-material/Logout';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { Link as RouterLink, useLocation, matchPath } from 'react-router-dom';
// material
import { alpha, styled } from '@mui/material/styles';
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
  useTheme,
  Grid,
  Paper,
  Stack,
  Fab,
  Badge,
  Tooltip,
  Link
} from '@mui/material';
import { NotificationsActive } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

// components
import MenuPopover from '../../components/MenuPopover';
import { usePageHeading } from '../../contexts/PageHeadingProvider';

//
import CreateAlert from '../../pages/alert/CreateAlert';
import { useAuthContext } from '../../contexts/AuthContext';
import ChangePassword from '../../pages/change_password';
import {
  API_HOST,
  BUILD_VERSION,
  DEMO_MODE_OFF,
  PROJECT_SUBTITLE,
  DEMO_MODE_DISPLAY,
  SUCCESS,
  SUPERUSER
} from '../../constants';
import DarkTheme from '../../components/DarkTheme';
import DemoMode from '../../components/DemoMode';
import { useDemoMode } from '../../contexts/DemoModeProvider';
import { useSystemSettings } from '../../contexts/SystemSettingsProvider';
import { ECBPendingCallsByUser } from '../../services/ApiEndpoints';
import ApiService from '../../services/ApiService';
import { MessagePopup } from '../../components/MessagePopup';
import lsm from '../../utils/lsm';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: homeFill,
    linkTo: '/'
  },
  {
    label: 'Profile',
    icon: personFill,
    linkTo: '/dashboard/profile'
  },
  {
    label: 'Change Password',
    icon: lockFill,
    linkTo: '#',
    clickEvent: (ref) => {
      if (ref !== 'undefined') {
        ref.current.openPopUp(true);
      }
    }
  }
];

const AccountPopover = ({
  alertPanelOpen,
  alertsInfo,
  slideOpen,
  setSidebarOpen,
  sidebarOpen,
  setEventType,
  openEventDrawer,
  adasInfo,
  deviceAlerts,
  slideAdasOpen,
  mobileView
}) => {
  const { apiService } = ApiService();
  const eventPermission = lsm.checkPermissions('tms');
  const adasRequest = lsm.checkPermissions('readonly_adas_request');
  const { demoMode, setDemoMode } = useDemoMode();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { palette, CommonPalette } = useTheme();
  const { authUser, logoutUser } = useAuthContext();
  const [checked, setChecked] = useState();
  const { pageHeading } = usePageHeading();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const changePasswordRef = useRef();
  const [userManualUrl, setUserManualUrl] = useState('');
  const systemSettings = useSystemSettings();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClearCache = () => {
    localStorage.clear();
    window.location.reload();
  };

  const handleLogout = () => {
    apiService({
      url: ECBPendingCallsByUser
    }).then((result) => {
      if (result?.status === SUCCESS) {
        if (result?.data?.length > 0) {
          let message =
            'You are not allowed to logout, because you have calls in pending state with ID: ';
          result?.data?.map((item) => {
            message = `${message} #${item}`;
            return null;
          });

          MessagePopup('error', message);
        } else {
          logoutUser();
        }
      }
    });
  };

  const handleFullScreen = () => {
    if (!isFullscreen) {
      document.body.requestFullscreen();
    } else document.exitFullscreen();
    setIsFullscreen(!isFullscreen);
  };

  const Item = styled(Paper)(({ theme }) => ({
    background: 'transparent',
    paddingTop: theme.spacing(1),
    textAlign: 'left'
  }));

  const location = useLocation();
  useEffect(() => {
    if (systemSettings?.atms_user_manual) {
      setUserManualUrl(systemSettings?.atms_user_manual);
    }
  }, [systemSettings]);

  return (
    <>
      <ChangePassword ref={changePasswordRef} />
      <Stack justifyContent="space-between" direction="row" alignItems="center" width="100%">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => setSidebarOpen(true)}
          edge="start"
          sx={{ ...(sidebarOpen && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton>
        <Grid container ml={0}>
          <Grid item xs={6}>
            {pageHeading && (
              <Item>
                <Typography noWrap className="page-heading" variant="h4">
                  {pageHeading}
                </Typography>
                {matchPath('dashboard', location.pathname) && (
                  <Typography
                    noWrap
                    variant="navItem"
                    className="project-subtitle"
                    sx={{
                      background: palette.blue.ligher,
                      border: '1px solid palette.blue.ligher'
                    }}
                  >
                    {PROJECT_SUBTITLE}
                  </Typography>
                )}
              </Item>
            )}
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', paddingX: '15px', justifyContent: 'end' }}>
            <Stack direction="row" spacing={1} alignItems="center">
              {/* <DarkTheme /> */}
              {!mobileView && (
                <>
                  {JSON.parse(localStorage.getItem(SUPERUSER)) && <CreateAlert />}
                  {eventPermission && (
                    <>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          setChecked(!checked);
                          setEventType('create');
                          openEventDrawer.current.openDrawer();
                        }}
                      >
                        <Typography noWrap sx={{ fontWeight: 600, fontSize: '13px' }}>
                          Create event
                        </Typography>
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        sx={{
                          background: palette.error.main,
                          '&:hover': {
                            background: palette.error.main
                          }
                        }}
                        onClick={() => {
                          setChecked(!checked);
                          setEventType('report');
                          openEventDrawer.current.openDrawer();
                        }}
                      >
                        <Typography noWrap sx={{ fontWeight: 600, fontSize: '13px' }}>
                          Report accident
                        </Typography>
                      </Button>
                    </>
                  )}
                </>
              )}
              <Box>
                {!alertPanelOpen && (
                  <Box sx={{ textAlign: 'right' }}>
                    <IconButton
                      ref={anchorRef}
                      onClick={handleOpen}
                      sx={{
                        padding: 0,
                        width: 44,
                        height: 44,
                        ...(open && {
                          '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(palette.grey[900], 0.72)
                          }
                        })
                      }}
                    >
                      <Avatar src={authUser.profileImage} alt="photoURL" />
                    </IconButton>
                    <MenuPopover
                      open={open}
                      onClose={handleClose}
                      anchorEl={anchorRef.current}
                      sx={{
                        width: 220,
                        background: palette.background.main,
                        '& .arrow': { backgroundColor: palette.background.main }
                      }}
                    >
                      <Stack direction="row">
                        <Box sx={{ my: 0.5, px: 2.5 }}>
                          <Typography variant="subtitle1" noWrap>
                            {authUser.username}
                          </Typography>
                          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                            {authUser.email}
                          </Typography>
                        </Box>
                      </Stack>
                      <Stack direction="row" sx={{ mb: 1.5, px: 2.5 }}>
                        <Typography
                          variant="body2"
                          sx={{ color: 'text.secondary', fontSize: '12px !important' }}
                          noWrap
                        >
                          Build Version: {BUILD_VERSION}
                        </Typography>
                      </Stack>

                      <Divider sx={{ my: 1 }} />
                      {!mobileView && (
                        <>
                          {/* {DEMO_MODE_DISPLAY && (
                            <MenuItem>
                              <DemoMode />
                            </MenuItem>
                          )} */}
                          <MenuItem>
                            <DarkTheme />
                          </MenuItem>

                          <Divider sx={{ my: 1 }} />

                          {MENU_OPTIONS.map((option) => (
                            <MenuItem
                              key={option.label}
                              to={option.linkTo}
                              component={RouterLink}
                              onClick={
                                option?.clickEvent
                                  ? () => {
                                      handleClose();
                                      option?.clickEvent(changePasswordRef);
                                    }
                                  : handleClose
                              }
                              sx={{ typography: 'body2', py: 1, px: 2.5 }}
                            >
                              <Box
                                component={Icon}
                                icon={option.icon}
                                sx={{
                                  mr: 2,
                                  width: 24,
                                  height: 24
                                }}
                              />
                              {option.label}
                            </MenuItem>
                          ))}
                          {userManualUrl && (
                            <MenuItem
                              href={API_HOST + userManualUrl}
                              component={Link}
                              target="_blank"
                              rel="noopener"
                              sx={{ typography: 'body2', py: 1, px: 2.5 }}
                            >
                              <Box
                                component={Icon}
                                icon={fileTextFill}
                                sx={{
                                  mr: 2,
                                  width: 24,
                                  height: 24
                                }}
                              />
                              User Manual
                            </MenuItem>
                          )}
                        </>
                      )}
                      <Box sx={{ p: 2, pt: 1.5 }}>
                        <Button
                          fullWidth
                          startIcon={<DeleteOutlineIcon fontSize="small" />}
                          color="error"
                          variant="outlined"
                          onClick={() => handleClearCache()}
                        >
                          Clear Cache
                        </Button>
                        <Button
                          sx={{ mt: 1, color: '#000' }}
                          fullWidth
                          color="inherit"
                          startIcon={<LogoutIcon fontSize="small" />}
                          variant="contained"
                          onClick={() => handleLogout()}
                        >
                          Logout
                        </Button>
                      </Box>
                    </MenuPopover>
                  </Box>
                )}
              </Box>
              {!mobileView && (
                <>
                  <Box>
                    <Tooltip title="Alerts">
                      <IconButton
                        className={`${alertsInfo.newAlerts > 0 && 'blink-red'}`}
                        sx={{
                          height: '40px',
                          width: '40px',
                          background: CommonPalette.lightpink,
                          display: slideOpen ? 'none' : 'show',
                          ':hover': { background: CommonPalette.lightpink }
                        }}
                        {...(alertsInfo.onClick && { onClick: alertsInfo.onClick })}
                        size="small"
                      >
                        <Badge badgeContent={`${alertsInfo.totalAlert}`} color="error">
                          <NotificationsActive sx={{ color: CommonPalette.black }} />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </Box>

                  <Box>
                    <Tooltip title="Device Down Alerts">
                      <IconButton
                        className={`${deviceAlerts.newAlerts > 0 && 'blink-red'}`}
                        sx={{
                          height: '40px',
                          width: '40px',
                          background: CommonPalette.lightpink,
                          display: slideOpen ? 'none' : 'show',
                          ':hover': { background: CommonPalette.lightpink }
                        }}
                        {...(deviceAlerts.onClick && { onClick: deviceAlerts.onClick })}
                        size="small"
                      >
                        <Badge badgeContent={`${deviceAlerts.totalAlert}`} color="error">
                          <LinkOffIcon sx={{ color: CommonPalette.black }} />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </Box>
                  {adasRequest && (
                    <Box>
                      <Tooltip title="Adas Requests">
                        <IconButton
                          className={`${adasInfo.newAdas > 0 && 'blink-red'}`}
                          sx={{
                            height: '40px',
                            width: '40px',
                            background: CommonPalette.lightpink,
                            display: slideAdasOpen ? 'none' : 'show',
                            ':hover': { background: CommonPalette.lightpink }
                          }}
                          {...(adasInfo.onClick && { onClick: adasInfo.onClick })}
                          size="small"
                        >
                          <Badge badgeContent={`${adasInfo.totalAdas}`} color="error">
                            <TaxiAlertIcon sx={{ color: CommonPalette.black }} />
                          </Badge>
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}

                  <Box>
                    <Tooltip title="Full Screen">
                      <IconButton
                        sx={{
                          height: '40px',
                          width: '40px',
                          background: CommonPalette.lightpink,
                          display: slideAdasOpen ? 'none' : 'show',
                          ':hover': { background: CommonPalette.lightpink }
                        }}
                        onClick={() => {
                          handleFullScreen();
                        }}
                        size="small"
                      >
                        {isFullscreen ? (
                          <FullscreenExitIcon sx={{ color: CommonPalette.black }} />
                        ) : (
                          <FullscreenIcon sx={{ color: CommonPalette.black }} />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Box>
                </>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

AccountPopover.propTypes = {
  alertPanelOpen: PropTypes.bool
};
export default AccountPopover;
