import { useContext, useEffect } from 'react';
import useForm from '../../../_hooks/useForm';
import ApiService from '../../../services/ApiService';
import { ResetPassword } from '../../../services/ApiEndpoints';
import { ResetPasswordForm, ResetPasswordFormValidation } from './FormModel';
import siteMessages from '../../../alert_messages';
import { SUCCESS } from '../../../constants';
import FormValidation from '../../../utils/FormValidation';
import { ForgotPassContext } from '.';
import handleException from '../../../utils/handleExceptions';

const useResetPassword = () => {
  const { apiService } = ApiService();
  const { toggleDrawer } = useContext(ForgotPassContext);
  const { formInputs, bindInput, setFormInputs } = useForm(ResetPasswordForm);
  const { validate, errors, setErrors } = FormValidation({
    validationRules: { ...ResetPasswordFormValidation },
    formInput: formInputs
  });

  const handleSubmit = () => {
    validate()
      .then(() => {
        apiService({ url: ResetPassword, method: 'post', data: formInputs }, { alertMessage: true })
          .then((result) => {
            if (result?.status === SUCCESS) {
              toggleDrawer();
            }
          })
          .catch((error) => handleException(error));
      })
      .catch(() => handleException(siteMessages.validationError));
  };

  useEffect(() => {
    if (errors) setErrors({});
  }, [formInputs]);

  return {
    handleSubmit,
    bindInput,
    errors,
    setFormInputs,
    formInputs
  };
};

export default useResetPassword;
