// routes
import Router from './routing/routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import ContextsProvider from './contexts';
import DarkThemeProvider from './contexts/DarkThemeContext';
import { AuthProvider } from './contexts/AuthContext';
// ------------------------./contexts/AlertContext------------------------

export default function App() {
  return (
    <AuthProvider>
      <DarkThemeProvider>
        <ThemeConfig>
          <ContextsProvider>
            <ScrollToTop />
            <GlobalStyles />
            <BaseOptionChartStyle />
            <Router />
          </ContextsProvider>
        </ThemeConfig>
      </DarkThemeProvider>
    </AuthProvider>
  );
}
