import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  OutlinedInput,
  useTheme,
  Checkbox
} from '@mui/material';
import PropTypes from 'prop-types';
import StyledFormControl from '../StyledFormControl';

export const InputText = (props) => {
  const {
    label,
    name,
    bindInput,
    error,
    type,
    rows,
    disabled = false,
    multiline,
    inputAttr,
    helperText,
    inputProps,
    variant,
    styleprops,
    onKeyDown
  } = props;
  const errorDesc = error !== null && name in error && error[name];

  return (
    // <FormControl variant={variant} sx={{ minWidth: '90%', ...styleprops?.sx }} size="small">
    <TextField
      error={error !== null && name in error}
      sx={{ minWidth: '100%', ...styleprops?.sx }}
      size="small"
      disabled={disabled}
      helperText={
        <>
          {errorDesc}
          {helperText}
        </>
      }
      {...bindInput(name)}
      label={label}
      name={name}
      rows={rows}
      multiline={multiline}
      type={type}
      variant={variant}
      {...inputAttr}
      inputProps={{ ...inputProps }}
      onKeyDown={onKeyDown}
    />
    // </FormControl>
  );
};

export const SelectBox = (props) => {
  const {
    label,
    name,
    bindInput,
    error,
    variant,
    options,
    emptyOption,
    formControlProps,
    inputProps
  } = props;
  const errorDesc = error !== null && name in error && error[name];

  return (
    <FormControl
      variant={variant}
      sx={{ minWidth: '100%', ...formControlProps?.sx }}
      error={error !== null && name in error}
      size="small"
    >
      <InputLabel id={label}>{label}</InputLabel>
      <Select
        {...bindInput(name)}
        name={name}
        label={label}
        inputProps={{ ...inputProps }}
        variant={variant}
      >
        {emptyOption && <MenuItem value="">All</MenuItem>}
        {options.length > 0 &&
          options.map((item) => (
            <MenuItem key={item.id} value={item.id} sx={{ textTransform: 'capitalize' }}>
              {item.name}
            </MenuItem>
          ))}
      </Select>
      {error !== null && name in error && <FormHelperText>{errorDesc}</FormHelperText>}
    </FormControl>
  );
};

export const RadioBox = ({ options, label, name, value, onChange }) => (
  <StyledFormControl>
    <FormLabel id={label}>{label}</FormLabel>
    <RadioGroup aria-labelledby="label" name={name} value={value} onChange={onChange}>
      <Stack direction="row" spacing={2}>
        {options.length &&
          options.map((item, index) => (
            <FormControlLabel
              key={index}
              value={Boolean(item.id)}
              control={<Radio />}
              label={item.name}
            />
          ))}
      </Stack>
    </RadioGroup>
  </StyledFormControl>
);

function getStyles(name, options, theme) {
  return {
    fontWeight:
      options.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  };
}
export const MultiSelectBox = ({
  formControlProps,
  label,
  value,
  name,
  onChange,
  options,
  inputProps
}) => {
  const theme = useTheme();
  return (
    <FormControl sx={{ minWidth: '100%', ...formControlProps?.sx }} size="small">
      <InputLabel id={label}>{label}</InputLabel>
      <Select
        multiple
        name={name}
        value={value}
        onChange={onChange}
        inputProps={{ ...inputProps }}
        // renderValue={(selected) => selected.map((x) => x.name).join(', ')}
        input={<OutlinedInput label={label} />}
      >
        {options.length &&
          options.map((item, index) => (
            <MenuItem key={index} value={item.id} style={getStyles(item, options, theme)}>
              <Checkbox checked={value?.indexOf(item.id) > -1} />
              {item.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

SelectBox.defaultProps = {
  formControlProps: {},
  variant: 'outlined',
  options: [],
  emptyOption: false,
  error: null
};

RadioBox.defaultProps = {
  options: []
};

MultiSelectBox.defaultProps = {
  options: []
};

InputText.defaultProps = {
  variant: 'outlined',
  type: 'text',
  inputAttr: {}
};

InputText.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  bindInput: PropTypes.func,
  error: PropTypes.object,
  type: PropTypes.string,
  inputAttr: PropTypes.object,
  helperText: PropTypes.object,
  inputProps: PropTypes.string,
  variant: PropTypes.string,
  styleprops: PropTypes.object,
  onKeyDown: PropTypes.func
};

SelectBox.propTypes = {
  formControlProps: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  inputProps: PropTypes.object,
  bindInput: PropTypes.func,
  error: PropTypes.object,
  options: PropTypes.array,
  emptyOption: PropTypes.any,
  variant: PropTypes.string
};
