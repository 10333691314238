import React from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { CardMedia, DialogTitle, IconButton, Dialog, DialogContent, Box, Fab } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import ImageAvatar from './ImageAvatar';

export const ImageViewer = ({ src, style, variant = 'square', brokenIcon, zoomState }) => (
  <Box
    sx={{
      ' & img': {
        objectFit: 'fill !important'
      },
      height: src ? '100%' : '',
      position: 'relative'
    }}
  >
    <TransformWrapper>
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <>
          <Box className="zoom-tools">
            <Fab onClick={() => resetTransform()} size="medium" color="secondary">
              <RefreshIcon />
            </Fab>
          </Box>

          <TransformComponent>
            <ImageAvatar
              src={src}
              sx={{ ...style }}
              variant={variant}
              brokenicon={{ ...brokenIcon }}
              zoomState={zoomState}
            />
          </TransformComponent>
        </>
      )}
    </TransformWrapper>
  </Box>
);

const { forwardRef, useImperativeHandle } = React;
const MediaViewer = forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [src, setSrc] = React.useState('');
  const [mediaType, setMediaType] = React.useState('image');
  const [uploadUrl, setUploadUrl] = React.useState(false);
  const [zoom, setZoom] = React.useState(false);
  const [variant, setVariant] = React.useState();
  const [style, setStyle] = React.useState({});
  const [brokenIcon, setBrokenIcon] = React.useState();

  useImperativeHandle(ref, () => ({
    openImageViewer: (src, type, variant, sx, brokenIcon, upload = false, zoom = false) => {
      setOpen(true);
      setSrc(src);
      setMediaType(type);
      setUploadUrl(upload);
      setVariant(variant);
      setStyle(sx);
      setBrokenIcon(brokenIcon);
      setZoom(zoom);
    }
  }));

  const closeImageViewer = () => {
    setOpen(false);
    setSrc('');
    setMediaType('');
    setUploadUrl(false);
  };
  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={closeImageViewer}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '& MuiPaper-root': {
          width: '70% ',
          maxWidth: '100%'
        }
      }}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeImageViewer}
          sx={{
            position: 'absolute',
            right: 0,
            top: -4,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <i className="fa fa-times-circle" aria-hidden="true" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ position: 'relative' }}>
        {mediaType === 'image' ? (
          <ImageViewer
            src={src}
            style={style}
            variant={variant}
            brokenIcon={brokenIcon}
            zoomState
          />
        ) : (
          <CardMedia
            autoPlay
            sx={{ borderRadius: '12px' }}
            component="video"
            image={src}
            controls
          />
        )}
        {/* <img
          src={uploadUrl ? src : `${src}?w=164&h=164&fit=crop&auto=format`}
          srcSet={uploadUrl ? src : `${src}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
          alt=""
          loading="lazy"
          style={{ width: '100%', height: '80vh' }}
        /> */}
      </DialogContent>
    </Dialog>
  );
});

export default MediaViewer;
