import { StaticColors } from '../components/charts/StaticColors';

export const validateImageSize = (image, sizeAllowed) => {
  if (!image) return `Invalid Image`;
  const imageInKb = Math.round(image.size / 1024);
  const messageSize =
    sizeAllowed > 1000 ? `${Math.round(sizeAllowed / 1024)}MB` : `${sizeAllowed}KB`;
  return imageInKb <= sizeAllowed
    ? true
    : `The file is two large. Allowed maximum size is ${messageSize}`;
};

export const arrayToChunks = (array, chunkSize = 10) => {
  const chunk = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunk.push(array.slice(i, i + chunkSize));
  }
  return chunk;
};

export const dateComparison = (currentDate, followUpDate) => {
  let data = '';
  if (
    currentDate.getMonth() === followUpDate.getMonth() &&
    currentDate.getYear() === followUpDate.getYear()
  ) {
    if (currentDate.getDay() === followUpDate.getDay()) {
      data = 'today';
    } else if (currentDate.getDay() - 1 === followUpDate.getDay()) {
      data = 'yestarday';
    } else if (currentDate.getDay() === followUpDate.getDay() - 1) {
      data = 'tomorrow';
    }
  }
  return data;
};

const special = ['zeroth', 'st', 'nd', 'rd'];
// const deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

export const stringifyNumber = (n) => {
  if (n < 4) return `${n}${special[n]}`;
  if (n > 3 && n < 21) return `${n}th`;
  return null;
};

export const graphSwatches = (data, excludeKeys = []) => {
  if (data?.length > 0) {
    const [first, ...rest] = data;
    const swatches = [];
    Object.keys(first).forEach((value, i) => {
      if (!excludeKeys.includes(value))
        swatches.push({ name: slugToString(value), key: value, color: StaticColors[i] });
    });

    return swatches;
  }

  return [];
};

export const slugToString = (string) => {
  const newStr = string.replaceAll('_', ' ').toLowerCase();
  return capitalizeFirstLetter(newStr);
};

export const nameToString = (string) => {
  const newStr = string.replace('.', ' ').toLowerCase();
  return capitalizeFirstLetter(newStr);
};

export const capitalizeFirstLetter = (string) =>
  string && string.toLowerCase().charAt(0).toUpperCase() + string.slice(1);

export const generateRandomColor = () => {
  const color = Math.random().toString(16).substr(-6);
  return `#${color}`;
};

export const openGoogleMaps = (lat, lng) => {
  /*  if (navigator.geolocation) {
    // Get the user's current location
    navigator.geolocation.getCurrentPosition((position) => {
      const currentLat = position.coords.latitude;
      const currentLng = position.coords.longitude;

      // Build the Google Maps URL with the starting point (user's current location) and destination (provided lat/lng)
      const mapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${currentLat},${currentLng}&destination=${lat},${lng}`;

      // Open Google Maps in a new tab/window
      window.open(mapsUrl, '_blank');
    });
  } else {
    console.log('Geolocation is not supported by this browser.');
  } */
  window.open(`https://maps.google.com/?q=${lat},${lng}`, '_blank');
};

export const getCurrentLocationCoordinates = (setLatitude, setLongitude) => {
  if (navigator.geolocation) {
    // Get the user's current location
    navigator.geolocation.getCurrentPosition((position) => {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    });
  }
};
