import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';
import { BLACK_MAIN_LOGO, WHITE_MAIN_LOGO } from '../constants';


// ----------------------------------------------------------------------

Aurigalogo.propTypes = {
  sx: PropTypes.object
};

export default function Aurigalogo({themeStatus, sx }) {
  console.log(process.env.WHITE_MAIN_LOGO_PATH, process.env.BLACK_MAIN_LOGO_PATH)
  const logoSrc = themeStatus
    ? WHITE_MAIN_LOGO
    : BLACK_MAIN_LOGO;
  return (
    <Box
      component="img"
      src={logoSrc}
      sx={{ width: 100, height: 62, marginLeft: 0.5, ...sx }}
    />
  );
}
