export const getPriorityColor = (priority) => {
  const priorityChoices = {
    1: 'badge bg-success',
    2: 'badge bg-warning',
    3: 'badge bg-danger',
    4: 'badge bg-danger'
  };
  return priorityChoices[priority];
};

export const getPriority = (priority) => {
  const priorityChoices = {
    1: '#019F79',
    2: '#F09205',
    3: '#FA5E5E',
    4: '#FA5E5E'
  };
  return priorityChoices[priority];
};
