import { Add, Videocam, CallEnd, MicOff } from '@mui/icons-material';
import { Stack, Typography, IconButton, useTheme, styled } from '@mui/material';
import { useState } from 'react';

const ActionBar = (props) => {
  const { CommonPalette, palette } = useTheme();
  const active = {
    background: CommonPalette.white,
    '& .MuiSvgIcon-root': {
      color: CommonPalette.black
    },
    '&:hover': {
      background: CommonPalette.white
    }
  };

  const inactive = {
    background: CommonPalette.videoCall.buttonsColor,
    '& .MuiSvgIcon-root': {
      color: CommonPalette.white
    },
    '&:hover': {
      background: CommonPalette.videoCall.buttonsColor
    }
  };

  const EndCallButtonStyle = {
    background: palette.error.main,
    padding: '15px',
    borderRadius: '10px',
    '&:hover': {
      background: palette.error.main
    },
    '& .MuiSvgIcon-root': {
      color: CommonPalette.white
    }
  };

  const client = props.useClient();
  const { tracks, setStart, setInCall } = props;
  const [trackState, setTrackState] = useState({ video: true, audio: true });

  const mute = async (type) => {
    if (type === 'audio') {
      await tracks[0].setEnabled(!trackState.audio);
      setTrackState((ps) => ({ ...ps, audio: !ps.audio }));
    } else if (type === 'video') {
      await tracks[1].setEnabled(!trackState.video);
      setTrackState((ps) => ({ ...ps, video: !ps.video }));
    }
  };

  const leaveChannel = async () => {
    await client.leave();
    client.removeAllListeners();
    await tracks[0].setEnabled(false);
    await tracks[1].setEnabled(false);
    tracks[0].close();
    tracks[1].close();
    setStart(false);
    setInCall(false);
  };

  return (
    <Stack direction="row" justifyContent="center" alignItems="flex-start" spacing={5} mt={3}>
      {/* <ActionButton title="Add Call" sx={{ ...inactive }} icon={<Add fontSize="medium" />} /> */}
      <ActionButton
        title="Video"
        onClick={() => mute('video')}
        sx={{ ...(!trackState.video ? active : inactive) }}
        icon={<Videocam fontSize="medium" />}
      />
      <ActionButton
        sx={EndCallButtonStyle}
        icon={<CallEnd fontSize="large" />}
        onClick={leaveChannel}
      />
      <ActionButton
        title="Mute"
        icon={<MicOff fontSize="medium" />}
        onClick={() => mute('audio')}
        sx={{ ...(!trackState.audio ? active : inactive) }}
      />
    </Stack>
  );
};

const ActionButton = ({ title = '', icon, ...props }) => {
  const { CommonPalette, palette } = useTheme();

  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    padding: '15px'
  }));

  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
      <StyledIconButton {...props}>{icon}</StyledIconButton>
      {title && (
        <Typography sx={{ color: CommonPalette.videoCall.typography }} variant="boxTitle">
          {title}
        </Typography>
      )}
    </Stack>
  );
};

export default ActionBar;
