import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import React, { useRef, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../assets/svg/Navbar/logo.svg';
import {
  ACCESS,
  API_HOST,
  DIRECTION,
  MESSAGE_IMAGE_SIZE_ALLOWED,
  PRIORITY_CLASSES,
  SUCCESS
} from '../../../constants';
import GroupMessagesAvatar from '../../VirtualMessagingSystem/vms_components/GroupMessagesAvatar';
import FormValidation, { required } from '../../../utils/FormValidation';
import useForm from '../../../_hooks/useForm';
import { validateImageSize } from '../../../utils/AppFunction';
import ApiService from '../../../services/ApiService';
import {
  AdasImageDelete,
  AdasImageUpload,
  AdasIncidentListing,
  AdasReportCreate,
  AdasReportData,
  AdasVerifyToken,
  TicketDetails
} from '../../../services/ApiEndpoints';
import { useAlert } from '../../../contexts/AlertContext';
import siteMessages from '../../../alert_messages';
import handleException from '../../../utils/handleExceptions';
import NotFound from '../../Page404';
import { fToNow } from '../../../utils/formatTime';
import { MessagePopup } from '../../../components/MessagePopup';
import MediaViewer from '../../../components/MediaViewer';

const AdasReport = () => {
  const params = useParams();
  const { eventId, vehicleId, token } = params;
  const { apiService } = ApiService();
  const { showAlert } = useAlert();
  const imageViewer = useRef();
  const theme = useTheme();
  const [verified, setVerified] = useState(false);
  const [adasReport, setAdasReport] = useState({});
  const [loader, setLoader] = useState(false);
  const [details, setDetails] = useState({});
  const [uploadUrl, setUploadUrl] = useState('');
  const { formInputs, bindInput, setFormInputs } = useForm({ comment: '', attachment: [] });
  const { validate, errors, setErrors } = FormValidation({
    validationRules: {
      comment: [required]
    },
    formInput: formInputs
  });

  const verifyToken = () => {
    // verify token token
    setLoader(true);
    apiService({
      url: AdasVerifyToken,
      method: 'post',
      data: { token }
    })
      .then((result) => {
        localStorage.setItem(ACCESS, token);
        if (result === {}) return null;
        setVerified(true);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleImageUpload = (e) => {
    const { name, files } = e.target;
    if (name === 'attachment') {
      const isValid = validateImageSize(files[0], MESSAGE_IMAGE_SIZE_ALLOWED);
      if (isValid !== true) {
        showAlert({ type: 'error', content: isValid });
        return false;
      }
    }
    setUploadUrl(files[0]);
  };

  const imageUpload = () => {
    const formData = new FormData();
    formData.append('encrypt_ticket_id', eventId);
    formData.append('user_token', token);
    formData.append(`files`, uploadUrl);

    apiService({
      url: AdasImageUpload,
      method: 'post',
      data: formData
    }).then((result) => {
      if (result?.status === SUCCESS) {
        const _data = result.data;
        const updateMsg = [...formInputs.attachment, _data];
        setFormInputs({ ...formInputs, attachment: updateMsg });
      }
    });
  };

  const imageDelete = (id) => {
    apiService(
      {
        url: AdasImageDelete,
        method: 'post',
        data: { adas_attachment_id: id }
      },
      { alertMessage: true }
    ).then((result) => {
      const data = formInputs?.attachment?.filter((element, index) => element.id !== id);
      setFormInputs({ ...formInputs, attachment: data });
    });
  };

  const handleSubmit = () => {
    const formData = {
      encrypt_ticket_id: eventId,
      user_token: token,
      vehicle_id: vehicleId,
      comment: formInputs.comment
    };

    validate()
      .then(() => {
        apiService({
          url: AdasReportCreate,
          method: 'post',
          data: { ...formData }
        }).then((result) => {
          if (result?.status === SUCCESS) {
            const _data = result.data;
            MessagePopup('success', siteMessages.AdasReportSubmit);
          }
        });
      })
      .catch(() => {
        handleException(siteMessages.validationError);
      });
  };

  const accidentDetail = () => {
    apiService({
      url: TicketDetails,
      method: 'post',
      data: { ticket_id: eventId }
    }).then((result) => {
      if (result?.status === SUCCESS) {
        const { details, report_id: reportId } = result.data;
        setDetails(details);
      }
    });
  };

  const adasReportData = () => {
    apiService({
      url: AdasReportData,
      method: 'post',
      data: { encrypt_ticket_id: eventId, vehicle_id: vehicleId }
    }).then((result) => {
      if (result?.status === SUCCESS) {
        const _data = result.data;
        setAdasReport(_data);
      }
    });
  };

  useEffect(() => {
    if (uploadUrl) imageUpload();
  }, [uploadUrl]);

  useEffect(() => {
    if (Object.keys(adasReport)?.length) {
      setFormInputs({
        ...formInputs,
        comment: adasReport.report.comment,
        attachment: adasReport.images
      });
    }
  }, [adasReport]);

  useEffect(() => {
    verifyToken();
  }, []);

  useEffect(() => {
    if (verified) adasReportData();
  }, [verified]);
  useEffect(() => {
    if (errors) setErrors({});
  }, [formInputs]);
  return (
    <>
      {verified ? (
        <Box sx={{ margin: '30px' }}>
          <Stack direction="row" spacing={2} sx={{ marginBottom: '20px' }}>
            <Logo />
            <Typography variant="h3">Incident Report</Typography>
          </Stack>
          <Box>
            <Card
              sx={{
                backgroundColor: theme.palette.background.main,
                border: `1px solid ${theme.palette.background.main}`
              }}
            >
              <CardContent>
                <Box>
                  <Box>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={3}
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography
                          variant="span"
                          sx={{ color: theme.palette.primary.light }}
                          className="location-no"
                        >
                          {details?.id && `#${details.id}`}
                        </Typography>
                        {details?.priority_display && (
                          <Typography
                            component="span"
                            variant="body2"
                            className={`common-status-badge ${PRIORITY_CLASSES[3]}`}
                            sx={{
                              color: theme.palette.primary.contrastText
                            }}
                          >
                            {details.priority_display === '0' ? 'Low' : details.priority_display}
                          </Typography>
                        )}
                        <Typography component="p" sx={{ color: theme.palette.grey[25] }}>
                          {details?.created_at && `Active since ${fToNow(details.created_at)}`}
                        </Typography>
                      </Stack>
                      {details?.closed_by && (
                        <Box
                          sx={{
                            background: theme.palette.blue.skyLight,
                            display: 'inline-block',
                            borderRadius: 1,
                            p: '10px'
                          }}
                        >
                          <Typography sx={{ color: theme.palette.blue.skyDark }}>
                            Closure Reported by {details?.closed_by_name} at {details?.closed_at}
                          </Typography>
                        </Box>
                      )}
                    </Stack>
                    <Typography component="p" variant="subtitle7" mb={0.5}>
                      {details?.type && `${details.event_type_name} - ${details.type}`}
                    </Typography>
                    <Typography
                      component="p"
                      variant="subtitle1"
                      fontWeight={500}
                      mb={0.5}
                      sx={{ color: theme.palette.grey[25] }}
                    >
                      {details?.incident_direction &&
                        `Direction - ${DIRECTION[details?.incident_direction]}`}
                    </Typography>
                  </Box>
                  <Box sx={{ marginTop: 3 }}>
                    <TextField
                      InputLabelProps={{
                        shrink: true
                      }}
                      label="Comment"
                      placeholder="Write here"
                      rows={3}
                      multiline
                      fullWidth
                      name="comment"
                      error={errors !== null && 'comment' in errors}
                      {...bindInput('comment')}
                    />
                    <Typography
                      variant="subtitle5"
                      component="p"
                      marginBottom={1}
                      sx={{ marginTop: '15px', fontWeight: '500' }}
                    >
                      Pictures & recordings
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        flexWrap: 'wrap',
                        columnGap: '5px',
                        rowGap: '20px',
                        marginBottom: '20px'
                      }}
                    >
                      {formInputs?.attachment?.map((item, index) => (
                        <Box key={index} sx={{ position: 'relative' }}>
                          <GroupMessagesAvatar
                            key={index}
                            clickHandler={() =>
                              imageViewer.current.openImageViewer(
                                API_HOST + item.attachment,
                                item.attachment.split('.')?.slice(-1).pop() === 'mp4'
                                  ? 'video'
                                  : 'image',
                                'square',
                                { width: '100%', height: '80vh' },
                                { sx: { fontSize: '150px' } }
                              )
                            }
                            type={
                              item.attachment.split('.')?.slice(-1).pop() === 'mp4'
                                ? 'video'
                                : 'image'
                            }
                            sx={{ height: '76px', width: '76px', position: 'relative' }}
                            variant="rounded"
                            url={item.attachment}
                          />
                          <Button
                            sx={{
                              position: 'absolute',
                              padding: '0px',
                              minWidth: '0px',
                              top: '-10px',
                              right: '-10px'
                            }}
                            onClick={() => imageDelete(item.id)}
                          >
                            <CloseIcon
                              sx={{
                                color: theme.palette.primary.contrastText,
                                background: theme.palette.error.main,
                                padding: '5px',
                                borderRadius: '50%'
                              }}
                            />
                          </Button>
                        </Box>
                      ))}
                      <Button
                        component="label"
                        sx={{ padding: '20px', border: `1px solid ${theme.palette.grey[125]}` }}
                      >
                        <AddIcon sx={{ color: theme.palette.primary.light, fontSize: '34px' }} />
                        <input
                          hidden
                          accept="image/* , video/*"
                          name="attachment"
                          onChange={(e) => handleImageUpload(e)}
                          type="file"
                        />
                      </Button>
                    </Stack>
                    <Button
                      sx={{ marginTop: 1 }}
                      variant="contained"
                      onClick={() => handleSubmit()}
                    >
                      Submit report
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>
      ) : (
        <Box>
          {!loader ? (
            <NotFound />
          ) : (
            <Box sx={{ width: '100%' }}>
              <LinearProgress color="secondary" />
            </Box>
          )}
        </Box>
      )}
      <MediaViewer ref={imageViewer} />
    </>
  );
};

export default AdasReport;
