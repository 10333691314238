export const StaticColors = [
  '#01d727',
  '#16b8de',
  '#6d8eff',
  '#dd228e',
  '#eaaae7',
  '#ee334f',
  '#87332c',
  '#319517',
  '#1c71af',
  '#439cb9',
  '#373b3a',
  '#5ca27f',
  '#8c5f5d',
  '#6e13a1',
  '#9569d6',
  '#6c1b0b',
  '#cc18f1',
  '#b72f22',
  '#44720d',
  '#545af3',
  '#dc1d8a',
  '#1f4a7f',
  '#f43131',
  '#aa33af',
  '#458841',
  '#971b24'
];
