import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { PERM } from '../constants';
import { getLocalStorageAtob } from '../utils/LocalStorage';

const RoleBasedRoute = ({ element, moduleName }) =>
  isAllowed(moduleName) ? element : <Navigate to="/dashboard/permission-denied" />;

export const RoleBasedComponent = ({ children, moduleName }) => {
  let response = '';
  if (typeof moduleName !== 'undefined') {
    response = isAllowed(moduleName) ? children : null;
  } else {
    response = children;
  }

  return response;
};

const isAllowed = (name) => {
  const userPermissesions = getLocalStorageAtob(PERM);
  let response = false;
  // if (typeof name === 'string') {
  //   response = userPermissesions.indexOf(name) > -1;
  // } else

  if (Object.prototype.toString.call(name) === '[object Array]') {
    if (typeof name?.[0] !== 'string') {
      const intersection = name.filter((element) =>
        userPermissesions.some((r) => element.includes(r))
      );
      response = intersection.length > 0;
    } else {
      response = userPermissesions.some((r) => name.includes(r));
    }
    // const intersection = name.filter((element) => userPermissesions.includes(element));
    // response = intersection.length > 0;
  }
  return response;
};
RoleBasedRoute.propTypes = {
  element: PropTypes.object,
  moduleName: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};

export default RoleBasedRoute;
