import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// material
import { Card, Container, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import { MHidden } from '../components/@material-extend';
import Page from '../components/Page';
import { LoginForm } from '../components/authentication/login';
// import AuthSocial from '../components/authentication/AuthSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  },
  [theme.breakpoints.down('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
  [theme.breakpoints.down('md')]: {
    // display: 'flex',
    padding: theme.spacing(12, 2),
    minHeight: '100%'
  }
}));

// ----------------------------------------------------------------------

export default function Login() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  useEffect(() => {
    if (page === 'tmcs-mobile-view') {
      localStorage.setItem('mobileView', true);
    }
  }, [page]);
  return (
    <RootStyle title="Login | ATMS">
      {/* <AuthLayout>
        Don’t have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/register">
          Get started
        </Link>
      </AuthLayout> */}
      {!page && (
        <MHidden width="mdDown">
          <div style={{ height: '90vh', width: '100%' }}>
            <SectionStyle style={{ height: '90vh', width: '100%', minWidth: '95%' }}>
              {/* <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                ATMS
              </Typography> */}
              <img src="/static/illustrations/ATMS.gif" alt="login" style={{ height: '100%' }} />
            </SectionStyle>
          </div>
        </MHidden>
      )}

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Welcome to ATMS
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Please enter your credentials below.
            </Typography>
          </Stack>
          {/* <AuthSocial /> */}

          <LoginForm page={page} />

          {/* <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to="register">
                Get started
              </Link>
            </Typography>
          </MHidden> */}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
