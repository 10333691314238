import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { PERM } from '../constants';
import { getLocalStorageAtob } from '../utils/LocalStorage';

const FMSRoleBasedRoute = ({ element, moduleName }) =>
  isAllowed(moduleName) ? element : <Navigate to="/dashboard/permission-denied" />;

export const FMSRoleBasedComponent = ({ children, moduleName }) => {
  let response = '';
  if (typeof moduleName !== 'undefined') {
    response = isAllowed(moduleName) ? children : null;
  } else {
    response = children;
  }

  return response;
};

const isAllowed = (name) => {
  const userPermissesions = getLocalStorageAtob(PERM);
  return userPermissesions.some((r) => name.includes(r));
};
FMSRoleBasedRoute.propTypes = {
  element: PropTypes.object,
  moduleName: PropTypes.string
};

export default FMSRoleBasedRoute;
