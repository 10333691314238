import { Box, Button, Stack, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import useForm from '../../_hooks/useForm';
import siteMessages from '../../alert_messages';
import { MessagePopup } from '../../components/MessagePopup';
import { SelectBox } from '../../components/form_inputs';
import { Popup } from '../../components/popup/Popup';
import { PACKAGES, SUCCESS } from '../../constants';
import {
  AlertFilters,
  CreateAlerts,
  DevicePackages,
  DevicesOverPackage
} from '../../services/ApiEndpoints';
import ApiService from '../../services/ApiService';
import FormValidation, { required } from '../../utils/FormValidation';
import handleException from '../../utils/handleExceptions';

const CreateAlert = () => {
  const { apiService } = ApiService();
  const [devices, setDevices] = useState([]);
  const [alertType, setAlertType] = useState([]);
  const [packageValue, setPackageValue] = useState([]);
  const { formInputs, bindInput, setFormInputs } = useForm({
    type_id: '',
    package_id: '',
    device_id: ''
  });
  const { validate, errors, setErrors } = FormValidation({
    validationRules: {
      type_id: [required],
      package_id: [required],
      device_id: [required]
    },
    formInput: formInputs
  });
  const myRef = useRef();
  const ref = myRef.current;
  const getAlertFilters = () => {
    apiService({ url: AlertFilters, method: 'post' }).then((result) => {
      if (result?.status === SUCCESS) {
        const _data = result.data;
        setAlertType(_data.master.alert_type);
      }
    });
  };

  const getDevicePackage = () => {
    apiService({ url: DevicePackages, method: 'post' }).then((result) => {
      if (result?.status === SUCCESS) {
        const _data = result.data;
        setPackageValue(_data.device_packages);
      }
    });
  };
  const handleDeviceValue = () => {
    apiService({
      url: DevicesOverPackage,
      method: 'post',
      data: { package_id: formInputs.package_id }
    }).then((result) => {
      if (result?.status === SUCCESS) {
        const _data = result.data;
        setDevices(_data.listing);
      }
    });
  };
  const handlePackageValue = () => {
    handleDeviceValue();
    setFormInputs({ ...formInputs, package_id: formInputs.package_id });
  };

  const handleSubmit = () => {
    validate()
      .then(() => {
        apiService({ url: CreateAlerts, method: 'post', data: { ...formInputs } })
          .then((result) => {
            if (result?.status === SUCCESS) {
              ref.openPopUp(false);
              MessagePopup('success', 'Alert Created Successfully!');
            }
          })
          .catch((error) => {
            ref.openPopUp(false);
            MessagePopup('error', 'Alert not created!');
            handleException(error);
          });
      })
      .catch(() => handleException(siteMessages.validationError));
  };

  useEffect(() => {
    if (formInputs.package_id) handlePackageValue();
  }, [formInputs.package_id]);

  useEffect(() => {
    if (errors) setErrors({});
  }, [formInputs]);

  useEffect(() => {
    const _packages = JSON.parse(localStorage.getItem(PACKAGES));
    getAlertFilters();
    if (_packages.length > 0) setPackageValue(_packages);
    else getDevicePackage();
  }, []);

  return (
    <Box>
      <Button
        disableElevation
        size="small"
        onClick={() => myRef.current.openPopUp(true)}
        variant="outlined"
        title="create alert"
      >
        <Typography noWrap sx={{ fontWeight: 600, fontSize: '13px' }}>
          Create Alert
        </Typography>
      </Button>
      <Popup
        title="Create Alert"
        openButtonText="New Alert"
        handleSubmit={handleSubmit}
        ref={myRef}
        width={{ fullWidth: true, maxWidth: 'sm' }}
        openButton={false}
      >
        <Stack spacing={2}>
          <SelectBox
            options={alertType}
            error={errors}
            name="type_id"
            label="Alert Type"
            bindInput={bindInput}
          />
          <SelectBox
            options={packageValue}
            error={errors}
            name="package_id"
            label="Package"
            onChange={(e) => handlePackageValue(e)}
            bindInput={bindInput}
          />
          <SelectBox
            options={devices}
            error={errors}
            name="device_id"
            label="Device"
            bindInput={bindInput}
          />
        </Stack>
      </Popup>
    </Box>
  );
};

export default CreateAlert;
