import CloseIcon from '@mui/icons-material/Close';
import { Box, Drawer, IconButton, Stack, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const { forwardRef, useImperativeHandle } = React;

const SideDrawer = forwardRef(
  (
    {
      closeOnOutside = true,
      title,
      slideProps = { minWidth: '600px' },
      containerProps = { padding: 3 },
      ...props
    },
    ref
  ) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();

    useImperativeHandle(ref, () => ({
      openDrawer: () => setDrawerOpen(true),
      closeDrawer: () => setDrawerOpen(false)
    }));
    return (
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <Box className="group-slider" sx={{ ...slideProps }}>
          <Box padding={1} sx={{ background: theme.palette.info.violet }}>
            <Stack direction="row" spacing={3} alignItems="center">
              <IconButton
                onClick={() => setDrawerOpen(false)}
                sx={{ padding: 0, color: theme.palette.error.contrastText }}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="subtitle1" sx={{ color: theme.palette.error.contrastText }}>
                {title}
              </Typography>
            </Stack>
          </Box>

          <Box sx={{ padding: 3, ...containerProps }}>{props.children}</Box>
        </Box>
      </Drawer>
    );
  }
);

SideDrawer.propTypes = {
  children: PropTypes.node
};

export default SideDrawer;
