import { BluetoothDrive, DriveEta, MedicalServices, Traffic } from '@mui/icons-material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import { ReactComponent as Alert } from '../../assets/svg/Navbar/alert_dark.svg';
import { ReactComponent as CarInsurance } from '../../assets/svg/Navbar/car_insurance_dark.svg';
import { ReactComponent as Dashboard } from '../../assets/svg/Navbar/dashboard_dark.svg';
import { ReactComponent as FaciltyManagementSystem } from '../../assets/svg/Navbar/Facilty_management_system_dark.svg';
import { ReactComponent as Gauge } from '../../assets/svg/Navbar/gauge_dark.svg';
import { ReactComponent as VideoMoniteringSystem } from '../../assets/svg/Navbar/highway.svg';
import { ReactComponent as Challan } from '../../assets/svg/Navbar/recipt.svg';
import { ReactComponent as Ticket } from '../../assets/svg/Navbar/ticket_dark.svg';
import { ReactComponent as TwoCarsInLine } from '../../assets/svg/Navbar/two_cars_in_line_dark.svg';
import { ReactComponent as VirtualMessagingSystem } from '../../assets/svg/Navbar/Virtual_messaging_system_dark.svg';
import { ReactComponent as AnnotationTool } from '../../assets/svg/screenshot.svg';
import { ReactComponent as Activity } from '../../assets/svg/Navbar/activity.svg';
import { ReactComponent as UserGuide } from '../../assets/svg/Navbar/user-guide.svg';
import SiteModules from '../../routing/SiteModules';
import ThemeSvg from '../../theme/ThemeSvg';

const sidebarConfig = [
  {
    title: 'Dashboard',
    head: 'ATMS',
    path: '/dashboard',
    icon: <ThemeSvg render={(color) => <Dashboard fill={color} stroke={color} />} />
  },
  {
    title: 'Emergency Contacts',
    head: 'EC',
    path: '/dashboard/emergency-contacts',
    icon: <PhoneIcon color="primary" />
  },
  {
    title: 'Challan',
    head: 'Challan',
    path: '/dashboard/challan-dashboard',
    icon: <ThemeSvg render={(color) => <Challan fill={color} />} />,
    permissionCode: SiteModules.CHALLAN
  },
  {
    title: 'Traffic monitoring & control system',
    head: 'TMCS',
    path: '/dashboard/traffic-monitoring-and-control-system',
    icon: <ThemeSvg render={(color) => <TwoCarsInLine fill={color} stroke={color} />} />,
    permissionCode: SiteModules.TRAFFIC_MONITORING_CONTROL_SYSTEM
  },
  {
    title: 'Vehicle speed detection system',
    head: 'VSDS',
    path: '/dashboard/speed-detection-system',
    icon: <ThemeSvg render={(color) => <Gauge fill={color} />} />,
    permissionCode: SiteModules.VEHICLE_SPEED_DETECTION_SYSTEM
  },
  {
    title: 'Video incident detection system',
    head: 'VIDS',
    path: '/dashboard/vehicle-incident-detection-system',
    icon: <ThemeSvg render={(color) => <CarInsurance fill={color} stroke={color} />} />,
    permissionCode: SiteModules.VIDEO_INCIDENT_DETECTION_SYSTEM
  },
  {
    title: 'Variable messaging system',
    head: 'VMS',
    path: '/dashboard/variable-messaging-system',
    icon: <ThemeSvg render={(color) => <VirtualMessagingSystem fill={color} />} />,
    permissionCode: SiteModules.VIRTUAL_MESSAGING_SYSTEM
  },

  {
    title: 'Emergency Calling Box',
    head: 'ECB',
    path: '/dashboard/emergency-calling-box/dashboard',
    icon: <MedicalServices color="primary" />,
    permissionCode: SiteModules.EMERGENCY_CONTROLLING_BOX
  },
  {
    title: 'Highway Traffic Management System',
    head: 'HTMS',
    path: '/dashboard/traffic-monitoring',
    icon: <ThemeSvg render={(color) => <VideoMoniteringSystem fill={color} />} />,
    permissionCode: SiteModules.HIGHWAY_TRAFFICE_MONITORING_MODULE
  },
  {
    title: 'Event management system',
    head: 'EMS',
    path: '/dashboard/event-management-system',
    icon: <ThemeSvg render={(color) => <Ticket fill={color} />} />,
    permissionCode: SiteModules.TICKET_MANAGEMENT_SYSTEM
  },

  {
    title: 'Alerts',
    head: 'ALERTS',
    path: '/dashboard/alert-listing',
    icon: <ThemeSvg render={(color) => <Alert fill={color} />} />
  },

  {
    title: ' Automatic Traffic Counter Cum Classifier',
    head: 'ATCC',
    path: '/dashboard/atcc',
    icon: <Traffic color="primary" />,
    permissionCode: SiteModules.ATCC
  },
  {
    title: 'Travel Time Measurement System',
    head: 'TTMS',
    path: '/dashboard/ttms',
    icon: <DriveEta color="primary" />,
    permissionCode: SiteModules.TTMS
  },

  {
    title: 'System Services',
    head: 'SS',
    path: '/dashboard/facility-management-system',
    icon: <ThemeSvg render={(color) => <FaciltyManagementSystem fill={color} stroke={color} />} />,
    permissionCode: SiteModules.SYSTEM_SERVICE
  },
  {
    title: 'Advanced driver assistance systems',
    head: 'ADAS',
    path: '/dashboard/adas',
    icon: <BluetoothDrive color="primary" />,
    permissionCode: SiteModules.ADAS
  },
  {
    title: 'Reports',
    head: 'REPORTS',
    path: '/dashboard/reports',
    icon: <AssessmentIcon color="primary" />,
    permissionCode: SiteModules.REPORTS
  },
  {
    title: 'User & Roles Administration',
    head: 'URA',
    path: '/dashboard/user-and-roles-admin',
    icon: <PersonIcon color="primary" />,
    permissionCode: SiteModules.FMS_USERS
  },
  {
    title: 'Annotation Tool',
    head: 'AT',
    path: '/dashboard/annotation-tool',
    icon: <ThemeSvg render={(color) => <AnnotationTool fill={color} />} />,
    permissionCode: SiteModules.ANNOTATION_TOOL
  },
  {
    title: 'User Guide',
    head: 'UG',
    path: '/dashboard/user-guide',
    icon: <ThemeSvg render={(color) => <UserGuide fill={color} stroke={color} />} />,
    permissionCode: SiteModules.USER_GUIDE
  },
  {
    title: 'Activity Log',
    head: 'AL',
    path: '/dashboard/activity-log',
    icon: <ThemeSvg render={(color) => <Activity fill={color} stroke={color} />} />,
    permissionCode: SiteModules.ACTIVITY_LOG
  },
  {
    title: 'GIS',
    head: 'GIS',
    path: '/dashboard/gis',
    icon: <ThemeSvg render={(color) => <Activity fill={color} stroke={color} />} />,
    permissionCode: SiteModules.GIS
  },
  {
    title: 'Manual Detection',
    head: 'MD',
    path: '/dashboard/manual-detection-mobile-view',
    icon: <ThemeSvg render={(color) => <CarInsurance fill={color} stroke={color} />} />,
    permissionCode: SiteModules.MANUAL_DETECTION
  }
];

export default sidebarConfig;
