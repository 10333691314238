import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const TabsHeader = ({ allTabs, ...props }) => {
  const { value, setValue } = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        {allTabs.map((label, index) => (
          <Tab key={index} label={label} {...a11yProps(index)} />
        ))}
      </Tabs>
    </Box>
  );
};

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

TabsHeader.propTypes = {
  value: PropTypes.any,
  setValue: PropTypes.func,
  allTabs: PropTypes.array
};
TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.any,
  index: PropTypes.any
};
export default TabsHeader;
