import { useState } from 'react';

/* eslint-disable no-useless-escape */

const FormValidation = ({ validationRules, formInput }) => {
  const [errors, setErrors] = useState(null);

  const validation = () => {
    // define a empty object to store errors.
    let allErrors = {};
    let arrayErrors = {};
    // Run loop on validation object
    Object.keys(validationRules).forEach((name) => {
      // name is the name of input field
      const rulesArr = validationRules[name];

      if (name === 'arrayFields') {
        rulesArr.forEach((rule) => {
          arrayErrors = { ...arrayErrors, [rule.key]: [] };
          if (formInput[rule.key].length > 0) {
            const validationRules2 = rule.fields;

            for (let index = 0; index < formInput[rule.key].length; index += 1) {
              const element = formInput[rule.key][index];

              let errorlist = {};
              Object.keys(validationRules2).forEach((name2) => {
                const rulesArr2 = validationRules2[name2];

                const result = checkErrors({
                  rulesArr: rulesArr2,
                  allInputs: element,
                  name: name2
                });

                errorlist = { ...errorlist, ...result };
              });
              if (Object.keys(errorlist)?.length) arrayErrors[rule.key].push(errorlist);
            }
          }

          if (arrayErrors[rule.key].length === 0) delete arrayErrors[rule.key];
        });
      } else {
        const result = checkErrors({ rulesArr, allInputs: formInput, name });
        allErrors = { ...allErrors, ...result };
      }
    });
    const myerrors = arrayErrors;
    return { ...allErrors, ...arrayErrors };
  };

  const checkErrors = ({ rulesArr, allInputs, name }) => {
    let result;

    rulesArr.every((rule) => {
      // If rule is an array than it is a type of a function with parameter
      switch (Array.isArray(rule)) {
        case true: {
          // take the function name and parameter value from rule array
          const [functionName, paramValue] = rule;
          // call validation function

          result = functionName(allInputs, name, paramValue);
          break;
        }

        default:
          // call validation function
          result = rule(allInputs, name);
          break;
      }

      if (result) {
        return false;
      }
      return true;
    });
    return result;
  };

  const validate = () =>
    new Promise((resolve, reject) => {
      const errorObj = validation();
      if (Object.keys(errorObj).length === 0) {
        setErrors({});
        resolve('Success');
      } else {
        setErrors(errorObj);
        const errorMessage = 'Some Error Occurred';
        reject(errorMessage);
      }
    });

  return { validate, errors, setErrors };
};

export const required = (formInputs, inputName) =>
  !formInputs[inputName] && { [inputName]: 'This field is required' };

function emailPattern(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

function distancePattern(distance) {
  return String(distance)
    .toLowerCase()
    .match(/^(\d+).?(\d*)$/);
}

function vehicleNumberPattern(no) {
  return String(no).match(/^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{1,2}[0-9]{4}$/);
}

function phonePattern(phone) {
  return String(phone).match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/);
}

function numberPattern(no) {
  return String(no).match(/\d/);
}

function coordinatesPattern(coord) {
  return String(coord).match(/^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}$/);
}

function positiveNumber(num) {
  return String(num).match(/^([1-9]+([0-9]*)?|\.[0-9]+)$/);
}

function name(num) {
  return String(num).match(/^[a-zA-Z ]{2,}$/);
}

function notes(num) {
  return String(num).match(/^[a-zA-Z ,]{2,}$/);
}

function deviceName(num) {
  return String(num).match(/^[a-zA-Z1-9 ]{2,}$/);
}

export const DeviceName = (formInputs, inputName) =>
  formInputs[inputName]
    ? !deviceName(formInputs[inputName]) && {
        [inputName]: 'Please enter Valid Name'
      }
    : true;

export const Notes = (formInputs, inputName) =>
  formInputs[inputName]
    ? !notes(formInputs[inputName]) && {
        [inputName]: 'Please enter Valid Note'
      }
    : true;

export const Name = (formInputs, inputName) =>
  formInputs[inputName]
    ? !name(formInputs[inputName]) && {
        [inputName]: 'Please enter Valid Name'
      }
    : true;

export const coordinates = (formInputs, inputName) =>
  formInputs[inputName]
    ? !coordinatesPattern(formInputs[inputName]) && {
        [inputName]: 'Please enter Valid Coordinates'
      }
    : true;

export const PositiveNumber = (formInputs, inputName) =>
  formInputs[inputName]
    ? !positiveNumber(formInputs[inputName]) && {
        [inputName]: 'Please enter Valid Number'
      }
    : true;

export const number = (formInputs, inputName) =>
  formInputs[inputName]
    ? !numberPattern(formInputs[inputName]) && {
        [inputName]: 'Please enter Number'
      }
    : true;

export const Distance = (formInputs, inputName) =>
  formInputs[inputName]
    ? !distancePattern(formInputs[inputName]) && {
        [inputName]: 'Please enter Valid Distance'
      }
    : true;

export const Phonenumber = (formInputs, inputName) =>
  formInputs[inputName]
    ? !phonePattern(formInputs[inputName]) && {
        [inputName]: 'Please enter valid Phone Number'
      }
    : true;

export const email = (formInputs, inputName) =>
  formInputs[inputName]
    ? !emailPattern(formInputs[inputName]) && {
        [inputName]: 'Please enter valid email'
      }
    : true;

export const vehicle = (formInputs, inputName) =>
  formInputs[inputName]
    ? !vehicleNumberPattern(formInputs[inputName]) && {
        [inputName]: 'Please enter valid No.'
      }
    : true;

export function passwordPattern(formInputs, inputName) {
  const value = formInputs[inputName];

  let error;
  if (value.length < 8) {
    error = 'Your password must be at least 8 characters';
  }
  if (value.search(/[a-z]/i) < 0) {
    error = 'Your password must contain at least one letter.';
  }
  if (value.search(/[0-9]/) < 0) {
    error = 'Your password must contain at least one digit.';
  }

  if (value.search(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/) < 0) {
    error = 'Your password must contain at least one special character.';
  }

  return (
    error && {
      [inputName]: error
    }
  );
}

export const maxLength = (formInputs, inputName, paramValue) =>
  formInputs[inputName].length > paramValue && {
    [inputName]: `Maximum characters are ${paramValue}`
  };

export const minLength = (formInputs, inputName, paramValue) =>
  formInputs[inputName].length < paramValue && {
    [inputName]: `Minimum characters are ${paramValue}`
  };

export const confirmPassword = (formInputs, inputName) =>
  formInputs[inputName] &&
  formInputs.new_password &&
  formInputs[inputName] !== formInputs.new_password && {
    [inputName]: `Password must be same`
  };

export default FormValidation;
