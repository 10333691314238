import React, { useState, createContext, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material';

import { THEME_TYPE, DARK_THEME } from '../constants';

const ThemeMode = createContext(1);

export const useThemeMode = () => useContext(ThemeMode);

const DarkThemeProvider = ({ children }) => {
  const [themeStatus, setThemeStatus] = useState();
  const { palette } = useTheme();

  useEffect(() => {
    if (!localStorage.getItem(THEME_TYPE)) {
      localStorage.setItem(THEME_TYPE, DARK_THEME);
      setThemeStatus(DARK_THEME);
    } else {
      setThemeStatus(localStorage.getItem(THEME_TYPE));
    }
  }, []);
  // color: theme.palette.grey[50]

  return (
    <>
      <ThemeMode.Provider value={{ themeStatus, setThemeStatus, palette }}>
        {children}
      </ThemeMode.Provider>
    </>
  );
};

export default DarkThemeProvider;
