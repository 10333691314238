import React, { useState, createContext, useContext, useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import _ from 'lodash';
import { useAuthContext } from './AuthContext';
import CustomSnackBar from '../components/CustomSnackBar';
import { DEVICES } from '../constants';
import { getLocalStorage, removeFromStorage } from '../utils/LocalStorage';
import { WS_ECB_CALLS } from '../services/ApiEndpoints';
import useSocket from '../_hooks/Socket';

const EcbCall = createContext(null);

export const useEcbCall = () => useContext(EcbCall);

const EcbCallContext = ({ children }) => {
  const { isLoggedIn } = useAuthContext();
  const { lastMessage } = useSocket(WS_ECB_CALLS);
  const [callData, setCallData] = useState();
  const [callCount, setCallCount] = useState(0);
  const device = getLocalStorage(DEVICES);
  const currentUrl = window.location.href;
  const date = new Date();
  const day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();
  if (month < 10) {
    month = `0${month}`;
  }

  useEffect(() => {
    if (isLoggedIn()) {
      if (lastMessage !== null) {
        const { data } = JSON.parse(lastMessage.data);
        setCallData(data);
        setCallCount(callCount + 1);
      }
    }
  }, [lastMessage]);

  return (
    <>
      <EcbCall.Provider value={{ callCount }}>{children}</EcbCall.Provider>
      {currentUrl?.includes('emergency-calling-box') && (
        <Stack
          spacing={2}
          direction="column-reverse"
          sx={{
            maxHeight: '60%',
            width: '20%',
            position: 'fixed',
            bottom: '4%',
            right: 0,
            overflowY: 'scroll'
          }}
        >
          {callData && (
            <Box>
              {/* {device?.includes(item?.device_id) &&  */}
              <CustomSnackBar data={callData} open autoHide />
            </Box>
          )}
        </Stack>
      )}
    </>
  );
};

export default EcbCallContext;
