import { useContext, useEffect } from 'react';
import useForm from '../../../_hooks/useForm';
import ApiService from '../../../services/ApiService';
import { ForgotPassword } from '../../../services/ApiEndpoints';
import { ForgotPasswordForm, ForgotPasswordValidation } from './FormModel';
import siteMessages from '../../../alert_messages';
import { SUCCESS } from '../../../constants';
import FormValidation from '../../../utils/FormValidation';
import { ForgotPassContext } from '.';
import ResetPassword from './ResetPassword';
import handleException from '../../../utils/handleExceptions';

const useForgotPassword = () => {
  const { apiService } = ApiService();
  const { setPage } = useContext(ForgotPassContext);
  const { formInputs, bindInput } = useForm(ForgotPasswordForm);
  const { validate, errors, setErrors } = FormValidation({
    validationRules: { ...ForgotPasswordValidation },
    formInput: formInputs
  });

  const handleSubmit = () => {
    validate()
      .then(() => {
        apiService(
          { url: ForgotPassword, method: 'post', data: formInputs },
          { alertMessage: siteMessages.otpSend }
        )
          .then((result) => {
            if (result?.status === SUCCESS) {
              setPage({ element: ResetPassword, props: { username: formInputs.username } });
            }
          })
          .catch((error) => handleException(error));
      })
      .catch(() => handleException(siteMessages.validationError));
  };

  useEffect(() => {
    if (errors) setErrors({});
  }, [formInputs]);

  return {
    handleSubmit,
    bindInput,
    errors
  };
};

export default useForgotPassword;
