import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const { forwardRef, useImperativeHandle } = React;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  }
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <i className="fa fa-times-circle" aria-hidden="true" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

export const Popup = forwardRef((props, ref) => {
  const theme = useTheme();
  const {
    handleSubmit,
    className,
    openButton,
    variant,
    openButtonText,
    width,
    title,
    submitButtonColor,
    children,
    submitButton,
    submitButtonText,
    sx,
    height,
    disableSummit = false,
    disableBorder
  } = props;

  Popup.propTypes = {
    handleSubmit: PropTypes.func,
    className: PropTypes.string,
    openButton: PropTypes.bool,
    variant: PropTypes.string,
    submitButtonText: PropTypes.string,
    openButtonText: PropTypes.string,
    title: PropTypes.any,
    children: PropTypes.node,
    submitButton: PropTypes.bool,
    width: PropTypes.object,
    sx: PropTypes.object
  };
  const [openPopup, setOpenPopup] = useState(false);

  useImperativeHandle(ref, () => ({
    openPopUp(status) {
      setOpenPopup(status);
    }
  }));

  return (
    <div className={className}>
      {openButton && (
        <Button
          variant={variant}
          color="info"
          size="small"
          sx={{ minWidth: 120, marginBottom: '10px' }}
          onClick={() => setOpenPopup(true)}
          startIcon={<AddIcon />}
        >
          {openButtonText}
        </Button>
      )}
      <BootstrapDialog
        onClose={() => setOpenPopup(false)}
        aria-labelledby="customized-dialog-title"
        open={openPopup}
        {...width}
        sx={{ ...sx }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => setOpenPopup(false)}
          sx={{ background: theme.palette.background.main }}
        >
          {title}
        </BootstrapDialogTitle>
        <DialogContent dividers sx={{ background: theme.palette.background.main, height, ...disableBorder }}>
          <div>
            {children}
            {submitButton && (
              <div style={{ textAlign: 'right' }}>
                <Button
                  type="button"
                  color={submitButtonColor}
                  sx={{
                    minWidth: '20%',
                    marginTop: '10px',
                    marginRight: '10px'
                  }}
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={disableSummit}
                >
                  {submitButtonText}
                </Button>
              </div>
            )}
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
});

Popup.defaultProps = {
  variant: 'outlined',
  openButton: true,
  submitButton: true,
  width: {},
  sx: {},
  submitButtonText: 'Create'
};

export default Popup;
