// ----------------------------------------------------------------------

function pxToRem(value) {
  return `${value / 16}rem`;
}

function responsiveFontSizes({ sm, md, lg }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm)
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md)
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg)
    }
  };
}

const FONT_PRIMARY = 'Poppins';

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 500,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    lineHeight: 80 / 64,
    fontSize: pxToRem(40),
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 })
  },
  h2: {
    fontWeight: 700,
    lineHeight: 64 / 48,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 })
  },
  h3: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 })
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 })
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
    // color: '#000000',
    ...responsiveFontSizes({ sm: 16, md: 16, lg: 16 })
  },
  h6: {
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17),
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 })
  },
  subtitle1: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(16)
  },
  subtitle8: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(22)
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(14)
  },
  subtitle6: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(22)
  },
  subtitle7: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(24)
  },
  subtitle3: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 600,
    lineHeight: 24 / 16,
    fontSize: pxToRem(16)
  },
  subtitle4: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 600,
    lineHeight: 24 / 16,
    fontSize: pxToRem(18)
  },
  boxTitle: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 500,
    lineHeight: 18 / 12,
    fontSize: pxToRem(12)
    // color: '#343b46'
  },
  boxTitle1: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 500,
    lineHeight: 18 / 12,
    fontSize: pxToRem(16)
    // color: '#343b46'
  },
  boxTitle3: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 500,
    lineHeight: 18 / 12,
    fontSize: pxToRem(10)
  },
  boxTitle2: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 500,
    lineHeight: 18 / 12,
    fontSize: pxToRem(14)
  },
  navItem: {
    fontWeight: 500,
    lineHeight: 18 / 12,
    ...responsiveFontSizes({ sm: 12, md: 12, lg: 12 })
  },
  navItemSubtitle: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: pxToRem(11)
  },
  subheading: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 700,
    lineHeight: 24 / 16,
    fontSize: pxToRem(16),
    // color: '#000000',
    margin: '8px 0px',
    display: 'block'
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(14)
  },
  body2: {
    lineHeight: 17 / 14,
    fontSize: pxToRem(14)
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12)
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    letterSpacing: 1.1,
    textTransform: 'uppercase'
  },
  button: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: 'capitalize'
    // color: '#4F46BA'
  },
  dataGrid: {
    lineHeight: 17 / 14,
    fontSize: pxToRem(12)
    // color: '#7B818A'
  },
  subtitle5: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(12)
  },
  small: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    ...responsiveFontSizes({ sm: 10, md: 12, lg: 12 })
  },
  extraSmall: {
    lineHeight: '17px',
    fontSize: pxToRem(10)
  },
  link: {
    // color: '#4f46ba !important',
    fontWeight: 500
  }
};

export default typography;
