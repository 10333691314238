// ----------------------------------------------------------------------

export default function Table() {
  return {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '12px'
        }
      }
    }
  };
}
