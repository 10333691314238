import { required, passwordPattern, confirmPassword } from '../../utils/FormValidation';

export const ChangePasswordForm = {
  old_password: '',
  new_password: '',
  confirm_password: ''
};

export const ChangePasswordFormValidation = {
  old_password: [required],
  new_password: [required, passwordPattern],
  confirm_password: [required, passwordPattern, confirmPassword]
};
