import _ from 'lodash';
import {
  ACCESS,
  REFRESH,
  REFRESH_AT,
  PERM,
  SUPERUSER,
  EXPIRED_AT,
  LOGGED_IN_USER_DISPLAY,
  DEVICES,
  USERID,
  USER_LEVEL,
  LEVEL_1,
  PACKAGES,
  ALL_PACKAGES,
  CHAINAGE,
  ALL_CHAINAGE,
  ALLOWED_CHAINAGE
} from '../constants';

export default {
  setAccessToken: (access, refresh, expiredIn) => {
    localStorage.setItem(ACCESS, access);
    localStorage.setItem(REFRESH, refresh);
    // setting expired at in local storage
    const expireTime = new Date();
    expireTime.setHours(expireTime.getHours() + expiredIn);
    localStorage.setItem(EXPIRED_AT, expireTime.getTime());

    const refreshTime = new Date();
    refreshTime.setHours(refreshTime.getHours() + 1);
    localStorage.setItem(REFRESH_AT, refreshTime.getTime());
  },
  setPermissions: (permissions) => {
    const permJson = JSON.stringify(permissions);
    localStorage.setItem(PERM, btoa(permJson));
  },
  setLoggedinDisplayName: (displayName) => {
    localStorage.setItem(LOGGED_IN_USER_DISPLAY, displayName);
  },
  setDevices: (devices) => {
    localStorage.setItem(DEVICES, devices);
  },
  setPackages: (packages) => {
    const permJson = JSON.stringify(packages);
    localStorage.setItem(PACKAGES, permJson);
  },
  setAllPackages: (packages) => {
    const allowedPackageData = _.map(packages, 'id');
    localStorage.setItem(ALL_PACKAGES, JSON.stringify(allowedPackageData));
  },
  setChainage: (chainage) => {
    const permJson = JSON.stringify(chainage);
    localStorage.setItem(CHAINAGE, permJson);
  },
  setallowedAllChainage: (chainage) => {
    localStorage.setItem(ALLOWED_CHAINAGE, chainage);
  },
  setAllChainage: (chainage) => {
    const allowedPackageData = _.map(chainage, 'id');
    localStorage.setItem(ALL_CHAINAGE, JSON.stringify(allowedPackageData));
  },
  setSuperUser: (status) => {
    localStorage.setItem(SUPERUSER, status);
  },
  setUserID: (id) => {
    localStorage.setItem(USERID, id);
  },
  setUserLevel: (level) => {
    if (level) {
      localStorage.setItem(USER_LEVEL, level);
    } else {
      localStorage.setItem(USER_LEVEL, LEVEL_1);
    }
  },
  getPermissions: () => {
    if (localStorage.getItem(PERM)) {
      const permJson = atob(localStorage.getItem(PERM));
      return JSON.parse(permJson);
    }
    return [];
  },
  checkPermissions: (name) => {
    if (localStorage.getItem(PERM)) {
      const permJson = atob(localStorage.getItem(PERM));
      const permission = JSON.parse(permJson);
      return permission.includes(name);
    }
    return false;
  },
  getLoggedinDisplayName: () => localStorage.getItem(LOGGED_IN_USER_DISPLAY),
  getAccessToken: () => localStorage.getItem(ACCESS),
  getRefreshToken: () => localStorage.getItem(REFRESH),
  getAccessExpiredTime: () => localStorage.getItem(EXPIRED_AT),
  getTokenRefreshTime: () => localStorage.getItem(REFRESH_AT)
};
